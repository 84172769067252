// Values must be lowercase
export enum TemplateEnum {
  GlobalTemplate = 'globaltemplate',
  LocalTemplate = 'localtemplate',
}

export type TemplateType = {
  categoryId?: number
  /** unix timestamp */
  created: number
  desc?: string
  id: number
  name: string
  published: boolean
  type: TemplateEnum
  /** unix timestamp */
  updated: number
}

export type TemplateToDelete = {
  id: number
  name?: string
}

export type TemplatesFilter = {
  globalTemplate?: boolean
  localTemplate?: boolean
}

export enum TemplatesExamplesEnum {
  Examples = 'examples',
  Templates = 'templates',
}

export type TemplatesExamplesUnion = `${TemplatesExamplesEnum}`

export enum TemplateExampleEnum {
  Example = 'example',
  Template = 'template',
}

export type TemplateExampleUnion = `${TemplateExampleEnum}`

export type Category = {
  id: number
  isDefault: boolean
  name: string
}

export type CategoryList = {
  categories: Array<Category>
}
