import { Fragment, useCallback } from 'react'
import { AppBar, Hidden, Grid } from '@mui/material'
import { ApplicationMenu, Breadcrumb } from '@dis/modules'
import { PublicationStatus } from '@dis/modules/src/publication-status/PublicationStatus'
import { RevisionControl } from '@dis/modules/src/revision-control/RevisionControl'
import ResultsSearchBar from '@dis/modules/src/search/ResultsSearchBar'
import { useAppSelector } from '@dis/redux'
import { cx } from '@emotion/css'
import { useNavTo } from '@dis/hooks'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import KpmgLogo from '@dis/assets/src/icons/LogoKpmg.svg'
import MapExIcon from '@dis/assets/src/icons/MapExIcon.svg'
import { SvgImage } from '@dis/components'
import { useRouteMatch } from '@dis/hooks/src/useRouteMatch'
import { selectIsAuthenticated } from '@dis/redux/src/security/selectors'
import {
  selectHasCentralModalLoaderHighPriority,
  selectIsCentralModalLoaderVisible,
} from '@dis/redux/src/centralModalLoader/selectors'
import { selectLogoTenantMapEx } from '@dis/redux/src/tenants/tenantsSelectors'
import { styles } from './styles'
import { ActiveUserList } from '../ActiveUserList/ActiveUserList'

export const Header = () => {
  const isDiscovery = useAppSelector(selectLogoTenantMapEx)
  const { navigate } = useNavTo()

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isCentralModalLoaderVisible = useAppSelector(selectIsCentralModalLoaderVisible)
  const hasCentralModalLoaderHighPriority = useAppSelector(selectHasCentralModalLoaderHighPriority)
  const isRtl = useAppSelector(selectIsLanguageRtl)

  const {
    isPersona,
    isJourney,
    isJourneyFolder,
    isRecycleBinJourney,
    isSearch,
    isTemplatePreview,
  } = useRouteMatch()

  const showRevisionControl = isJourney || isPersona || isJourneyFolder
  const showPublicationStatus = isJourney || isRecycleBinJourney || isJourneyFolder
  const showSearchBar = isSearch
  const showActiveUserList = isJourney || isRecycleBinJourney

  const icon = isDiscovery ? KpmgLogo : MapExIcon

  const goToRoot = useCallback(() => {
    navigate('tenants')
  }, [navigate])

  return (
    <AppBar
      sx={{
        px: {
          lg: 21,
          md: 5,
          sm: 4,
          xs: 2,
        },
      }}
      className={cx(styles.appBar, {
        [styles.higherZindex]: isCentralModalLoaderVisible && !hasCentralModalLoaderHighPriority,
      })}>
      <div
        className={cx(styles.logoContainer.root, {
          [styles.logoContainer.rtl]: isRtl,
        })}
        onClick={goToRoot}>
        <Hidden lgDown>
          <SvgImage src={icon} />
        </Hidden>
        <Hidden lgUp mdDown>
          <SvgImage src={icon} width="75px" height="28px" />
        </Hidden>
        <Hidden mdUp>
          <SvgImage src={icon} width="56px" height="22px" />
        </Hidden>
      </div>

      {isAuthenticated && !isTemplatePreview && (
        <Fragment>
          <div className={styles.breadcrumbContainer}>
            <Breadcrumb />
          </div>

          {showActiveUserList && (
            <div
              className={cx(styles.menuContainer, styles.publicationStatus.root, {
                [styles.publicationStatus.rtl]: isRtl,
              })}>
              <ActiveUserList />
            </div>
          )}

          {showPublicationStatus && (
            <div
              className={cx(styles.menuContainer, styles.publicationStatus.root, {
                [styles.publicationStatus.rtl]: isRtl,
              })}>
              <PublicationStatus />
            </div>
          )}

          {showRevisionControl && (
            <div
              className={cx(styles.menuContainer, styles.revisionControl.root, {
                [styles.revisionControl.rtl]: isRtl,
              })}>
              <RevisionControl isPersona={isPersona} isJourney={isJourney || isJourneyFolder} />
            </div>
          )}

          {showSearchBar && (
            <Grid
              item
              className={cx(styles.menuContainer, {
                [styles.searchBar]: isRtl,
              })}>
              <div className={styles.resultsSearchBarWrapper}>
                <ResultsSearchBar />
              </div>
            </Grid>
          )}
        </Fragment>
      )}

      {!isTemplatePreview && (
        <div className={cx(styles.menuContainer, { [styles.menuContainerAr]: isRtl })}>
          <ApplicationMenu />
        </div>
      )}
    </AppBar>
  )
}
