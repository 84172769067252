import { css } from '@emotion/css'
import { colors, theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  icon: css`
    margin-inline-end: ${theme.spacing(2)};
  `,
  text: css`
    margin-inline-start: ${theme.spacing(1)};
    margin-inline-end: ${theme.spacing(1)};
  `,
  toggleButton: css`
    border: none;
    border-radius: ${theme.spacing(1)};
    text-transform: none;
    padding-inline-start: ${theme.spacing(1)};
    padding-inline-end: ${theme.spacing(1)};

    &.Mui-selected {
      background-color: ${colors.kpmg.pacificBlue};
      color: ${colors.background.white};
      .MuiTypography-root {
        font-weight: ${typographyFontWeight.bold};
      }

      &:hover {
        background-color: ${colors.kpmg.pacificBlue};
        color: ${colors.background.white};
      }

      svg {
        color: ${colors.background.white};
      }
    }

    &:not(.Mui-selected) {
      color: ${colors.text};

      svg {
        color: ${colors.gray.gray60};
      }
    }
  `,
  toggleButtonGroup: css`
    padding: ${theme.spacing(1)};
    border: solid 1px ${colors.gray.gray10};
    border-radius: ${theme.spacing(2)};
    height: 2.5rem;
    gap: ${theme.spacing(2)};
  `,
}
