import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserTypeEnum } from '@dis/types/src/UsersAndRoles'

type UserManagementReducerState = {
  managementType: 'users' | 'groups'
  selectedTenantId: number
  userType: `${UserTypeEnum}`
}

const initialState: UserManagementReducerState = {
  managementType: 'users',
  selectedTenantId: 0,
  userType: 'tenant',
}

export const userManagementSlice = createSlice({
  initialState,
  name: 'userManagement',
  reducers: {
    init: () => initialState,
    set: (state: UserManagementReducerState, action: PayloadAction<UserManagementReducerState>) => {
      state.userType = action.payload.userType
      state.managementType = action.payload.managementType
      state.selectedTenantId = action.payload.selectedTenantId
    },
    setManagementType: (
      state: UserManagementReducerState,
      action: PayloadAction<UserManagementReducerState['managementType']>,
    ) => {
      state.managementType = action.payload
    },
    setSelectedTenantId: (
      state: UserManagementReducerState,
      action: PayloadAction<UserManagementReducerState['selectedTenantId']>,
    ) => {
      state.selectedTenantId = action.payload
    },
    setUserType: (
      state: UserManagementReducerState,
      action: PayloadAction<UserManagementReducerState['userType']>,
    ) => {
      state.userType = action.payload
    },
  },
})
