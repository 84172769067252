import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image51 from '@dis/assets/src/images/newTutorial/51.png'
import image52 from '@dis/assets/src/images/newTutorial/52.png'
import image53 from '@dis/assets/src/images/newTutorial/53.png'
import image54 from '@dis/assets/src/images/newTutorial/54.png'
import image55 from '@dis/assets/src/images/newTutorial/55.png'
import image56 from '@dis/assets/src/images/newTutorial/56.png'
import image57 from '@dis/assets/src/images/newTutorial/57.png'
import image58 from '@dis/assets/src/images/newTutorial/58.png'
import image59 from '@dis/assets/src/images/newTutorial/59.png'
import image60 from '@dis/assets/src/images/newTutorial/60.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const JourneyBasis = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.1 Setting the journey basis
      </Header>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1 Creating a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To create a journey, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Landing page"</strong>: click on the "Plus" button (Journeys section)
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image51} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"All journeys"</strong> screen: click on the "Create a new journey" button
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image52} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"All journeys"</strong> screen: click on the "Create a new journey" button
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image53} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all of the above scenarios, the "Create new journey" screen will then appear in which you
        can either create a completely new journey by clicking the "Create from scratch" button or
        use one of the prepared templates/examples.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image54} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1.1 Creating from scratch
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To create a journey from scratch, click on the "Create from scratch" button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image55} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1.2 Creating from a local template
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To use a local template, hover over the item and click on "Use" button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image56} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1.3 Creating from a global template
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To use a global template, hover over the item and click on "Use" button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image57} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1.4 Creating from an example
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To use an example, hover over the item and click on "Use" button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image58} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all the above scenarios, a modal window will appear in which you have to define bellow
        mentioned fields:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Step 1
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Name (mandotory field)</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Atlas (mandotory field)</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Folder</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image59} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Step 2
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Persona</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image60} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
