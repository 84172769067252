import { css } from '@emotion/css'
import { colors } from '@dis/styles'

export const styles = {
  primary: css`
    color: rgba(${colors.gray.gray100}, 0.87);
  `,
  title: css`
    margin: 0;
  `,
}
