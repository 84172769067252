import { Header, Paragraph } from '@dis/components'
import image24 from '@dis/assets/src/images/newTutorial/24.png'
import { styles } from '../styles'

/* eslint-disable react/no-unescaped-entities */
export const ImportFromXLSX = () => {
  return (
    <>
      <Header marginTop={2} variant="h2">
        1.4 Import from XLSX
      </Header>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        To import a journey from XLSX format, go to the journey and click on the upper right menu.
        From the list of choices, choose "Import from XLSX" option.
      </Paragraph>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        <img src={image24} width="600" alt="Settings Discovery" />
      </Paragraph>
    </>
  )
}
