import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { usePagination } from '@dis/hooks/src/usePagination'
import { TableContext } from '@dis/context/src/table/TableContext'
import { dispatchedActions } from '@dis/redux'
import { TableContextType, TableProviderProps } from './types'
import { sort } from './utils'

export const TableProvider = <RowType,>({
  children,
  rows,
  orderLikeNumber = [],
  defaultOrderDirection = 'asc',
  defaultOrderBy,
  rowsPerPage,
  storeSortToRedux,
  ...rest
}: TableProviderProps<RowType>) => {
  const [orderDirection, setOrderDirection] =
    useState<TableContextType<RowType>['orderDirection']>(defaultOrderDirection)

  const [orderBy, setOrderBy] = useState<TableContextType<RowType>['orderBy']>(defaultOrderBy)

  useEffect(() => {
    if (storeSortToRedux)
      dispatchedActions.table.setSort({
        type: storeSortToRedux,
        value: {
          direction: orderDirection || defaultOrderDirection,
          item: String(orderBy || 'id'),
        },
      })
  }, [orderDirection, orderBy, storeSortToRedux, defaultOrderDirection])

  const sortedRows = useMemo(
    () => sort<(typeof rows)[number]>(rows, orderDirection, orderBy, orderLikeNumber),
    [rows, orderBy, orderDirection, orderLikeNumber],
  )

  const {
    currentData: paginatedRows,
    maxPage,
    currentPage,
    handlePageChange,
  } = usePagination(sortedRows, rowsPerPage || rows.length, !rowsPerPage)

  const handleRequestSort = useCallback<TableContextType<any>['onRequestSort']>(
    (property) => {
      const isAsc = orderBy === property && orderDirection === 'asc'

      setOrderDirection(isAsc ? 'desc' : 'asc')

      setOrderBy(property as TableContextType<RowType>['orderBy'])
    },
    [orderDirection, orderBy],
  )

  return (
    <TableContext.Provider
      value={{
        ...rest,
        currentPage,
        maxPage,
        onPageChange: handlePageChange,
        onRequestSort: handleRequestSort,
        orderBy,
        orderDirection,
        rowsPerPage,
        sortedRows: !rowsPerPage ? sortedRows : paginatedRows,
      }}>
      {children}
    </TableContext.Provider>
  )
}
