import React, { useContext } from 'react'
import { SortableItemContext } from '@dis/context/src/sortableItem/SortableItemContext'
import { styles } from './styles'

// TODO: Move SVG string to a file
const icon = (
  <svg viewBox="0 0 20 20" width="12">
    <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
  </svg>
)

export const DragHandleButton = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <button className={styles.dragHandleButton} {...attributes} {...listeners} ref={ref}>
      {icon}
    </button>
  )
}
