// TODO: Move to global utils
export const dateHandler = (num: number | undefined, isRtl: boolean) => {
  if (!num) return ''

  return new Date(num)
    .toLocaleString(isRtl ? 'ar-EG' : 'de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(',', '  ')
    .replace(/\//g, '.')
}
