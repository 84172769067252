import { useTheme } from '@mui/material'
import { Paragraph } from '@dis/components'
import { FieldError } from 'react-hook-form/dist/types'

export type Props = {
  error?: FieldError
}

export const ErrorNotice = ({ error }: Props) => {
  const theme = useTheme()

  return error?.message ? (
    <div style={{ color: theme.palette.error.main }}>
      <Paragraph>{error?.message}</Paragraph>
    </div>
  ) : null
}
