import { yup } from '@dis/form'
import { MAX_NAME_LENGTH } from '@dis/constants'
import { getSelectAtlasValidationSchemaPart } from '../selectAtlasAndFolder/utils'

type GetCreateOrDuplicateJourneyValidationPart = {
  maxJourneyNameLength?: number
}

export const getCreateOrDuplicateJourneyValidationPart = (
  props?: GetCreateOrDuplicateJourneyValidationPart,
) => ({
  ...getSelectAtlasValidationSchemaPart(),
  name: yup
    .string()
    .required()
    .max(props?.maxJourneyNameLength || MAX_NAME_LENGTH),
})
