import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { ids, Journey } from '../tutorialConstants'
import { JourneyBasis } from './JourneysBasis'
import { BaseLayers } from './BaseLayers'
import { AdditionalJourney } from './AdditionlaJourney'
import { useScroll } from '../useScroll'

export const TutorialJourneys = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}
          </Button>
          <TutorialSidebarContent
            initial={ids.journeys}
            onSelectedItemChange={() => ids.journeys}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader header={t(tKeys.tutorials.tutorialJourneysTitleScreen)} />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Journey.JourneyBasis)}>
                1.1 Setting the journey basis
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Journey.BaseLayer)}
                className={styles.menuText}>
                1.2 Settings associated with base layers
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Journey.AdditionalJourney)}
                className={styles.menuText}>
                1.3 Settings associated with additional layers
              </ListItem>
            </List>
            <JourneyBasis id={Journey.JourneyBasis} />
            <BaseLayers id={Journey.BaseLayer} />
            <AdditionalJourney id={Journey.AdditionalJourney} />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
