import { useCallback, useRef } from 'react'
import { useAtlasesAndFolders } from '@dis/hooks/src/useAtlasesAndFolders'
import {
  WsMessageModel,
  WsMoveFolderMessageDataModel,
  WsMoveJourneyMessageDataModel,
} from '@dis/types/src/wsModels'
import { useMove } from '@dis/hooks/src/useMove'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { MoveRestoreForm } from '@dis/types/src/forms'
import { useRestore } from '@dis/hooks/src/useRestore'
import { Paragraph } from '@dis/components'
import { t } from 'i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { JourneyLocation } from '@dis/types/src/JourneyTypes'
import { MoveRestoreType } from './MoveRestoreModal'

type Props = {
  atlasId?: number
  onClose: VoidFunction
  refetch?: VoidFunction
  type: MoveRestoreType
}

type NavigationProps = {
  autoNavigateToParent?: boolean
  notificationNavigateToParent?: boolean
  preventAutoNavigation?: boolean
  showNotification?: boolean
  target?: JourneyLocation
}

export const useMoveRestoreModal = ({ atlasId, onClose, refetch, type }: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const {
    atlasName,
    atlasOptions,
    folderOptions,
    getFolderName,
    loading: foldersLoading,
    refetch: refetchAtlasOptions,
  } = useAtlasesAndFolders({ atlasId })

  const { navigate } = useNavTo()
  const navigationPropsRef = useRef<NavigationProps>()

  const getTargetParent = useCallback((target?: JourneyLocation) => {
    if (!target) return target
    const parentTarget = { ...target }
    if (parentTarget.journeyId) {
      parentTarget.journeyId = undefined
    }
    return parentTarget
  }, [])

  const navigateToTarget = useCallback(
    (target?: JourneyLocation) => {
      if (target) {
        if (target.atlasId && target.folderId && target.journeyId) {
          dispatchedActions.journeys.setSelectedJourneyId(target.journeyId)
          dispatchedActions.journeys.setJourneyName()
          navigate('journeyFolder', {
            atlasId: target.atlasId,
            folderId: target.folderId,
            journeyId: target.journeyId,
          })
        } else if (target.atlasId && target.journeyId) {
          dispatchedActions.journeys.setSelectedJourneyId(target.journeyId)
          dispatchedActions.journeys.setJourneyName()
          navigate('journey', {
            atlasId: target.atlasId,
            journeyId: target.journeyId,
          })
        } else if (target.atlasId && target.folderId) {
          navigate('folder', {
            atlasId: target.atlasId,
            folderId: target.folderId,
          })
        } else if (target.atlasId) {
          navigate('atlas', {
            atlasId: target.atlasId,
          })
        }
      }
    },
    [navigate],
  )

  const onDataCallback = useCallback(() => {
    const navigationProps = navigationPropsRef.current

    if (navigationProps?.showNotification) {
      const onClickOpen = () => {
        const target = navigationProps.notificationNavigateToParent
          ? getTargetParent(navigationProps.target)
          : navigationProps.target
        navigateToTarget(target)
      }

      dispatchedActions.centralNotification.showNotification({
        content: (
          <Paragraph>
            {type === 'folder'
              ? t(tKeys.notifications.notificationFolderSuccessfully)
              : t(tKeys.notifications.notificationJourneySuccessfully)}{' '}
            <a onClick={onClickOpen} href="#">
              {t(tKeys.notifications.notificationEndMoved)}
            </a>
          </Paragraph>
        ),
        displayTimeInMs: 5000,
      })
    }

    if (!navigationProps?.preventAutoNavigation && navigationProps?.target) {
      const target = navigationProps.autoNavigateToParent
        ? getTargetParent(navigationProps.target)
        : navigationProps.target
      navigateToTarget(target)
    }
  }, [getTargetParent, navigateToTarget, type])

  const { send: sendMove, loading: moveLoading } = useMove({ onData: onDataCallback })
  const { send: sendRestore, loading: restoreLoading } = useRestore({ onData: refetch })

  const handleMoveJourney = useCallback(
    (
      { atlasId, folderId }: MoveRestoreForm,
      journeyId: number,
      preventAutoNavigation: boolean,
      autoNavigateToParent: boolean,
      notificationNavigateToParent: boolean,
    ) => {
      if (tenantId) {
        const moveJourneyData: WsMoveJourneyMessageDataModel = {
          sourceJourneyId: journeyId,
          targetAtlasId: folderId === 0 ? undefined : atlasId,
          targetFolderId: folderId,
        }

        navigationPropsRef.current = {
          autoNavigateToParent,
          notificationNavigateToParent,
          preventAutoNavigation,
          showNotification: true,
          target: { atlasId, folderId, journeyId },
        }

        sendMove({
          move: {
            data: moveJourneyData,
            id: '',
            model: WsMessageModel.Document,
          },
          tenantId,
        })
      }
      onClose()
    },
    [onClose, sendMove, tenantId],
  )

  const handleMoveFolder = useCallback(
    ({ atlasId }: MoveRestoreForm, folderId: number) => {
      if (tenantId) {
        const moveFolderData: WsMoveFolderMessageDataModel = {
          sourceFolderId: folderId,
          targetAtlasId: atlasId,
        }

        navigationPropsRef.current = {
          preventAutoNavigation: true,
          showNotification: true,
          target: { atlasId },
        }

        sendMove({
          move: {
            data: moveFolderData,
            id: '',
            model: WsMessageModel.Folder,
          },
          tenantId: tenantId,
        })
      }
      onClose()
    },
    [onClose, sendMove, tenantId],
  )

  const handleRestore = useCallback(
    ({ atlasId, folderId }: MoveRestoreForm, sourceJourneyId: number) => {
      if (tenantId) {
        sendRestore({
          recovery: {
            data: {
              sourceJourneyId,
              targetAtlasId: folderId ? undefined : atlasId,
              targetFolderId: folderId,
            },
            id: '',
            model: WsMessageModel.Document,
          },
          tenantId: tenantId,
        })
      }
      onClose()
    },
    [onClose, sendRestore, tenantId],
  )

  return {
    atlasName,
    atlasOptions,
    folderOptions,
    getFolderName,
    handleMoveFolder,
    handleMoveJourney,
    handleRestore,
    loading: foldersLoading || moveLoading || restoreLoading,
    refetchAtlasOptions,
  }
}
