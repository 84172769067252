import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { SearchResultContents } from '@dis/redux/src/search/searchTypes'
import ContentsCard from './ContentsCard'
import TitleContainer from './TitleContainer'
import { styles } from './styles'

type ContentsMapperProps = {
  data: SearchResultContents[]
}

export const ContentsMapper = ({ data }: ContentsMapperProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.contentsMapperContainer}>
      {data.length > 0 && <TitleContainer title={t(tKeys.search.inTheJourneyContent)} />}
      {data?.map((item) => {
        return (
          <div key={item.id} className={styles.contentsCardWrapper}>
            <ContentsCard
              name={item.name}
              occurrencesCount={item.occurrencesCount}
              atlasId={item?.atlasId}
              journeyId={item?.journeyId}
            />
          </div>
        )
      })}
    </div>
  )
}
