import { FC } from 'react'
import { Pagination as MuiPagination } from '@mui/material'
import { cx } from '@emotion/css'
import { styles } from './styles'

type Props = {
  className?: string
  currentPage: number
  maxPage: number
  onPageChange: (event: unknown, page: number) => void
}

export const Pagination: FC<Props> = ({ className, currentPage, onPageChange, maxPage }) => {
  if (!maxPage || maxPage === 1) {
    return null
  }

  return (
    <div className={cx(styles.pagination, className)}>
      <MuiPagination count={maxPage} page={currentPage} onChange={onPageChange} color="primary" />
    </div>
  )
}
