import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  dragHandleButton: css`
    display: flex;
    width: 12px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: ${colors.transparent};
    -webkit-tap-highlight-color: ${colors.transparent};

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:focus-visible {
      box-shadow: ${theme.shadows[3]};
    }

    svg {
      flex: 0 0 auto;
      margin: auto;
      height: 100%;
      overflow: visible;
      fill: ${colors.gray.gray40};
    }
  `,
}
