import * as yup from 'yup'
import { t } from 'i18next'
import { translations } from './translations/translations'

type ItemFnc = (values: Record<string, any> | undefined) => string

type Locale = Record<string, Record<string, ItemFnc>>

// Not possible to change yup locale after init so dynamic translation is used
export const setYupLocale = () => {
  const locale: Locale = {}

  Object.entries(translations.yup).forEach(([section, object]) => {
    if (!locale[section]) {
      locale[section] = {}
    }

    Object.entries(object).forEach(([key]) => {
      locale[section][key] = (values) => t(`yup.${section}.${key}`, values)
    })
  })

  yup.setLocale(locale)
}
