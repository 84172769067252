import { css } from '@emotion/css'
import { theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  pagination: css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${theme.spacing(3)};
    margin-top: ${theme.spacing(6)};

    .MuiPaginationItem-root {
      border-radius: 4px;
      font-weight: ${typographyFontWeight.semibold};
      min-width: 0;
    }
  `,
}
