import { useCallback } from 'react'
import { useTenantDashboard } from '@dis/hooks/src/useSubscribe'
import { DashboardContainer } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { useHandleOpen } from '@dis/hooks/src/useHandleOpen'
import { useAppSelector } from '@dis/redux'
import { selectPermissions } from '@dis/redux/src/security/selectors'
import { PersonaPreview } from '../persona-preview/PersonaPreview'
import { styles as personaStyles } from './styles'
import { NewPersonaModal } from '../Persona/NewPersonaModal/NewPersonaModal'

export const PersonasPreview = () => {
  const { navigate, navigateFactory } = useNavTo()
  const { handleClose, handleOpen, open } = useHandleOpen()

  const { dashboardData } = useTenantDashboard({
    autoRefetch: {
      create: true,
      delete: true,
    },
  })

  const personas = dashboardData?.personas

  const { canCreateNewPersona } = useAppSelector(selectPermissions)

  const onClickPersona = useCallback(
    (personaId: number) => () => {
      navigate('persona', { personaId })
    },
    [navigate],
  )

  return (
    <DashboardContainer
      gridArea="Personas"
      canCreate={canCreateNewPersona}
      navigate={navigateFactory('personas')}
      sendCreate={handleOpen}
      textButtonTitle={tKeys.atlasesPreview.showAll}
      isLoader={!personas}
      title={tKeys.personasPreview.personas}>
      {personas && (
        <div className={personaStyles.personasWrapper}>
          {personas?.map((persona) => (
            <div
              key={persona.id}
              className={personaStyles.personaItem}
              onClick={onClickPersona(persona.id)}>
              <PersonaPreview image={persona.image} name={persona.name} basic={persona.basic} />
            </div>
          ))}
        </div>
      )}

      <NewPersonaModal isOpen={open && canCreateNewPersona} onClose={handleClose} />
    </DashboardContainer>
  )
}
