import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit'
import { CentralModalDialogConfiguration } from '@dis/types/src/centralModalDialogTypes'

const initialState: Array<CentralModalDialogConfiguration> = []

export const centralModalDialogSlice = createSlice({
  initialState,
  name: 'centralModalDialog',
  reducers: {
    hideAllModalDialogs: (_state, _action: PayloadAction<undefined>) => initialState,
    hideModalDialog: (
      state,
      { payload }: PayloadAction<string | { payload: CentralModalDialogConfiguration }>,
    ) => {
      const id = typeof payload === 'string' ? payload : payload.payload.id

      return state.filter((item) => item.id !== id)
    },
    showModalDialog: {
      prepare: (
        payload: CentralModalDialogConfiguration,
      ): { payload: CentralModalDialogConfiguration } => {
        return {
          payload: {
            ...payload,
            hasCrossClose: !!payload.hasCrossClose,
            hasFooter:
              payload.hasFooter === undefined
                ? !!payload.primaryButton || !!payload.secondaryButton
                : payload.hasFooter,
            id: payload.id || nanoid(),
            severity: payload.severity,
          },
        }
      },
      reducer: (state, { payload }: PayloadAction<CentralModalDialogConfiguration>) => {
        const index = state.findIndex(({ id }) => id === payload.id)

        if (index < 0) {
          state.push(payload)
        }
        // Update config with same ID
        else {
          state[index] = payload
        }
      },
    },
  },
})
