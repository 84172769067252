import { MouseEventHandler, SyntheticEvent, useCallback, useRef } from 'react'
import { ListItemIcon, Menu, MenuItem as MenuItemComponent, PopoverOrigin } from '@mui/material'
import { useAppSelector } from '@dis/redux'
import { colors, getTypographyProp } from '@dis/styles'
import { useNavTo } from '@dis/hooks'

import LeftArrowIcon from '@dis/assets/src/icons/LeftArrowIcon.svg'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import RightArrowIcon from '@dis/assets/src/icons/RightArrowIcon.svg'
import { Paragraph, SvgImage } from '@dis/components'
import { cx } from '@emotion/css'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { styles } from './styles'
import { MenuItem } from './types'

type Props = {
  handleClose: VoidFunction
  menuItem: MenuItem
  opened: boolean
  submenuOnLeft?: boolean
}

export const Item = ({
  handleClose,
  menuItem: {
    items,
    icon,
    iconColor,
    isRed,
    labelColor,
    fontSize = 'paragraph.body',
    invisible,
    disabled,
    navigateTo,
    onClick,
    label,
    suffix,
  },
  opened,
  submenuOnLeft = false,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const { navigate } = useNavTo()

  const { anchorEl, handleClose: handleCloseSubmenu, handleOpen } = useAnchor()

  const hasChildren = !!items?.length

  let iconColorWrapper = iconColor || colors.gray.gray100
  let labelColorWrapper = labelColor || colors.gray.gray100

  if (isRed) {
    iconColorWrapper = colors.semantic.red
    labelColorWrapper = colors.semantic.red
  }

  const anchorOrigin: PopoverOrigin = {
    horizontal: 'right',
    vertical: 'top',
  }

  const transformOrigin: PopoverOrigin = { horizontal: 'left', vertical: 'top' }

  if (submenuOnLeft && !isRtl) {
    anchorOrigin.horizontal = 'left'
    transformOrigin.horizontal = 'right'
  } else if (isRtl) {
    anchorOrigin.horizontal = 'left'
    transformOrigin.horizontal = 'right'
  }

  const onMenuItemClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      event.preventDefault()

      if (!disabled && !hasChildren) {
        onClick?.()

        if (navigateTo) {
          navigate(navigateTo)
        }

        handleClose?.()
        handleCloseSubmenu()
      }

      return false
    },
    [disabled, onClick, handleClose, hasChildren, navigateTo, navigate, handleCloseSubmenu],
  )

  const onMenuItemEnter = useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      if (!disabled && hasChildren) {
        handleOpen(event)
      }
    },
    [disabled, hasChildren, handleOpen],
  )

  const handleCloseWrapper = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()

      handleCloseSubmenu()
    },
    [handleCloseSubmenu],
  )

  if (invisible) {
    return <></>
  }

  return (
    <MenuItemComponent
      onClick={onMenuItemClick}
      onMouseEnter={onMenuItemEnter}
      onMouseLeave={handleCloseSubmenu}
      className={cx(
        styles.item.root,
        styles.item.labelColor(labelColorWrapper),
        styles.item.labelSize(
          getTypographyProp(fontSize, 'fontSize'),
          getTypographyProp(fontSize, 'lineHeight'),
        ),
      )}>
      {!!icon && (
        <ListItemIcon color={iconColorWrapper} className={styles.item.iconContainer}>
          <SvgImage color={iconColorWrapper} src={icon} />
        </ListItemIcon>
      )}
      <Paragraph>{label}</Paragraph>
      {!!items?.length && (
        <div className={styles.item.submenuContainer}>
          {!!suffix && (
            <Paragraph
              className={cx(
                styles.item.suffix,
                styles.item.labelSize(
                  getTypographyProp(fontSize, 'fontSize'),
                  getTypographyProp(fontSize, 'lineHeight'),
                ),
              )}>
              {suffix}
            </Paragraph>
          )}
          <div className={styles.item.leftRightIconContainer}>
            <SvgImage width="9px" height="9px" src={isRtl ? LeftArrowIcon : RightArrowIcon} />
          </div>
          <Menu
            className={styles.item.submenu}
            autoFocus={false}
            disableAutoFocus
            disableEnforceFocus
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseWrapper}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}>
            <div ref={wrapperRef} className={styles.item.submenuContent}>
              {!disabled &&
                items?.map((item) => (
                  <Item
                    handleClose={handleClose}
                    key={`${label}_${item.label}`}
                    opened={opened}
                    menuItem={item}
                  />
                ))}
            </div>
          </Menu>
        </div>
      )}
    </MenuItemComponent>
  )
}
