import React, { FC, useCallback } from 'react'
import { useController, Control } from 'react-hook-form'
import { TextArea as TextAreaComp, type TextAreaProps } from '@dis/components'
import { submitByVirtualButton } from '../../utils'

export type Props = Omit<TextAreaProps, 'value'> & {
  formControl: Control<any>
  name: string
  shouldUnregister?: boolean
  submitOnBlur?: boolean
}

export const TextArea: FC<Props> = ({
  name,
  formControl,
  shouldUnregister = false,
  submitOnBlur = false,
  ...restProps
}) => {
  const restPropsOnChange = restProps.onChange
  const restPropsOnBlur = restProps.onBlur
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<TextAreaProps['onChange']>>(
    (e) => {
      onChange(e)
      restPropsOnChange?.(e)
    },
    [restPropsOnChange, onChange],
  )

  const onBlurWrapper = useCallback<NonNullable<TextAreaProps['onBlur']>>(
    (e) => {
      onBlur()
      restPropsOnBlur?.(e)

      if (submitOnBlur) {
        const target = e.target as HTMLTextAreaElement | undefined
        submitByVirtualButton(target)
      }
    },
    [onBlur, restPropsOnBlur, submitOnBlur],
  )

  return (
    <TextAreaComp
      {...restProps}
      error={error}
      onChange={onChangeWrapper}
      onBlur={onBlurWrapper}
      value={value || ''}
    />
  )
}
