import { useCallback, useState } from 'react'
import { useNavTo } from '@dis/hooks'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { Tenant } from '@dis/types/src/TenantTypes'
import { useHandleOpen } from '@dis/hooks/src/useHandleOpen'
import { selectLogoTenantMapEx } from '@dis/redux/src/tenants/tenantsSelectors'

export const useTenantPage = () => {
  const { navigate } = useNavTo()
  const isLogo = useAppSelector(selectLogoTenantMapEx)

  const navigateToLandingPage = useCallback(
    ({ initialized, id, name, istemplate }: Tenant) =>
      () => {
        if (initialized) {
          dispatchedActions.tenants.setSelectedTenantId(id)
          dispatchedActions.tenants.setSelectedTenantName(name)
          dispatchedActions.tenants.setSelectedIsTemplateTenant(istemplate)
          if (isLogo) {
            dispatchedActions.tenants.setSelectedLogoTenant(isLogo)
          }

          navigate('tenant', { tenantId: id })
        }
      },
    [navigate, isLogo],
  )

  return { navigateToLandingPage }
}

export const useTenantEdit = () => {
  const {
    handleOpen: handleOpenEditTenant,
    open: openEditTenant,
    handleClose: handleCloseEditTenant,
  } = useHandleOpen()
  const [tenantChange, setTenantToChange] = useState<Tenant>()
  const showEditModal = useCallback(
    (data: Tenant) => () => {
      setTenantToChange(data)
      handleOpenEditTenant()
    },
    [handleOpenEditTenant],
  )

  const hideEditModal = useCallback(() => {
    setTenantToChange(undefined)
    handleCloseEditTenant()
  }, [handleCloseEditTenant])

  return { handleOpenEditTenant, hideEditModal, openEditTenant, showEditModal, tenantChange }
}

export const useTenantDelete = () => {
  const [tenantDelete, setTenantDelete] = useState<Tenant>()
  const {
    handleOpen: handleOpenDeleteTenant,
    open: openDeleteTenant,
    handleClose: handleCloseDeleteTenant,
  } = useHandleOpen()

  const openDeleteTenantModal = useCallback(
    (data: Tenant) => () => {
      setTenantDelete(data)
      handleOpenDeleteTenant()
    },
    [handleOpenDeleteTenant, setTenantDelete],
  )

  return {
    handleCloseDeleteTenant,
    openDeleteTenant,
    openDeleteTenantModal,
    tenantDelete,
  }
}
