import { useMemo, useState } from 'react'

const MIN_PAGE = 1

export const usePagination = <Data extends Array<unknown>>(
  data: Data,
  rowsPerPage: number,
  disablePagination?: boolean,
) => {
  const [currentPage, setCurrentPage] = useState(MIN_PAGE)
  const maxPage = Math.ceil(data.length / rowsPerPage)

  const currentData = useMemo((): Data => {
    if (disablePagination) return data

    if (maxPage < currentPage) {
      setCurrentPage(maxPage)
    }
    if (currentPage === 0 && maxPage !== 0) {
      setCurrentPage(MIN_PAGE)
    }

    const start = (currentPage - 1) * rowsPerPage
    const end = start + rowsPerPage

    return data.slice(start, end) as Data
  }, [disablePagination, data, maxPage, currentPage, rowsPerPage])

  const handlePageChange = (event: unknown, page: number) => {
    const pageNumber = Math.max(MIN_PAGE, page)
    setCurrentPage(Math.min(pageNumber, maxPage))
  }

  return {
    currentData,
    currentPage,
    handlePageChange,
    maxPage,
  }
}
