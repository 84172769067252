import ExportIcon from '@dis/assets/src/icons/ExportIcon.svg'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import IntroductionIcon from '@dis/assets/src/icons/IntroductionIcon.svg'
import PersonasIcon from '@dis/assets/src/icons/PersonasIcon.svg'
import NavigationIcon from '@dis/assets/src/icons/NavigationIcon.svg'
import SettingsIcon from '@dis/assets/src/icons/SettingsIcon.svg'
import { tKeys } from '@dis/languages'
import { RouteNames } from '@dis/routing'

export const ids: Record<string, RouteNames> = {
  exportImport: 'tutorialExportImport',
  introduction: 'tutorialIntroduction',
  journeys: 'tutorialJourneys',
  navigation: 'tutorialNavigation',
  personas: 'tutorialPersonas',
  settings: 'tutorialSettings',
}

export const tutorialData = [
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialIntroductionDescription,
    goTo: ids.introduction,
    iconSrc: IntroductionIcon,
    titleKey: tKeys.tutorials.tutorialIntroductionTitle,
  },
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialNavigationDescription,
    goTo: ids.navigation,
    iconSrc: NavigationIcon,
    titleKey: tKeys.tutorials.tutorialNavigationTitle,
  },
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialJourneysDescription,
    goTo: ids.journeys,
    iconSrc: JourneyIcon,
    titleKey: tKeys.tutorials.tutorialJourneysTitle,
  },
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialPersonasDescription,
    goTo: ids.personas,
    iconSrc: PersonasIcon,
    titleKey: tKeys.tutorials.tutorialPersonasTitle,
  },
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialExportImportDescription,
    goTo: ids.exportImport,
    iconSrc: ExportIcon,
    titleKey: tKeys.tutorials.tutorialExportImportTitle,
  },
  {
    buttonTitleKey: tKeys.tutorials.tutorialButtonTitle,
    descriptionKey: tKeys.tutorials.tutorialSettingsDescription,
    goTo: ids.settings,
    iconSrc: SettingsIcon,
    titleKey: tKeys.tutorials.tutorialSettingsTitle,
  },
]

export enum Introduction {
  BasicElement = 'basicElement',
  Glossary = 'glossary',
  SigningIn = 'signingIn',
  UserRoles = 'userRoles',
}

export enum Navigation {
  Atlas = 'atlas',
  Folder = 'folder',
  Journey = 'journey',
  Tenant = 'tenant',
}

export enum Journey {
  AdditionalJourney = 'additionalJourney',
  BaseLayer = 'baseLayer',
  JourneyBasis = 'journeyBasis',
}

export enum Personas {
  AddingPersona = 'addingPersona',
  AddingPersonaLayer = 'addingPersonaLayer',
  DeletePersona = 'deletePersona',
  NewPersona = 'newPersona',
  PersonaInformation = 'personaInformation',
  RemovePersona = 'removePersona',
  RemovePersonaLayer = 'removePersonaLayer',
}

export enum Settings {
  BacklogManagement = 'backlogManagement',
  CapabilityManagement = 'capabilityManagement',
  LanguageSelection = 'languageSelection',
}

export enum Export {
  ExportToPdf = 'exportToPdf',
  ExportToXLSX = 'exportToXLSX',
  ExportToXML = 'exportToXML',
  ImportFromXLSX = 'importFromXLSX',
}
