import { colors, theme, typography, typographyFontWeight } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  statusButton: {
    root: css`
      color: ${colors.gray.gray0};
      min-height: 0;
      min-width: 0;
      height: 1.75rem;
      font-size: ${typography.paragraph.small.fontSize};
      line-height: ${typography.paragraph.small.lineHeight};
      font-weight: ${typographyFontWeight.regular};
    `,
    withoutText: css`
      padding: 0;
      margin: 0;
      width: 1.75rem;

      span {
        padding: 0;
        margin: 0;
        max-width: 100%;
        max-height: 100%;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    `,
  },
  statusContainer: css`
    display: flex;
    margin-inline-end: ${theme.spacing(3)};
    align-items: center;
    color: ${colors.gray.gray5};
  `,
}
