import SortUpIcon from '@dis/assets/src/icons/SortUpIcon.svg'
import SortDownIcon from '@dis/assets/src/icons/SortDownIcon.svg'
import { cx } from '@dis/styles'
import { SvgImage } from '../SvgImage/SvgImage'
import { styles } from './styles'

type Props = {
  className: string
}

export const SortIcon = ({ className }: Props) => {
  let chosenIcon = null

  if (className.includes('MuiTableSortLabel-iconDirectionAsc')) {
    chosenIcon = SortUpIcon
  }
  if (className.includes('MuiTableSortLabel-iconDirectionDesc')) {
    chosenIcon = SortDownIcon
  }

  return chosenIcon ? (
    <SvgImage className={cx(className, styles.tableHead.sortIcon)} src={chosenIcon} />
  ) : null
}
