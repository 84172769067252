import { useEffect } from 'react'
import { Footer } from '@dis/modules/src/footer/Footer'
import { Header } from '@dis/modules/src/Header/Header'
import { Login } from '@dis/screens'
import { GRID_WRAPPER_ID } from '@dis/constants'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { checkExistingSession } from '@dis/utils/src/msalInstance'
import { selectIsAuthenticated } from '@dis/redux/src/security/selectors'
import { useOnlineStatus } from '@dis/hooks/src/useOnlineStatus/useOnlineStatus'
import { WebsocketApi } from '@dis/api'
import { PreloadSVGs } from '@dis/modules/src/PreloadSVGs/PreloadSVGs'
import { styles } from './styles'
import { Authenticated } from './Authenticated'

export const App = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  useOnlineStatus()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      const modal = dispatchedActions.centralModalLoader.showModalLoader()

      return () => {
        dispatchedActions.centralModalLoader.hideModalLoader(modal)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    checkExistingSession()
  }, [])

  useEffect(() => {
    // Set initial state of network
    dispatchedActions.api.setIsBrowserOnline(window.navigator.onLine)

    const onlineCallback = () => {
      dispatchedActions.api.setIsBrowserOnline(true)
    }
    const offlineCallback = () => {
      dispatchedActions.api.setIsBrowserOnline(false)

      // FIXME
      // eslint-disable-next-line no-console
      console.log('App.tsx - call WebsocketApi.close')

      /**
       * If the wired ethernet connection is physically unplugged the websocket looks like it's still connected. So it's
       * necessary to force closing websocket. Then websocket automatically try to re-open.
       * If the internet connection is disabled in the operating system or in the web-browser the websocket inform
       * immediately it's closed.
       */
      WebsocketApi.close(true)
    }

    window.addEventListener('online', onlineCallback)
    window.addEventListener('offline', offlineCallback)

    return () => {
      window.removeEventListener('online', onlineCallback)
      window.removeEventListener('offline', offlineCallback)
    }
  }, [])

  // 3-state logic avoids content blinking
  let content = null
  if (isAuthenticated) {
    content = <Authenticated />
  } else if (isAuthenticated === false) {
    content = <Login />
  }

  return (
    <div className={styles.root}>
      <Header />
      <div id={GRID_WRAPPER_ID} className={styles.mainWrapper}>
        {content}
        <Footer />
      </div>
      <PreloadSVGs />
    </div>
  )
}
