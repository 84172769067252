import { useEffect, useRef } from 'react'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectIsBrowserOnline, selectIsWebsocketConnected } from '@dis/redux/src/api/selectors'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { selectIsAuthenticated } from '@dis/redux/src/security/selectors'
import WifiOn from '@dis/assets/src/icons/WifiOn.svg'
import WifiOff from '@dis/assets/src/icons/WifiOff.svg'
import { SvgImage } from '@dis/components'
import { styles } from './styles'

const setReloadPageTimer = () =>
  setTimeout(() => {
    window.location.reload()
  }, 1 * 60 * 1_000)

export const useOnlineStatus = () => {
  const isBrowserOnline = useAppSelector(selectIsBrowserOnline)
  const isWebsocketConnected = useAppSelector(selectIsWebsocketConnected)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const isNetworkOutageDialog = useRef(false)

  const { t } = useTranslation()

  let textKey = ''

  if (
    isAuthenticated &&
    (!isBrowserOnline || (isBrowserOnline && isWebsocketConnected === false))
  ) {
    textKey = tKeys.api.networkOutage.reconnecting
  }

  useEffect(() => {
    if (textKey) {
      const loader = dispatchedActions.centralModalLoader.showModalLoader({
        overlayPriority: 'high',
        text: t(textKey),
      })

      return () => {
        dispatchedActions.centralModalLoader.hideModalLoader(loader)
      }
    }
  }, [textKey, t])

  useEffect(() => {
    if (isAuthenticated) {
      if (!isBrowserOnline) {
        isNetworkOutageDialog.current = true

        const notif = dispatchedActions.centralNotification.showNotification({
          className: styles.notificationConnection(false),
          content: t(tKeys.api.networkOutage.notification.outage),
          displayTimeInMs: 5000,
          icon: <SvgImage src={WifiOff} />,
          title: t(tKeys.api.networkOutage.notification.outageTitle),
        })

        const timeoutRef = setReloadPageTimer()

        return () => {
          clearTimeout(timeoutRef)
          dispatchedActions.centralNotification.hideNotification(notif)
        }
      } else if (isWebsocketConnected === false) {
        isNetworkOutageDialog.current = true

        const notif = dispatchedActions.centralNotification.showNotification({
          className: styles.notificationConnection(false),
          content: t(tKeys.api.networkOutage.notification.reconnecting),
          displayTimeInMs: 5000,
          icon: <SvgImage src={WifiOff} />,
          title: t(tKeys.api.networkOutage.notification.outageTitle),
        })

        const timeoutRef = setReloadPageTimer()

        return () => {
          clearTimeout(timeoutRef)
          dispatchedActions.centralNotification.hideNotification(notif)
        }
      } else if (isNetworkOutageDialog.current) {
        isNetworkOutageDialog.current = false

        const notif = dispatchedActions.centralNotification.showNotification({
          className: styles.notificationConnection(true),
          content: t(tKeys.api.networkOutage.notification.reconnected),
          displayTimeInMs: 5000,
          icon: <SvgImage src={WifiOn} />,
          title: t(tKeys.api.networkOutage.notification.reconnectedTitle),
        })

        return () => {
          dispatchedActions.centralNotification.hideNotification(notif)
        }
      }
    }
  }, [textKey, t, isAuthenticated, isBrowserOnline, isWebsocketConnected])
}
