import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { Paragraph, SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import image1 from '@dis/assets/src/images/newTutorial/1.png'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { ids, Introduction } from '../tutorialConstants'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { Glossary } from './Glossary'
import { UserRoles } from './UserRoles'
import { SigningIn } from './SigningIn'
import { BasicElements } from './BasicElements'
import { useScroll } from '../useScroll'

const msg = tKeys.tutorials

export const TutorialIntroduction = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}
          </Button>
          <TutorialSidebarContent
            initial={ids.introduction}
            onSelectedItemChange={() => ids.introduction}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader
              header={t(msg.tutorialIntroductionTitleCard)}
              description={t(msg.tutorialIntroductionDescriptionScreen)}
              hasButton
            />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Introduction.Glossary)}>
                1.1 Glossary
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Introduction.UserRoles)}
                className={styles.menuText}>
                1.2 User roles
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Introduction.SigningIn)}
                className={styles.menuText}>
                1.3 Signing in
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Introduction.BasicElement)}
                className={styles.menuText}>
                1.4 Basic element
              </ListItem>
            </List>
            <div>
              <Paragraph variant="medium" className={styles.subtitleDescription}>
                {t(msg.tutorialIntroductionbrowsers)}
              </Paragraph>
              <List
                sx={{
                  listStylePosition: 'inside',
                  listStyleType: 'disc',
                }}>
                <ListItem sx={{ display: 'list-item' }}>
                  {t(msg.introductionBrowsers.chrome)}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t(msg.introductionBrowsers.mozilla)}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t(msg.introductionBrowsers.safari)}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t(msg.introductionBrowsers.edge)}
                </ListItem>
              </List>
            </div>
            <Paragraph variant="medium" className={styles.subtitleDescription}>
              {t(msg.tutorialIntroductionOptimized)}
            </Paragraph>
            <Paragraph variant="medium" className={styles.subtitleDescription}>
              <img src={image1} alt="Introduction Discovery" width="300" />
            </Paragraph>
            <Glossary id={Introduction.Glossary} />
            <UserRoles id={Introduction.UserRoles} />
            <SigningIn id={Introduction.SigningIn} />
            <BasicElements id={Introduction.BasicElement} />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
