import { Fragment, useEffect, useRef } from 'react'
import { Link, Box } from '@mui/material'
import { Paragraph } from '@dis/components'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { GRID_WRAPPER_ID } from '@dis/constants'
import {
  COPYRIGHT,
  FIRST_PARAGRAPH,
  PRIVACY_LINK,
  PRIVACY_STATEMENT_LINK,
  SECOND_PARAGRAPH,
} from './constants'
import { styles } from './styles'

export const Footer = () => {
  const footerRef = useRef<HTMLDivElement>(null)

  const isRtl = useAppSelector(selectIsLanguageRtl)

  /**
   * The position:sticky has a poor support in browsers for right-to-left languages. That's why it's necessary
   * to use a JavaScript hack. In RTL language the footer is switched to position:relative and set counted css
   * prop "insetInlineStart".
   */
  useEffect(() => {
    if (isRtl) {
      const gridWrapper = document.getElementById(GRID_WRAPPER_ID)
      if (gridWrapper) {
        const listener = (event: Event) => {
          if (event.target instanceof HTMLElement && footerRef.current) {
            footerRef.current.style.insetInlineStart = `${Math.abs(
              event.target?.scrollLeft || 0,
            )}px`
          }
        }

        gridWrapper.addEventListener('scroll', listener)

        return () => {
          gridWrapper.removeEventListener('scroll', listener)
        }
      }
    } else {
      if (HTMLElement && footerRef.current) {
        footerRef.current.style.insetInlineStart = '0px'
      }
    }
  }, [isRtl])

  return (
    <Fragment>
      {/* It keeps the footer on the bottom position when a page doesn't render content */}
      <div />
      <Box
        sx={{
          position: isRtl ? 'relative' : 'sticky',
          px: {
            lg: 10,
            md: 2,
            sm: 1,
          },
        }}
        className={styles.wrapper}
        ref={footerRef}>
        <div data-testid="footer" className={styles.container}>
          <div className={styles.copyrightContainer}>
            <div dir="ltr">
              <Paragraph variant="tiny">{COPYRIGHT}</Paragraph>
              <Paragraph variant="tiny">
                {FIRST_PARAGRAPH}
                <Link target="_blank" className={styles.link} href={PRIVACY_LINK}>
                  here.
                </Link>
                {SECOND_PARAGRAPH}
                <Link target="_blank" className={styles.link} href={PRIVACY_STATEMENT_LINK}>
                  KPMG International Services Limited Privacy Statement.
                </Link>
              </Paragraph>
            </div>
          </div>
        </div>
      </Box>
    </Fragment>
  )
}
