import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

const containerXpadding = '5vw'

export const styles = {
  buttonsContainer: css`
    display: flex;
    gap: ${theme.spacing(5)};
  `,

  container: css`
    align-self: center;
    box-sizing: border-box;
    margin-top: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(10)};
    padding-inline-start: 5vw;
    padding-inline-end: 5vw;
    background-color: ${colors.background.white};
  `,
  normalWidth: css`
    max-width: calc(72.5rem + 2 * ${containerXpadding});
    width: 100%;
  `,
  secondaryBtn: css`
    gap: ${theme.spacing(3)};
  `,
}
