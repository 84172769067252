import React, { useCallback } from 'react'
import MuiTableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import { useTable } from '@dis/context/src/table/useTable'
import { styles } from './styles'
import { TableProps } from './types'
import { SortIcon } from './SortIcon'

type Props<RowType> = Pick<TableProps<RowType>, 'tableHeadClassName' | 'headCols'>

export const TableHead = <RowType,>({ headCols, tableHeadClassName }: Props<RowType>) => {
  const { orderDirection, orderBy, onRequestSort, allowDnd } = useTable()

  const onRequestSortWrapper = useCallback(
    (property: TableProps<RowType>['defaultOrderBy']) => () => {
      onRequestSort(property)
    },
    [onRequestSort],
  )

  return (
    <MuiTableHead className={tableHeadClassName}>
      <TableRow>
        {allowDnd && <TableCell />}

        {headCols?.map(({ label, orderBy: colOrderBy }, colIndex) => {
          const isLabelFunction = typeof label === 'function'
          const isLabelObject = typeof label === 'object'

          return (
            <TableCell
              className={styles.tableHead.tableCell}
              // TODO: check if it is possible to change the key to not to use index
              // eslint-disable-next-line react/no-array-index-key
              key={`thead_col_${colIndex}_${String(colOrderBy)}_${
                typeof label === 'string' ? label : ''
              }`}>
              <>
                {isLabelFunction && label({ colIndex })}
                {isLabelObject && label}
                <TableSortLabel
                  IconComponent={SortIcon}
                  disabled={!colOrderBy}
                  active={!!colOrderBy && orderBy === colOrderBy}
                  direction={orderBy === colOrderBy ? orderDirection : 'asc'}
                  className={styles.tableHead.sortLabel}
                  onClick={colOrderBy ? onRequestSortWrapper(colOrderBy) : undefined}>
                  <>
                    {!isLabelFunction && !isLabelObject && label}
                    {!!colOrderBy && orderBy === colOrderBy ? (
                      <Box component="span" sx={visuallyHidden}>
                        {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </>
                </TableSortLabel>
              </>
            </TableCell>
          )
        })}
      </TableRow>
    </MuiTableHead>
  )
}
