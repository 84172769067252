import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image84 from '@dis/assets/src/images/newTutorial/84.png'
import image85 from '@dis/assets/src/images/newTutorial/85.png'
import image86 from '@dis/assets/src/images/newTutorial/86.png'
import image87 from '@dis/assets/src/images/newTutorial/87.png'
import image88 from '@dis/assets/src/images/newTutorial/88.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const Atlas = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.2 Atlas
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The atlas represents a folder which help you and your teams keep journeys and folders
        organized and structured in one place.
      </Paragraph>
      <Header marginTop={5} variant="h3">
        1.2.1 Creating a new atlas
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To create an atlas, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Landing page"</strong>: click on the "Plus" button (Atlases section)
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image84} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"All atlases"</strong> screen (accessible from "Landing page" after clicking on
        "Show all" button in Atlases section): click on the "Add new atlas" button
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image85} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all the above scenarios, a modal window will appear in which you have to define the name
        of the atlas. Click the "Submit" button to confirm the name of the atlas.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image86} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.2 Editing an atlas
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Edit an atlas by clicking on the item's dropdown menu within "All atlases" screen and choose
        "Edit".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image87} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.3 Deleting an atlas
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Delete an atlas by clicking on the item's dropdown menu within "All atlases" screen and
        choose "Delete".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image88} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
