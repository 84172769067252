import { ErrorCode } from '@dis/constants'

type Options = {
  cause?: any
  errorCode?: string | number
  location?: string
  message: string
  stackTrace?: any
  title?: string
}

export class ExtendedError extends Error {
  public readonly errorCode: Options['errorCode']
  public readonly stackTrace: Options['stackTrace']
  public readonly location: Options['location']
  public readonly title: Options['title']

  constructor({
    errorCode = ErrorCode.GeneralError,
    location = '',
    message,
    stackTrace,
    cause,
    title,
  }: Options) {
    super(message, { cause })

    this.title = title
    this.message = message
    this.name = errorCode.toString()
    this.errorCode = errorCode
    this.stack = stackTrace
    this.stackTrace = stackTrace
    this.cause = cause
    this.location = location
  }
}
