import { css } from '@emotion/css'
import { colors, shadowClasses, theme } from '@dis/styles'
import { typography, typographyFontWeight } from '@dis/styles/src/theme'

export const styles = {
  ghost: css`
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.5;
  `,
  imageContainer: css`
    border-radius: 0.7rem;
    height: 60px;
    overflow: hidden;
    position: relative;
    width: 60px;
  `,
  noUnderline: css`
    .MuiInput-underline::before {
      border-bottom: none;
      content: none;
    }
  `,
  personaDescription: css`
    display: inline-block;
    max-width: 10rem;
    color: ${colors.gray.gray80};
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1rem;
    white-space: nowrap;
    font-size: ${typography.paragraph.small.fontSize};
  `,
  personaImage: css`
    border-radius: 1rem;
    overflow: hidden;
    width: 3.75rem;
    height: 3.75rem;
    position: relative;
  `,
  personaMainContainer: css`
    background-color: ${colors.transparent};
    border-radius: 0.5rem;
    max-width: 18.75rem;
    padding: ${theme.spacing(1.5)};
    height: 4.5rem;
    transition: all 0.5s;
    :hover {
      ${shadowClasses[7]}
      cursor: pointer;
    }
  `,
  personaTitle: css`
    min-height: 2.25rem;
    max-height: 2.8rem;
    text-overflow: ellipsis;
    font-weight: ${typographyFontWeight.semibold};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
  `,
  photo: css`
    object-fit: cover;
    height: 60px;
    width: 60px;
  `,
  placeholderDisappearAndHoverUnderline: css`
    input {
      :hover {
        text-decoration-line: underline;
      }
    }
    .Mui-focused {
      input {
        :hover {
          text-decoration: none;
        }
      }
      input::placeholder {
        color: ${colors.transparent};
      }
    }
  `,
  sameStyleForDisabled: css`
    input {
      -webkit-text-fill-color: initial !important;
    }
  `,
  textContainer: css`
    padding-inline-start: ${theme.spacing(5)};
  `,
}
