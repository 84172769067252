import { useCallback } from 'react'
import { TableCell, TableRow } from '@mui/material'
import { tKeys } from '@dis/languages'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { useTranslation } from 'react-i18next'
import { useNavTo } from '@dis/hooks'
import { JourneyPreview } from '@dis/types/src/JourneyTypes'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { Paragraph } from '@dis/components'
import { dateHandler } from '@dis/utils'
import { styles } from './styles'

type Props = {
  atlasId?: number
  folderId: JourneyPreview['folderId']
  journeyId: JourneyPreview['id']
  journeyName: JourneyPreview['name']
  updated: JourneyPreview['updated']
}

export const JourneysTableRow = ({ journeyId, atlasId, journeyName, updated, folderId }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()

  const name = journeyName || t(tKeys.common.newJourney)
  const isRtl = useAppSelector(selectIsLanguageRtl)

  const onClickJourneyPreview = useCallback(() => {
    dispatchedActions.journeys.setSelectedJourneyId(journeyId)
    dispatchedActions.journeys.setJourneyName(name)
    if (folderId) {
      navigate('journeyFolder', { atlasId, folderId, journeyId })
    } else {
      navigate('journey', { atlasId, journeyId })
    }
  }, [atlasId, folderId, journeyId, name, navigate])

  return (
    <TableRow onClick={onClickJourneyPreview} className={styles.root}>
      <TableCell className={styles.tableCellName}>
        <Paragraph>{name}</Paragraph>
      </TableCell>
      <TableCell className={styles.tableCellDate}>
        <Paragraph variant="small">{dateHandler(updated, isRtl)}</Paragraph>
      </TableCell>
    </TableRow>
  )
}
