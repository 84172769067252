import { useEffect } from 'react'
import { dispatchedActions } from '@dis/redux'

export const useCentralModalLoader = (show: boolean, text?: string) => {
  useEffect(() => {
    if (show) {
      const modal = dispatchedActions.centralModalLoader.showModalLoader({
        text,
      })

      return () => {
        dispatchedActions.centralModalLoader.hideModalLoader(modal)
      }
    }
  }, [show, text])
}
