import { Box } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import { cx } from '@emotion/css'
import { styles } from './styles'
import { TutorialPdfButton } from './TutorialPdfButton'

type TextAndButtonHeaderProps = {
  description?: string
  hasButton?: boolean
  header?: string
}
export const TextAndButtonHeader = ({
  header,
  description,
  hasButton: button = true,
}: TextAndButtonHeaderProps) => {
  return (
    <div className={styles.downloadButtonContainer}>
      <Box>
        {header && (
          <Header variant="h1" className={cx(styles.subtitleDescription, styles.gap)}>
            {header}
          </Header>
        )}
        {description && (
          <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
            {description}
          </Paragraph>
        )}
      </Box>
      {button && <TutorialPdfButton />}
    </div>
  )
}
