import { css } from '@emotion/css'
import { theme, typography, typographyFontWeight } from '@dis/styles'
import { BASIC_FONT_SIZE } from '@dis/constants'

/**
 * One list item height in REM units.
 * The list item pixel value is fetched manually in the developer console in the web browser.
 */
const listItemHeight = 33 / BASIC_FONT_SIZE

const dropdownMargin = 1

const countDropDownMaxHeight = (listItemCount?: number) =>
  listItemCount === undefined ? 'auto' : listItemCount * listItemHeight + dropdownMargin + 'rem'

// Used decimal point values and pixel paddings/margins to style the ghost as perfect as possible according to the original component
export const styles = {
  dropDownMaxHeight: (listItemCount?: number) => css`
    height: ${countDropDownMaxHeight(listItemCount)};
    max-height: 100%;
  `,
  dropdown: css`
    margin-top: ${theme.spacing(dropdownMargin)};

    .MuiButtonBase-root {
      font-size: ${typography.paragraph.body.fontSize};
    }
  `,
  formLabel: css`
    font-size: ${typography.paragraph.body.fontSize};
  `,
  ghost: {
    icon: css`
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      color: rgba(0, 0, 0, 0.54);
    `,
    root: css`
      box-sizing: border-box;
      white-space: nowrap;
      display: flex;
      letter-spacing: normal;
      line-height: ${typography.paragraph.medium.lineHeight};
      font-weight: ${typographyFontWeight.regular};
      cursor: pointer;
      position: relative;
      padding-inline-end: ${theme.spacing(6)};
      min-height: 1.4375em;
      padding-bottom: 1px;
    `,
    rtl: css`
      padding-right: unset;
      padding-left: ${theme.spacing(6)};
    `,
    rtlIcon: css`
      left: 0;
      right: unset;
    `,
    withLabel: css`
      margin-top: calc(1.4375em / 2);
    `,
  },
  itemRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  menuItem: {
    innerContainer: css`
      max-width: 100%;
    `,
    paragraph: css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;

      @media all and (max-width: 500px) {
        white-space: normal;
      }
    `,
    root: css`
      padding-left: 0;
      padding-right: 0;
      padding-inline-end: ${theme.spacing(2)};
      padding-inline-start: ${theme.spacing(2)};
      margin-inline-start: ${theme.spacing(2)};
      margin-inline-end: ${theme.spacing(2)};
      min-height: 3rem;
      max-width: 100%;
    `,
  },
  menuList: {
    invisiblePlaceholder: css`
      & > li.Mui-selected.Mui-disabled {
        display: none;
      }
    `,
    root: css`
      max-width: 100%;
    `,
  },
}
