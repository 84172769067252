import { colors, hex2rgba, theme } from '@dis/styles'
import { css } from '@emotion/css'

// TODO - use colors object
export const styles = {
  root: css`
    &:nth-of-type(odd) {
      background-color: ${colors.gray.gray5};
    }

    &:hover {
      background-color: ${hex2rgba(colors.text, 0.06)};
    }
  `,
  table: css`
    margin-top: ${theme.spacing(4)};
    cursor: pointer;
  `,
  tableCellDate: css`
    padding: ${theme.spacing(2)};
    padding-right: ${theme.spacing(7.5)};
    width: 40%;
    text-align: end;
    border: none;
  `,
  tableCellName: css`
    padding: ${theme.spacing(2)};
    padding-left: ${theme.spacing(7.5)};
    width: 60%;
    border: none;
  `,
  tableHeader: css`
    margin-top: ${theme.spacing(4)};
    cursor: pointer;
  `,
  tableHeaderDate: css`
    padding: ${theme.spacing(2)};
    padding-right: ${theme.spacing(7.5)};
    width: 40%;
    text-align: end;
  `,
  tableHeaderName: css`
    padding: ${theme.spacing(2)};
    padding-left: ${theme.spacing(7.5)};
    width: 60%;
  `,
}
