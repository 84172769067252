import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  container: css`
    .MuiOutlinedInput-root {
      border-radius: 100px;
      border: 1px solid ${colors.gray.gray10};

      &.Mui-focused fieldset {
        border: none;
        top: 0;
      }
    }

    legend {
      display: none;
    }

    .MuiInputBase-input {
      padding-inline-start: ${theme.spacing(2)};
      padding-inline-end: ${theme.spacing(4)};
      padding-top: ${theme.spacing(0)};
      padding-bottom: ${theme.spacing(0)};
    }

    color: ${colors.gray.gray40};

    svg {
      color: ${colors.gray.gray20};
    }
  `,
  containerLarge: css`
    .MuiOutlinedInput-root {
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
      padding-inline-start: ${theme.spacing(3.5)};
    }
  `,
  containerMedium: css`
    .MuiOutlinedInput-root {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
      padding-inline-start: ${theme.spacing(3.5)};
    }
  `,
  containerSmall: css`
    .MuiOutlinedInput-root {
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
      padding-inline-start: ${theme.spacing(3.5)};
    }
  `,
  resultsSearchBarContainer: css`
    .MuiOutlinedInput-root {
      border-radius: 100px;
    }

    legend {
      display: none;
    }

    .MuiInputBase-input {
      padding-inline-start: ${theme.spacing(2)};
      padding-inline-end: ${theme.spacing(4)};
      padding-top: ${theme.spacing(0)};
      padding-bottom: ${theme.spacing(0)};
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 100px;
      top: 0;
      border-color: ${colors.gray.gray10};
      color: ${colors.gray.gray10};
    }
    .MuiOutlinedInput-notchedOutline .Mui-focused {
      border-color: ${colors.gray.gray10};
      color: ${colors.gray.gray10};
    }
    .MuiInputBase-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.gray.gray10};
        color: ${colors.gray.gray10};
      }
    }

    color: ${colors.gray.gray40};
  `,
}
