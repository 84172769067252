import { css } from '@emotion/css'
import { theme } from '@dis/styles'

const actionsPanelColumnGap = 6 as const

export const styles = {
  actionsPanel: css`
    display: flex;
    row-gap: ${theme.spacing(2)};
    column-gap: ${theme.spacing(actionsPanelColumnGap)};
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  `,
  popoverFilterButton: css`
    margin-inline-end: ${theme.spacing(3 - actionsPanelColumnGap)};
  `,
}
