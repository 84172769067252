import graphql from 'babel-plugin-relay/macro'

export const GetUserActivityPresenceList = graphql`
  query QueriesGetUserActivityPresenceListQuery($input: UserActivityPresenceListQueryInput!) {
    userActivityPresenceList(input: $input) {
      items {
        metadata {
          currentSessionStart
          lastHeartbeat
          status
        }
        userInfo {
          email
          id
          name
        }
      }
    }
  }
`
