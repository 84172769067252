import { FC, ReactNode, useState } from 'react'
import { Card, CardActionArea, CardContent, Grid } from '@mui/material'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { cj, cx } from '@dis/styles'
import { PopoverWithAnchor } from '../PopoverWithAnchor/PopoverWithAnchor'
import { styles, Size, VerticalAlign, HorizontalAlign } from './styles'
import { MenuItem } from '../PopoverWithAnchor/types'

type Props = {
  cardActionClassName?: string
  children: ReactNode
  className?: string
  horizontalAlign?: HorizontalAlign
  menu?: Array<MenuItem>
  menuOnLeft?: boolean
  onClick?: VoidFunction
  size?: Size
  verticalAlign?: VerticalAlign
}

export const Tile: FC<Props> = ({
  children,
  horizontalAlign = 'center',
  menu,
  menuOnLeft,
  onClick,
  size = 'small',
  verticalAlign = 'top',
  cardActionClassName,
  className,
}) => {
  const [buttonAlwaysDisplayed, setButtonAlwaysDisplayed] = useState(false)

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const content = (
    <>
      {menu && (
        <div
          className={cj(
            styles.button.root,
            cx({
              [styles.button.alwaysVisible]: buttonAlwaysDisplayed,
              [styles.button.leftMenu]: isRtl,
              [styles.button.rightMenu]: !isRtl,
            }),
          )}>
          <PopoverWithAnchor
            items={menu}
            menuOnLeft={menuOnLeft}
            onVisibilityChanged={setButtonAlwaysDisplayed}
          />
        </div>
      )}
      {children}
    </>
  )

  return (
    <Grid item maxWidth="100%">
      <Card className={cx(styles.tile, styles.size(size), className)}>
        {!!onClick && (
          <CardActionArea
            className={cx(styles.card(verticalAlign, horizontalAlign), cardActionClassName)}
            onClick={onClick}>
            {content}
          </CardActionArea>
        )}
        {!onClick && (
          <CardContent className={styles.card(verticalAlign, horizontalAlign)}>
            {content}
          </CardContent>
        )}
      </Card>
    </Grid>
  )
}
