import { Header, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { styles } from '../styles'
import { ScrollProps } from '../types'

const msg = tKeys.tutorials.glossary

export const Glossary = ({ id }: ScrollProps) => {
  const { t } = useTranslation()
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        {t(msg.introductionGlossary)}
      </Header>
      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryTenant)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryTenantDescription)}
        </Paragraph>
      </div>
      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryAtlas)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryAtlasDescription)}
        </Paragraph>
      </div>

      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryFolder)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryFolderDescription)}
        </Paragraph>
      </div>

      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryJourney)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryJourneyDescription)}
        </Paragraph>
      </div>

      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryPhase)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryPhaseDescription)}
        </Paragraph>
      </div>

      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossarySubphase)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossarySubphaseDescription)}
        </Paragraph>
      </div>

      <div className={styles.headerAndText}>
        <Header variant="h4" pb={1}>
          {t(msg.introductionGlossaryTouchpoint)}
        </Header>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          {t(msg.introductionGlossaryTouchpointDescription)}
        </Paragraph>
      </div>
    </div>
  )
}
