import { css } from '@emotion/css'
import { theme } from '@dis/styles'

export const styles = {
  root: css`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: ${theme.spacing(4)};
  `,
}
