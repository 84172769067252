import { useEffect, useState } from 'react'
import { useDeviceSelectors } from 'react-device-detect'
import { dispatchedActions } from '@dis/redux'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'

export const useMobileDetector = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_resizeTimestamp, setResizeTimestamp] = useState(Date.now())

  const { t } = useTranslation()

  const [selectors] = useDeviceSelectors(window.navigator.userAgent)

  const { isMobile } = selectors

  useEffect(() => {
    dispatchedActions.general.setIsMobileDevice(isMobile)
  }, [isMobile])

  useEffect(() => {
    const listener: EventListenerOrEventListenerObject = () => {
      setResizeTimestamp(Date.now())
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      const modalId = dispatchedActions.centralModalDialog.showModalDialog({
        hasCrossClose: true,
        primaryButton: {
          btnText: t(tKeys.common.accept),
        },
        severity: 'warning',
        title: t(tKeys.alertModal.journeyOnMobileDevice),
      })

      return () => {
        dispatchedActions.centralModalDialog.hideModalDialog(modalId)
      }
    }
  }, [isMobile, t])
}
