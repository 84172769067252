import { RootState } from '../index'

export const selectCentralModalLoaderItems = (state: RootState) =>
  state.centralModalLoader.items || []

export const selectIsCentralModalLoaderVisible = (state: RootState) =>
  state.centralModalLoader.items.length > 0

export const selectHasCentralModalLoaderHighPriority = (state: RootState) =>
  !!state.centralModalLoader.items.find(({ overlayPriority }) => overlayPriority === 'high')
