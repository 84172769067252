import { css } from '@emotion/css'
import { theme } from './theme'
import { shadows } from './boxShadows'
import { colors } from './colors'

export const scrollbarWidth = '11px' as const

export const dropdownStyles = {
  container: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px solid white',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRadius: '0px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '0px solid white',
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        borderRadius: '0px',
      },
    },
    '& fieldset': { height: 20, top: 20 },
  },
  font: { fontSize: '0.8rem' },
  itemWidth: {
    style: {
      width: 140,
    },
  },
}

const threeDots = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const actionsContainer = css`
  opacity: 0;
  z-index: -1;
  background-image: linear-gradient(${colors.transparent} 15%, ${colors.gray.gray0} 55%);
  transition: opacity ease-in-out 0.2s, z-index ease-in-out 0s 0.2s;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: flex-end;
  padding: ${theme.spacing(6)};
  gap: ${theme.spacing(3)};
`

export const tileStyles = {
  body: css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
  `,
  createdContainer: css`
    opacity: 1;
    z-index: 1;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    gap: ${theme.spacing(2)};

    & > * {
      margin-top: ${theme.spacing(4)};
    }
  `,
  descContainer: css`
    flex: 1 1 auto;
    padding-top: ${theme.spacing(2)};
    position: relative;
  `,
  descTooltipContainer: css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  `,
  textAndImage: css`
    flex: 0 0 auto;
    padding-bottom: ${theme.spacing(1)};
    padding-inline-end: ${theme.spacing(5)};

    display: flex;
    flex-direction: row;
  `,

  threeDotsHeader: (isChannelManagement: boolean) => css`
    white-space: nowrap;
    margin-bottom: ${isChannelManagement ? theme.spacing(2) : 0};
    ${threeDots};
    width: 100%;
    margin-inline-start: ${isChannelManagement ? theme.spacing(2) : 0};
  `,

  threeDotsText: css`
    color: ${colors.gray.gray60};
    display: flex;
    flex-direction: row;
    -webkit-line-clamp: 3;
    ${threeDots};
  `,
  tile: (isTemplateTenant: boolean | undefined) => css`
    height: ${isTemplateTenant ? 10.25 : 13}rem;
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: ${shadows[16]};
    cursor: pointer;

    &:hover {
      ${'.' + actionsContainer} {
        opacity: 1;
        z-index: 1;
        transition: z-index ease-in-out 0s 0s, opacity ease-in-out 0.2s 0s;
      }
    }
  `,

  topContainer: css`
    flex: 0 0 auto;
    padding-bottom: ${theme.spacing(1)};
  `,
}
