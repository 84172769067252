import { Paragraph } from '@dis/components'
import { colors } from '@dis/styles'

type TitleContainerProps = {
  title: string
}

const TitleContainer = ({ title }: TitleContainerProps) => {
  return (
    <Paragraph
      variant="large"
      borderBottom={1}
      borderColor={colors.gray.gray0}
      marginTop={7}
      marginBottom={5}
      paddingY={2}>
      {title}
    </Paragraph>
  )
}

export default TitleContainer
