import { Paragraph } from '@dis/components'
import { colors } from '@dis/styles/src/colors'
import { t, tKeys } from '@dis/languages'

export const CreateDuplicateJourneyTooltip = () => {
  return (
    <>
      <Paragraph fontWeight="bold" color={colors.text}>
        {t(tKeys.journeys.duplicateJourneyModal.title)}
      </Paragraph>
      <Paragraph variant="small" marginTop={1} color={colors.gray.gray80}>
        {t(tKeys.journeys.duplicateJourneyModal.text)}
      </Paragraph>
    </>
  )
}
