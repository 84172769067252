import { useCallback, useRef, useState } from 'react'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'
import { WsEnhancedResponseMessage, WsRevertMessage } from '@dis/types/src/wsModels'
import { Api } from '@dis/api'

type UseRevertProps = {
  onData?: (data: PersonaDetailType) => void
}

export const useRevert = (props?: UseRevertProps) => {
  const [data, setData] = useState<PersonaDetailType>()
  const [loading, setLoading] = useState(true)

  const onData = props?.onData
  const send =
    useRef<(message: WsRevertMessage, callback?: (data?: PersonaDetailType) => void) => void>()

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage, callback?: (data?: PersonaDetailType) => void) => {
      setData(newData.data)
      setLoading(false)
      onData?.(newData.data)
      callback?.(newData.data)
    },
    [onData],
  )

  const sendWrapper = useCallback(
    (message: WsRevertMessage, callback?: (data?: PersonaDetailType) => void) => {
      Api.sendRevert(message, (newData: WsEnhancedResponseMessage) => {
        onMessage(newData, callback)
      })
    },
    [onMessage],
  )

  send.current = sendWrapper

  return {
    data,
    loading,
    send: send.current,
  }
}
