import { Link } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { styles } from './styles'

type NavigationBarProps = {
  description: string
  link: string
  linkText: string
  title: string
}
export const NavigationBar = ({ title, description, linkText, link }: NavigationBarProps) => {
  const isRtl = useAppSelector(selectIsLanguageRtl)

  return (
    <div className={styles.nextSteps}>
      <Header variant="h3">{title}</Header>
      <Paragraph variant="medium">
        {!isRtl && description}{' '}
        <Link target="_blank" className={styles.link} href={link}>
          {linkText}
        </Link>
        {isRtl && description}
      </Paragraph>
    </div>
  )
}
