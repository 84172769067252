import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import PlusIcon from '@dis/assets/src/icons/PlusIcon.svg'
import { cx } from '@dis/styles'
import { SvgImage } from '../SvgImage/SvgImage'
import { styles } from './style'

type Icon = 'add'

type Props = ButtonProps & {
  iconColor?: string
  iconVariant: Icon
}

const iconMap: Record<Icon, string> = { add: PlusIcon }

export const IconButton: FC<Props> = ({ className, iconColor, iconVariant, ...props }) => (
  <Button className={cx(className, styles.button)} {...props}>
    <SvgImage src={iconMap[iconVariant]} color={iconColor} />
  </Button>
)
