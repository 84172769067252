import { useCallback, useEffect, useRef, useState } from 'react'
import {
  CacheConfig,
  fetchQuery,
  commitMutation,
  FetchQueryFetchPolicy,
  GraphQLTaggedNode,
  OperationType,
  GraphQLResponseWithoutData,
} from 'relay-runtime'
import { reactRelayEnvironment } from '@dis/api/src/graphqlClient/relayClient'
import { MutationParameters } from 'relay-runtime/lib/mutations/commitMutation'

type UseRelayQuery<D extends OperationType> = {
  cacheConfig?: {
    fetchPolicy?: FetchQueryFetchPolicy
    networkCacheConfig?: CacheConfig
  }
  onData?: (data: D['response']) => void
  onError?: (error: any) => void
  polling?: number
  skip?: boolean
  taggedNode: GraphQLTaggedNode
  variables: D['variables']
}

export const useRelayQuery = <D extends OperationType>({
  taggedNode,
  variables,
  cacheConfig,
  onData,
  onError,
  polling,
  skip,
}: UseRelayQuery<D>) => {
  const [timestamp, setTimestamp] = useState<number>(Date.now())
  const [error, setError] = useState<GraphQLResponseWithoutData>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<D['response']>()

  const onDataRef = useRef(onData)
  onDataRef.current = onData

  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const { networkCacheConfig, fetchPolicy } = cacheConfig || {}

  // This stringified variables avoid some re-renders on the following useEffect placed below
  const stringifiedVariables = variables ? JSON.stringify(variables) : ''

  useEffect(() => {
    if (!skip && timestamp) {
      setLoading(true)
      setError(undefined)

      fetchQuery<D>(
        reactRelayEnvironment,
        taggedNode,
        stringifiedVariables ? JSON.parse(stringifiedVariables) : {},
        {
          fetchPolicy,
          networkCacheConfig,
        },
      ).subscribe({
        error: (error: Error | GraphQLResponseWithoutData) => {
          console.error('useRelayQuery', error)

          let enhancedError

          if (
            error &&
            'errors' in error &&
            Array.isArray(error?.errors) &&
            'message' in error.errors[0]
          ) {
            enhancedError = error
          } else {
            enhancedError = {
              errors: [
                {
                  message: error.toString(),
                },
              ],
            }
          }

          setData(undefined)

          setError(enhancedError)

          setLoading(false)

          onErrorRef.current?.(enhancedError)
        },
        next: (value) => {
          setError(undefined)
          setData(value)
          onDataRef.current?.(value)
          setLoading(false)
        },
        start: () => {
          setLoading(true)
          setError(undefined)
        },
      })
    }
  }, [skip, taggedNode, stringifiedVariables, networkCacheConfig, fetchPolicy, timestamp])

  useEffect(() => {
    if (polling && polling > 0) {
      const intervalRef = setInterval(() => {
        setTimestamp(Date.now())
      }, polling)

      return () => {
        clearInterval(intervalRef)
      }
    }
  }, [polling])

  return {
    data,
    error,
    loading,
  }
}

type UseRelayMutation<D extends MutationParameters> = {
  onData?: (data: D['response']) => void
  onError?: (error: GraphQLResponseWithoutData) => void
  taggedNode: GraphQLTaggedNode
}

export const useRelayMutation = <D extends MutationParameters>({
  taggedNode,
  onData,
  onError,
}: UseRelayMutation<D>) => {
  const [error, setError] = useState<GraphQLResponseWithoutData>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<D['response']>()

  const onDataRef = useRef(onData)
  onDataRef.current = onData

  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const send = useCallback(
    ({ skip, variables }: { skip?: boolean; variables: D['variables'] }) => {
      if (!skip) {
        setLoading(true)
        setError(undefined)

        commitMutation<D>(reactRelayEnvironment, {
          mutation: taggedNode,
          onCompleted: (response, errors) => {
            if (errors) {
              const enhancedError = {
                errors: [...errors],
              }

              setError(enhancedError)

              console.error('useRelayMutation: ', errors)

              setLoading(false)

              onErrorRef.current?.(enhancedError)
            } else {
              setData(response)

              setLoading(false)

              onDataRef.current?.(response)
            }
          },
          onError: (err: Error | GraphQLResponseWithoutData) => {
            console.error('useRelayMutation: ', err)

            let enhancedError

            if (
              err &&
              'errors' in err &&
              Array.isArray(err?.errors) &&
              'message' in err.errors[0]
            ) {
              enhancedError = err
            } else {
              enhancedError = {
                errors: [
                  {
                    message: err.toString(),
                  },
                ],
              }
            }

            setData(undefined)

            setError(enhancedError)

            setLoading(false)

            onErrorRef.current?.(enhancedError)
          },

          variables,
        })
      }
    },
    [taggedNode],
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [])

  return {
    clearError,
    data,
    error,
    loading,
    send,
  }
}
