import { css } from '@emotion/css'

export const styles = {
  root: css`
    position: relative;
    cursor: pointer;

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
    }
  `,
}
