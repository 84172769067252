import { RouteObject } from 'react-router/dist/lib/context'
import { matchRoutes, generatePath } from 'react-router-dom'
import { tKeys, t } from '@dis/languages'
import { RouteNameObject, RouteFullPaths, RouteNames, RouteVariables } from './types'

const undefPlaceholder = 'UNDEFINED' as const

export const routeVariables = {
  atlasId: ':atlasId',
  folderId: ':folderId',
  journeyId: ':journeyId',
  personaId: ':personaId',
  templateId: ':templateId',
  tenantId: ':tenantId',
} as const

export const routeNames: Record<keyof typeof tKeys.routes, { path: string }> = {
  administration: { path: 'administration' },
  archivedJourneys: { path: 'archived-journeys' },
  archivedTenants: { path: 'archived-tenants' },
  atlas: { path: routeVariables.atlasId },
  atlases: { path: 'atlases' },
  backlog: { path: 'backlog' },
  capabilities: { path: 'capabilities' },
  capabilityManagement: { path: 'capability-management' },
  channels: { path: 'channels' },
  editTenant: { path: 'edit-tenant' },
  folder: { path: routeVariables.folderId },
  folders: { path: 'folders' },
  globalAdministration: { path: 'global-administration' },
  globalCapabilityManagement: { path: 'global-capability-management' },
  globalTemplate: { path: routeVariables.templateId },
  globalTemplateManagement: { path: 'global-template-management' },
  globalTemplatePreview: { path: 'global-template-preview' },
  globalUserManagement: { path: 'global-user-management' },
  groups: { path: 'groups' },
  journey: { path: routeVariables.journeyId },
  journeyFolder: { path: routeVariables.journeyId },
  journeys: { path: 'journeys' },
  limits: { path: 'limits' },
  listOfJourneys: { path: 'journeys-list' },
  localTemplate: { path: routeVariables.templateId },
  localTemplatePreview: { path: 'local-template-preview' },
  login: { path: 'login' },
  newJourney: { path: 'new-journey' },
  newJourneyAtlas: { path: routeVariables.atlasId },
  newJourneyFolder: { path: 'new-journey' },
  persona: { path: routeVariables.personaId },
  personas: { path: 'personas' },
  recycleBinJourney: { path: routeVariables.journeyId },
  search: { path: 'search' },
  templateManagement: { path: 'template-management' },
  tenant: { path: routeVariables.tenantId },
  tenants: { path: 'tenants' },
  test: { path: 'test' },
  tutorial: { path: 'tutorial' },
  tutorialExportImport: { path: 'tutorial-exportImport' },
  tutorialIntroduction: { path: 'tutorial-introduction' },
  tutorialJourneys: { path: 'tutorial-journeys' },
  tutorialNavigation: { path: 'tutorial-navigation' },
  tutorialPersonas: { path: 'tutorial-personas' },
  tutorialSettings: { path: 'tutorial-settings' },
  userManagement: { path: 'user-management' },
  userStory: { path: 'user-story' },
}

let routes: RouteObject[] = []

export const generateRouteObjects = (): Record<keyof typeof routeNames, RouteNameObject> => {
  const paths: Record<string, RouteNameObject> = {}

  Object.entries(routeNames).forEach(([routeName, { path }]) => {
    paths[routeName as string] = {
      name: routeName,
      path,
      get title() {
        return t(tKeys.routes[routeName as keyof typeof tKeys.routes])
      },
    }
  })

  return paths
}

export const setRoutes = (newRoutes: RouteObject[]) => {
  routes = newRoutes

  generateRouteFullPaths()
}

export let routeFullPaths: RouteFullPaths | undefined

const generateRouteFullPaths = () => {
  const routePaths: Record<string, string[]> = {}

  const loop = (objects: RouteObject[], path: string[]) => {
    objects.forEach((object) => {
      const newPath = [...path]

      if (object.path) {
        newPath.push(object.path)
      }

      if (object.handle?.name) {
        if (!Array.isArray(routePaths[object.handle.name])) {
          routePaths[object.handle.name] = []
        }

        routePaths[object.handle.name].push(newPath.join('/').replace(/\/+/g, '/'))
      }

      if (object.children?.length) {
        loop(object.children, newPath)
      }
    })
  }

  loop(routes, ['/'])

  routeFullPaths = routePaths as RouteFullPaths
}

/**
 * Special logic implemented because some screens can have multiple routes/URLs.
 */
export const getRouteUrl = (
  routeKey: RouteNames,
  variables: RouteVariables = {
    atlasId: '',
    folderId: '',
    journeyId: '',
    personaId: '',
    templateId: '',
    tenantId: '',
  },
) => {
  const matches = matchRoutes(routes, window.location.pathname)

  const filteredVariables: Record<string, string | number> = {}

  Object.entries(variables).forEach(([key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      filteredVariables[key] = value
    }
  })

  if (matches && routeFullPaths) {
    // Keep order of the objects mixing!
    const mixedVariables = {
      ...(matches.pop()?.params || {}),
      ...filteredVariables,
    }

    let fullPath = ''

    const pathname = matches[matches.length - 1].pathname || ''

    // Fill empty variables with a placeholder string to avoid an exception in generatePath
    const fillMixedVariables = { ...mixedVariables }
    Object.keys(routeVariables).forEach((key) => {
      if (fillMixedVariables[key] === undefined) {
        fillMixedVariables[key] = undefPlaceholder
      }
    })

    const generatedPaths: Array<{ path: string; size: number }> = []
    routeFullPaths[routeKey].forEach((route) => {
      const generatedPath = generatePath(route, fillMixedVariables)
      if (!generatedPath.includes(undefPlaceholder)) {
        generatedPaths.push({
          path: generatedPath,
          size: generatedPath.split('/').length,
        })
      }
    })

    // Sort paths from the shortest
    generatedPaths.sort((a, b) => a.size - b.size)

    generatedPaths.find((gmp) => {
      if (gmp.path.startsWith(pathname) && gmp.path != pathname) {
        fullPath = gmp.path
        return true
      }
    })

    // Find a path shorter than actual path
    if (!fullPath) {
      const matches = pathname.split('/')
      for (let i = matches.length; i >= 0; i--) {
        const testPath = matches.slice(0, i).join('/')

        generatedPaths.find((gmp) => {
          if (gmp.path.startsWith(testPath)) {
            fullPath = gmp.path
            return true
          }
        })

        if (fullPath) {
          break
        }
      }
    }

    if (!fullPath) {
      throw new Error(`getRouteUrl cannot find any path for the route key ${routeKey}`)
    }

    return fullPath
  }

  return ''
}

export const getRouteParams = () => {
  const ret: Record<keyof typeof routeVariables, number> = {
    atlasId: 0,
    folderId: 0,
    journeyId: 0,
    personaId: 0,
    templateId: 0,
    tenantId: 0,
  }

  const matches = matchRoutes(routes, window.location.pathname)

  if (matches && routeFullPaths) {
    Object.entries(matches.pop()?.params || {}).forEach(([key, value]) => {
      ret[key as keyof typeof ret] = value ? Number(value) : 0
    })
  }

  return ret
}
