import { colors, shadows, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  buttonWrapper: css`
    display: inline-flex;
    gap: ${theme.spacing(2)};
  `,
  buttonsContainer: css`
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
    gap: ${theme.spacing(4)};
  `,
  landingInnerContainerLayout: css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacing(5)};
    background-color: ${colors.background.white};
    box-shadow: ${shadows[14]};
    border-radius: 8px;
  `,
  loadingAnimationContainer: css`
    justify-content: center;
    padding: ${theme.spacing(8)} 0 ${theme.spacing(8)} ${theme.spacing(4)};
  `,
  title: css`
    margin: ${theme.spacing(0)};
    letter-spacing: 0.2rem;
    text-transform: uppercase;
  `,
}
