import React, { useRef } from 'react'
import MaterialTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import { cx } from '@emotion/css'
import { TableProvider } from './TableProvider'
import { Pagination } from './Pagination'
import { TableProps } from './types'
import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { styles } from './styles'

export const Table = <RowType,>({
  children,
  containerClassName,
  isNewDesign,
  tableClassName,
  tableBodyClassName,
  tableHeadClassName,
  paginationClassName,
  rowsPerPage,
  headCols,
  bodyCols,
  fullWidth,
  orderLikeNumber = [],
  idExtractor,
  stickyHeader = false,
  ...rest
}: TableProps<RowType>) => {
  // ---
  // Transform mutable array to immutable to avoid re-rendering
  const orderLikeNumberWrapper = useRef(orderLikeNumber)
  const idExtractorWrapper = useRef(idExtractor)
  // ---

  return (
    <TableContainer className={containerClassName}>
      <TableProvider
        {...rest}
        rowsPerPage={rowsPerPage}
        orderLikeNumber={orderLikeNumberWrapper.current}
        idExtractor={idExtractorWrapper.current}>
        <MaterialTable
          stickyHeader={stickyHeader}
          className={cx(
            styles.table.root,
            {
              [styles.newDesign]: isNewDesign,
              [styles.table.fullWidth]: fullWidth,
            },
            tableClassName,
          )}>
          {children ?? (
            <>
              <TableHead<RowType> tableHeadClassName={tableHeadClassName} headCols={headCols} />
              <TableBody<RowType> tableBodyClassName={tableBodyClassName} bodyCols={bodyCols} />
            </>
          )}
        </MaterialTable>
        {!children && <Pagination<RowType> paginationClassName={paginationClassName} />}
      </TableProvider>
    </TableContainer>
  )
}
