import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image20 from '@dis/assets/src/images/newTutorial/20.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const BacklogManagement = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.3 Backlog management
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        Within the given screen, you are allowed to manage items for the "Backlog" layer. The screen
        contains an overview of existing items, filtering, sorting and the ability to add new
        backlog items.
      </Paragraph>
      <div>
        <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
          Backlog item can have bellow mentioned status:
        </Paragraph>
        <List
          sx={{
            listStylePosition: 'inside',
            listStyleType: 'disc',
          }}>
          <ListItem sx={{ display: 'list-item' }}>New</ListItem>
          <ListItem sx={{ display: 'list-item' }}>In progress</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Done</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Terminated</ListItem>
        </List>
      </div>
      <div>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          And bellow mentioned priority:
        </Paragraph>
        <List
          sx={{
            listStylePosition: 'inside',
            listStyleType: 'disc',
          }}>
          <ListItem sx={{ display: 'list-item' }}>Low</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Medium</ListItem>
          <ListItem sx={{ display: 'list-item' }}>High</ListItem>
        </List>
      </div>
      <Paragraph
        marginTop={5}
        marginBottom={8}
        variant="medium"
        className={styles.subtitleDescription}>
        Each state and priority is assigned its own distinguishing color.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Filtration</strong>
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Filtering is possible above the "Priority" and "Status" columns. If no item is selected, the
        filter is inactive. By default, items in the "Terminated" state are not displayed.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Sorting</strong>
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Sorting is possible above "ID", "Workload estimation", "Priority" and "Status" columns
        (ascending/descending order). By default, the list of backlog items is sorted by "ID" column
        in ascending order.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Add a new backlog item</strong>
      </Paragraph>
      <div>
        <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
          You can add a new backlog item using the Plus" button in the upper right corner.
          Subsequently, a modal window will appear in which you must fill in the following fields:
        </Paragraph>
        <List
          sx={{
            listStylePosition: 'inside',
            listStyleType: 'disc',
          }}>
          <ListItem sx={{ display: 'list-item' }}>Name (mandotory field)</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Description (mandotory field)</ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Acceptance criteria (can only contain a numerical value)
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Workload estimation (can only contain a numerical value)
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Priority (predefined values; "Medium" by default)
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Status (predefined values; "New" by default)
          </ListItem>
        </List>
      </div>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image20} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can also add a new backlog item directly from a layer in the journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Edit your backlog
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Click in the appropriate field in the table cell to change the existing value. Saving takes
        place immediately after leaving the field. The given change is reflected for all users and
        in all journeys containing the "Backlog" layer.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Delete a backlog
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To delete an item, use the "Red cross" icon at the end of the line of the item, which
        appears when you hover over the line. If a backlog item is used in the journey, a
        confirmation dialog with this information is displayed. Otherwise, the item is deleted
        immediately without warning.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The deleted backlog item is automatically removed from all journeys where it was used. Other
        users are not informed about this.
      </Paragraph>
    </div>
  )
}
