import { css } from '@emotion/css'
import { colors, theme, typography, typographyFontWeight } from '@dis/styles'

export const styles = {
  createItem: {
    createButton: css`
      border: 0 !important;
      background: ${colors.transparent} !important;
    `,
    icon: css`
      width: 1.5rem;
      height: 1.5rem;
      margin-inline-end: ${theme.spacing(2)};
    `,
    input: css`
      color: ${colors.gray.gray100};
      text-decoration: none !important;
      font-style: normal;
      font-weight: ${typographyFontWeight.regular};
      line-height: ${typography.paragraph.body.lineHeight};
      ::placeholder {
        color: ${colors.kpmg.pacificBlue};
        font-style: normal;
        font-weight: ${typographyFontWeight.bold};
        line-height: ${typography.paragraph.body.lineHeight};
      }
      :after {
        border-color: ${colors.transparent};
      }
    `,
    selectItemContainer: css`
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 2.5rem;
      width: 100%;

      & > * {
        margin-top: ${theme.spacing(2)};

        &:nth-child(1) {
          flex: 1 0 auto;
        }

        &:nth-child(2) {
          flex: 0 0 auto;
        }
      }
    `,
    selectItemLine: css`
      background-color: ${colors.gray.gray20};
      height: 1px;
      margin-top: ${theme.spacing(2)};
      width: 100%;
    `,
  },
  selectMenu: css`
    height: 14rem;
    min-height: 14rem;
  `,
}
