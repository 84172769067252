import { useEffect, useMemo } from 'react'
import { Modal } from '@dis/components'
import { Input, useForm, yup } from '@dis/form'
import { tKeys } from '@dis/languages'
import { MAX_ATLAS_FOLDER_VISIBLE_LENGTH } from '@dis/modules/src/atlas-folder-journey/utils'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId, selectTenantLimits } from '@dis/redux/src/tenants/tenantsSelectors'
import { NewAtlasForm } from '@dis/types/src/forms'
import { useTranslation } from 'react-i18next'
import { useCreateAtlas } from '@dis/hooks/src/useCreate'
import { useChangeAtlasDetail } from '@dis/hooks/src/useChange'
import { useNavTo } from '@dis/hooks'

interface Props {
  atlasId?: number
  atlasName?: string
  isOpen: boolean
  mode: 'create' | 'edit'
  onClose: VoidFunction
}

export const CreateOrEditAtlasModal = ({ isOpen, onClose, mode, atlasId, atlasName }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const tenantLimits = useAppSelector(selectTenantLimits)
  const tenantId = useAppSelector(selectSelectedTenantId)
  const maxNameLength = tenantLimits?.maxlen?.others?.atlasname ?? MAX_ATLAS_FOLDER_VISIBLE_LENGTH
  const { sendCreate, createData } = useCreateAtlas()
  const { send: sendChangeAtlasDetail } = useChangeAtlasDetail()

  const validationSchema: yup.SchemaOf<NewAtlasForm> = useMemo(() => {
    return yup.object().shape({
      id: yup.number().optional(),
      name: yup.string().max(maxNameLength).required(),
    })
  }, [maxNameLength])

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<NewAtlasForm>({ mode: 'onSubmit', reValidateMode: 'onChange', validationSchema })

  const onSubmitForm = handleSubmit((formData) => {
    if (mode === 'create') {
      sendCreate(formData.name)
      onClose()
    } else if (atlasId) {
      sendChangeAtlasDetail({ data: { id: atlasId, name: formData.name } })
      onClose()
    }
  })

  useEffect(() => {
    if (isOpen) {
      resetForm({
        name: atlasName || '',
      })
    }
  }, [isOpen, resetForm, atlasName])

  useEffect(() => {
    if (createData) {
      navigate('atlas', { atlasId: createData?.id })
    }
  }, [createData, navigate, tenantId])

  return (
    <Modal
      onCrossBtnClick={onClose}
      isOpen={isOpen}
      title={mode === 'create' ? t(tKeys.atlasesPreview.title) : t(tKeys.atlases.editAtlas)}
      onPrimaryBtnClick={onSubmitForm}
      primaryBtnText={mode === 'create' ? t(tKeys.common.submit) : t(tKeys.atlases.complete)}
      height="auto">
      <Input
        required
        name="name"
        fixedLabel
        variant="outlined"
        hoverUnderline={false}
        label={t(tKeys.common.name)}
        formControl={control}
        placeholder={t(tKeys.atlasesPreview.placeholder)}
        maxLength={maxNameLength}
        isLimitVisible
        showLimitOnTop
      />
    </Modal>
  )
}
