import { FC } from 'react'
import {
  FormGroup,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio as RadioComponent,
  RadioProps as RadioComponentProps,
  FormLabel,
  TypographyProps,
  FormGroupProps,
} from '@mui/material'
import { TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import { ErrorNotice, Props as ErrorNoticeProps } from '../ErrorNotice/ErrorNotice'

export type RadioProps = Omit<RadioGroupProps, 'name' | 'color'> & {
  color?: RadioComponentProps['color']
  containerSx?: FormGroupProps['sx']
  error?: ErrorNoticeProps['error']
  fontSize?: TypographyKeys
  inlineLabelSx?: TypographyProps['sx']
  label?: string
  options: Array<{ disabled?: boolean; label: string; value: string | number | boolean }>
  shouldUnregister?: boolean
}

export const Radio: FC<RadioProps> = ({
  containerSx,
  options,
  label,
  color = 'primary',
  fontSize = 'paragraph.medium',
  inlineLabelSx = {},
  error,
  value,
  ...restProps
}) => {
  return (
    <FormGroup sx={containerSx}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <RadioGroup {...restProps} value={value ?? ''}>
        {options?.map((item) => (
          <FormControlLabel
            disabled={item.disabled}
            key={item.label}
            control={<RadioComponent color={color} />}
            componentsProps={{
              typography: {
                sx: { fontSize: getTypographyProp(fontSize, 'fontSize'), ...inlineLabelSx },
              },
            }}
            value={item.value}
            label={item.label}
            color={color}
          />
        ))}
      </RadioGroup>
      <ErrorNotice error={error} />
    </FormGroup>
  )
}
