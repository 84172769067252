import { ReactNode, FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@dis/assets/src/icons/Close.svg'
import { colors, cx } from '@dis/styles'
import { DISALLOW_DND } from '@dis/constants'
import InfoIcon from '@dis/assets/src/icons/InfoIcon.svg'
import { Header } from '../Typography/Header'
import { styles } from './ModalStyles'
import { SvgImage } from '../SvgImage/SvgImage'

type ModalHeaderProps = {
  hasCrossClose?: boolean
  headerContent?: ReactNode
  onCrossBtnClick: VoidFunction
  title?: string | ReactNode
  tooltip?: ReactNode
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  hasCrossClose,
  headerContent,
  onCrossBtnClick,
  title,
  tooltip,
}) => {
  return (
    <div className={cx(DISALLOW_DND)} id="components-modal-header">
      <div className={styles.modalHeader}>
        <Header variant="h2">{title}</Header>
        {tooltip && (
          <Tooltip
            classes={{
              tooltip: styles.tooltip.messageBox,
            }}
            arrow
            title={tooltip}>
            <div>
              <SvgImage color={colors.gray.gray40} src={InfoIcon} />
            </div>
          </Tooltip>
        )}
        {hasCrossClose && (
          <IconButton className={styles.crossButton} onClick={onCrossBtnClick}>
            <SvgImage color={colors.gray.gray40} src={CloseIcon} />
          </IconButton>
        )}
      </div>
      {headerContent}
    </div>
  )
}
