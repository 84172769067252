import { ReactNode } from 'react'
import WarningIcon from '@dis/assets/src/icons/WarningIcon.svg'
import NoticeIcon from '@dis/assets/src/icons/NoticeIcon.svg'
import ErrorIcon from '@dis/assets/src/icons/ErrorIcon.svg'
import SuccessIcon from '@dis/assets/src/icons/SuccessIcon.svg'
import { NotificationModalProps, Severity } from '@dis/types/src/NotificationModalDialogTypes'
import { Paragraph } from '@dis/components'
import { colors } from '@dis/styles'
import { styles } from './NotificationModalStyles'
import { SvgImage } from '../SvgImage/SvgImage'

type NotificationModalMainContentProps = Pick<NotificationModalProps, 'title' | 'severity'>

const iconSize = '60px' as const

const severityIcon: Record<Severity, ReactNode> = {
  error: <SvgImage src={ErrorIcon} width={iconSize} height={iconSize} />,
  normal: (
    <SvgImage color={colors.semantic.red} src={WarningIcon} width={iconSize} height={iconSize} />
  ),
  notice: <SvgImage src={NoticeIcon} width={iconSize} height={iconSize} />,
  success: <SvgImage src={SuccessIcon} width={iconSize} height={iconSize} />,
  warning: (
    <SvgImage color={colors.semantic.red} src={WarningIcon} width={iconSize} height={iconSize} />
  ),
}

export const NotificationModalMainContent = ({
  title,
  severity,
}: NotificationModalMainContentProps) => {
  return (
    <>
      {severity && <div className={styles.iconContainer}>{severityIcon[severity]}</div>}
      <Paragraph fontWeight="bold" variant="large">
        {title}
      </Paragraph>
    </>
  )
}
