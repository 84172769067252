import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGES } from '@dis/constants'
import { setYupLocale } from './yupLocale'
import { generateDictionary } from './utils'

import { translations } from './translations/translations'

const { tKeys, dictionary } = generateDictionary<typeof translations>(translations)

i18next.use(initReactI18next).init({
  fallbackLng: LANGUAGES.en,
  interpolation: {
    escapeValue: false,
  },
  resources: dictionary,
})

setYupLocale()

const t = i18next.t

export { tKeys, i18next, t }
