import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'
import { CentralModalLoaderState, HideCentralModalPayload } from './centralModalLoaderTypes'

const initialState: CentralModalLoaderState = {
  items: [],
}

export const centralModalLoaderSlice = createSlice({
  initialState,
  name: 'centralModalLoader',
  reducers: {
    forceHideModalLoader: (state) => {
      state.items.length = 0
    },
    hideModalLoader: (state, { payload }: PayloadAction<HideCentralModalPayload>) => {
      if (payload) {
        const id = typeof payload === 'string' ? payload : payload.payload.id

        if (id) {
          state.items = state.items.filter((item) => id !== item.id)
        }

        return state
      }
    },
    showModalLoader: {
      prepare: (props?: {
        overlayPriority?: CentralModalLoaderState['items'][number]['overlayPriority']
        ownId?: CentralModalLoaderState['items'][number]['id']
        text?: CentralModalLoaderState['items'][number]['text']
      }) => {
        const { text = '', ownId, overlayPriority } = props || {}

        const id = ownId || nanoid()
        return { payload: { id, overlayPriority: overlayPriority || 'normal', text: text || '' } }
      },
      reducer: (state, { payload }: PayloadAction<CentralModalLoaderState['items'][number]>) => {
        if (payload.id) {
          state.items = state.items.filter(({ id }) => id !== payload.id)

          state.items.push({
            id: payload.id,
            overlayPriority: payload.overlayPriority || 'normal',
            text: payload.text,
          })
        }
      },
    },
  },
})
