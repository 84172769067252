import { Header, Paragraph } from '@dis/components'
import image70 from '@dis/assets/src/images/newTutorial/70.png'
import image71 from '@dis/assets/src/images/newTutorial/71.png'
import image72 from '@dis/assets/src/images/newTutorial/72.png'
import image73 from '@dis/assets/src/images/newTutorial/73.png'
import image74 from '@dis/assets/src/images/newTutorial/74.png'
import image75 from '@dis/assets/src/images/newTutorial/75.png'
import image76 from '@dis/assets/src/images/newTutorial/76.png'
import image77 from '@dis/assets/src/images/newTutorial/77.png'
import image78 from '@dis/assets/src/images/newTutorial/78.png'
import image79 from '@dis/assets/src/images/newTutorial/79.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const AdditionalJourney = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.3 Settings associated with additional layers
      </Header>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1 Introducing the types of additional layers
      </Header>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.1 Text & Image
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The representation of textual and visual content helps to map and visualize various aspects
        of the customer journey to convey information effectively.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.2 "Point" layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        These are 6 layers with identical behavior, differing only by icons. These layers are an
        unnumbered list of items. Items can be marked with a colored background.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a touchpoint.
      </Paragraph>
      <Paragraph marginTop={10} variant="medium" className={styles.subtitleDescription}>
        These layers include:
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Gain point</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        Positive aspects or benefits that enhance the customer’s experience.
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Pain point</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        Challenges or problems customers face in their journey, which can lead to dissatisfaction.
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Technological capabilities</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        Refer to the resources, skills and technologies the organization has or needs to develop to
        deliver an optimal customer experience.
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Expectations</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        What customers expect from their interactions with the business, which can range from
        product quality to customer service responsiveness.
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Metrics</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        Quantifiable measures used to assess aspects of the customer journey, like customer
        satisfaction scores or average response time.
      </Paragraph>
      <Paragraph marginTop={6} variant="medium" className={styles.subtitleDescription}>
        <strong>Voice of business</strong>
      </Paragraph>
      <Paragraph marginTop={1} variant="medium" className={styles.subtitleDescription}>
        Represents the business’s perspective, ensures that the customer experience is not only
        customer-centric but also aligns with the business goals.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.3 Finance
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The financial aspect of customer experience, including the cost to the business of providing
        the experience.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.4 What they say about us
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The financial aspect of customer experience, including the cost to the business of providing
        the experience.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.5 Statistics
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Helps to capture, collect and present any statistical data related to customer interactions
        and behaviors.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.6 Solutions
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Refers to the strategies and tools used to improve customer experience, such as a new
        technology platform or a customer loyalty program.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.7 Channels
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The various means of communication with customers, like email, social media, phone, or
        in-person.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The "Channels" layer does not follow the standard structure of adding cells to a phase,
        subphase, and touchpoint, but applies to the entire length of the journey. Individual points
        are then horizontally divided according to touchpoints.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.8 Emotional journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The emotional states that customers experience during their interactions with an
        organization, ranging from anticipation to satisfaction or frustration.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The "Emotional journey" layer does not follow the standard structure of adding cells to the
        phase, subphase and touchpoint, but applies to the entire length of the journey. Individual
        points are then horizontally divided according to touchpoints.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.9 Six pillars
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        KPMG's Six Pillars of Experience Excellence, a framework that outlines the key elements of a
        successful customer experience.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.10 Backlog
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The backlog is utilized for organizing, prioritizing, and managing potential solutions and
        improvements.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.11 Processes
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Detailed visualization of individual contact points within the customer journey using a BPMN
        diagram, enabling in-depth analysis of each specific interaction or event.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a subphase or touchpoint.The layer can be associated with a
        phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.12 Inline processes
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Offers a comprehensive BPMN diagram that maps the entire customer journey, connecting all
        touchpoints.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The "Inline processes" layer does not follow the standard structure of adding cells to the
        phase, subphase and touchpoint, but applies to the entire length of the journey.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1.13 Connected capabilities
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Refers to the KPMG Connected Enterprise methodology, encompassing eight distinct
        capabilities, each featuring industry-specific sub-capabilities.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The layer can be associated with a phase, subphase or touchpoint.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.2 Adding new additional layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        New layer can be added by hovering over the layer and clicking on the "Plus" button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image70} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Choose the layer you want to add to the journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image71} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.3 Naming additional layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To name a layer, click on the predefined layer name and rewrite the text. The same approach
        can be applied to layer description.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image72} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.4 Moving additional layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To move a layer, go in the dropdown menu and click on the "Move down"/"Move up" option.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image73} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The second way to move a layer is through the "Journey Management" sidebar, the "Layers"
        option. Here you can change the order of items based on "Drag and drop" activity.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image74} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.5 Hiding/Unhiding additional layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To hide a layer, go in the dropdown menu and click on the "Hide layer" option.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image75} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The second way to hide a layer is through the "Journey Management" sidebar, the "Layers"
        option. Here you can hide the specific layer by clicking on the "Open eye" icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image76} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The only way to unhide the given layer is to click the "Open eye" icon in the sidebar.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image77} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.6 Deleting additional layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To delete a layer, go in the dropdown menu and click on the "Delete layer" option.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image78} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The second way to delete a layer is through the "Journey Management" sidebar, the "Layers"
        option. Here you can delete the specific layer by clicking on the "Red cross" icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image79} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
