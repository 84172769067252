import {
  WsEnhancedErrorMessage,
  WsRequestMessage,
  WsResponseMessage,
} from '@dis/types/src/wsModels'
import { dispatchedActions, store } from '@dis/redux'
import packageJson from '../../../package.json'

type BufferItem = WsRequestMessage & {
  msgid: string
  time: string
}

type StrictCallback<Response = any> = (
  responseData: Response,
  error?: WsEnhancedErrorMessage['error'],
) => any

export class WebsocketApi {
  private static buffer: Array<BufferItem> = []
  private static bufferTimeoutRef: any
  private static socket: WebSocket
  private static opened = false
  private static manuallyClosed = false
  private static onMessageCallback: (message: WsResponseMessage) => void = () => {}
  private static reconnectCallback = () => {}
  private static strictCallbacks: Record<string, StrictCallback> = {}
  private static checkWebsocketRef: ReturnType<typeof window.setTimeout>

  public static init = (url: string) => {
    // FIXME
    // eslint-disable-next-line no-console
    console.log('websocket init:', { opened: this.opened })

    if (!this.opened && url) {
      this.manuallyClosed = false
      this.socket = new WebSocket(`${url}&X-App-Version=${packageJson.version}`)
      this.socket.onclose = this.onclose
      this.socket.onerror = this.onerror
      this.socket.onopen = this.onopen
      this.socket.onmessage = this.onMessage
    }
  }

  private static processBuffer = () => {
    // Make sure the previous interval is really destroyed
    clearTimeout(this.bufferTimeoutRef)

    try {
      while (
        this.buffer.length &&
        this.opened &&
        !this.manuallyClosed &&
        this.socket.readyState === 1
      ) {
        const message = this.buffer.shift()

        if (message) {
          const messageString = JSON.stringify(message)
          this.socket.send(messageString)
        }
      }
    } catch (ex) {
      console.error(ex)
    }

    this.bufferTimeoutRef = setTimeout(this.processBuffer, 100)
  }

  private static onopen: WebSocket['onopen'] = () => {
    this.opened = true
    dispatchedActions.api.setIsWebsocketConnected(true)

    this.processBuffer()
  }

  private static onMessage: WebSocket['onmessage'] = (event) => {
    clearTimeout(this.checkWebsocketRef)

    const { websocketTimeout } = store.getState().api

    /*
     * Close websocket after long inactivity to avoid zombie connection.
     * After close the websocket it's automatically re-opened.
     */
    this.checkWebsocketRef = setTimeout(() => {
      if (!this.manuallyClosed && this.opened) {
        this.close(true)
      }
    }, websocketTimeout)

    if (typeof event.data === 'string') {
      const message = JSON.parse(event.data) as WsResponseMessage

      if (!message) {
        console.error('WebsocketApi - error: Message object is undefined!')
        return
      }

      this.onMessageCallback(message)
    } else {
      console.warn('WebsocketApi - unknown message: ', event.data)
    }
  }

  private static onclose: WebSocket['onclose'] = () => {
    this.opened = false
    clearTimeout(this.bufferTimeoutRef)
    clearTimeout(this.checkWebsocketRef)
    dispatchedActions.api.setConnectionId(undefined)
    dispatchedActions.api.setIsWebsocketConnected(false)

    // FIXME
    // eslint-disable-next-line no-console
    console.log('websocket onclose', { manuallyClosed: this.manuallyClosed })

    if (!this.manuallyClosed) {
      const intervalRef = setInterval(() => {
        const { isBrowserOnline } = store.getState().api

        if (isBrowserOnline) {
          clearInterval(intervalRef)

          // FIXME
          // eslint-disable-next-line no-console
          console.log('websocket onclose - call reconnectCallback')

          // This invokes negotiate EP to get URL with actual access token and also calls websocket init function
          this.reconnectCallback()
        }
      }, 5_000)
    }
  }

  private static onerror: WebSocket['onerror'] = (event) => {
    console.error('WebsocketApi - socket error', event)
  }

  public static close = (avoidManuallyClosed = false) => {
    if (!avoidManuallyClosed) {
      this.manuallyClosed = true
    }

    if (this.opened) {
      // FIXME
      // eslint-disable-next-line no-console
      console.log('websocket close - call this.socket.close()')

      this.socket.close()
    }
  }

  public static sendMessage = (message: BufferItem, callback?: StrictCallback) => {
    if (callback) {
      this.strictCallbacks[message.msgid] = callback
    }

    this.buffer.push(message)
  }

  public static setOnMessageCallback = (
    onMessageCallback: (message: WsResponseMessage) => void,
  ) => {
    this.onMessageCallback = onMessageCallback
  }

  public static setReconnectCallback = (reconnectCallback: VoidFunction) => {
    this.reconnectCallback = reconnectCallback
  }
}
