import { ReactNode } from 'react'
import { ModalDialogProps } from '@dis/types/src/ModalDialogTypes'
import { backendErrorCodeMap } from '@dis/utils/src/beErrorCodeMap'
import { BackendErrorCodesUnion } from '@dis/types/src/BackendErrorCodeList'
import { t, tKeys } from '@dis/languages'
import { CentralModalDialogConfiguration } from '@dis/types/src/centralModalDialogTypes'
import { dispatchedActions } from '@dis/redux'

export type ErrorCodeModalProps = Pick<
  ModalDialogProps,
  | 'primaryBtnText'
  | 'onPrimaryBtnClick'
  | 'secondaryBtnText'
  | 'onSecondaryBtnClick'
  | 'hasCrossClose'
  | 'title'
  | 'onCrossBtnClick'
> & {
  defaultContent?: string | ReactNode
  errorCode?: string
  priority?: 'errorCode' | 'content' | 'mix'
}

export const errorCodeModal = ({
  title,
  errorCode,
  defaultContent,
  primaryBtnText,
  hasCrossClose = true,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  priority = 'errorCode',
  secondaryBtnText,
  onCrossBtnClick,
}: ErrorCodeModalProps): CentralModalDialogConfiguration => {
  let content = defaultContent ?? ''

  let msgId = undefined

  const localizedMessage =
    errorCode &&
    backendErrorCodeMap[errorCode as BackendErrorCodesUnion] &&
    backendErrorCodeMap[errorCode as BackendErrorCodesUnion].localizedMessage

  if (priority === 'errorCode') {
    if (localizedMessage) {
      content = localizedMessage
      // This avoids to show same error message multiple times
      msgId = errorCode
    }
  }

  if (priority === 'mix') {
    if (localizedMessage && defaultContent) {
      content = (
        <>
          {defaultContent}
          <br />
          <br />
          {t(tKeys.errors.errorCodeModal.errorDetail)} {localizedMessage}
        </>
      )
    } else if (localizedMessage) {
      content = localizedMessage
    }
  }

  return {
    content: content || t(tKeys.errors.default.content),
    crossButton: {
      onClick: onCrossBtnClick,
    },
    hasCrossClose,
    id: msgId,
    primaryButton: {
      btnText: primaryBtnText || t(tKeys.common.accept),
      onClick: onPrimaryBtnClick,
    },
    secondaryButton: secondaryBtnText
      ? {
          btnText: secondaryBtnText,
          onClick: onSecondaryBtnClick,
        }
      : undefined,
    severity: 'error',
    title: title || t(tKeys.errors.default.title),
  }
}

export const errorCodeModalCallback = (props: ErrorCodeModalProps) =>
  dispatchedActions.centralModalDialog.showModalDialog(errorCodeModal(props))
