import { colors, shadows, theme } from '@dis/styles'
import { css } from '@emotion/css'

export type VerticalAlign = 'top' | 'center' | 'bottom'
export type HorizontalAlign = 'left' | 'center' | 'right'
export type Size = 'small' | 'medium' | 'large'

const justifyContent = {
  bottom: 'flex-end',
  center: 'center',
  top: 'flex-start',
}

const alignItems = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const sizes = {
  large: '560px',
  medium: '370px',
  small: '272px',
}

const button = {
  alwaysVisible: css`
    opacity: 1;
  `,
  leftMenu: css`
    left: ${theme.spacing(4)};
  `,
  rightMenu: css`
    right: ${theme.spacing(4)};
  `,
  root: css`
    opacity: 0;
    position: absolute;
    top: ${theme.spacing(4)};
    z-index: 2;
    transition: opacity 0.2s ease-in-out;
  `,
}

export const styles = {
  button,
  card: (verticalAlign: VerticalAlign, horizontalAlign: HorizontalAlign) => css`
    align-items: ${alignItems[horizontalAlign]};
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    justify-content: ${justifyContent[verticalAlign]};
    padding: ${theme.spacing(6)};
    position: relative;
  `,

  size: (size: Size) => css`
    width: ${sizes[size]};
  `,
  tile: css`
    height: 100%;
    border: 1px solid ${colors.background.white};
    box-shadow: ${shadows[16]};
    transition: border-color ease-in-out 0.2s;

    &:hover {
      border: 1px solid ${colors.kpmg.blue};

      ${'.' + button.root} {
        opacity: 1;
      }
    }
  `,
  visible: css`
    opacity: 1;
  `,
}
