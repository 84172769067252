import { useCallback, useRef, useState } from 'react'
import { WsEnhancedResponseMessage, WsRestoreMessageModel } from '@dis/types/src/wsModels'
import { Api } from '@dis/api'

type Props = {
  onData?: (data: WsEnhancedResponseMessage) => void
}

export const useRestore = (props?: Props) => {
  const [data, setData] = useState<WsEnhancedResponseMessage>()
  const [loading, setLoading] = useState(false)

  const send = useRef<(message: WsRestoreMessageModel) => void>()

  const onData = props?.onData

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage) => {
      setData(newData)
      setLoading(false)
      onData?.(newData)
    },
    [setData, setLoading, onData],
  )

  const sendWrapper = useCallback(
    (message: WsRestoreMessageModel) => {
      Api.sendRestoreMessage(message, onMessage)
    },
    [onMessage],
  )

  send.current = sendWrapper

  return {
    data,
    loading,
    send: send.current,
  }
}
