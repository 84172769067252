import { Button } from '@mui/material'
import { SvgImage } from '@dis/components'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import ExportIcon from '@dis/assets/src/icons/ExportIcon.svg'
import { styles } from './styles'
import { pdfTutorial } from './constants'

const msg = tKeys.tutorials

export const TutorialPdfButton = () => {
  const { t } = useTranslation()

  return (
    <>
      <a href={pdfTutorial} download="PdfTutorial" target="_blank" rel="noreferrer">
        <Button variant="outlined" color="primary" className={styles.actionButton}>
          <SvgImage src={ExportIcon} />
          {t(msg.tutorialDownload)}
        </Button>
      </a>
    </>
  )
}
