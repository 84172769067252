import { useCallback, useRef } from 'react'
import {
  WsEnhancedResponseMessage,
  WsMessageModel,
  WsSearchMessageModel,
} from '@dis/types/src/wsModels'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { SearchResults } from '@dis/redux/src/search/searchTypes'
import { Api } from '@dis/api'

export const useSearch = () => {
  const selectedTenant = useAppSelector(selectSelectedTenantId)

  const send = useRef<(data: string) => void>()

  const sendWrapper = useCallback(
    (data: string) => {
      dispatchedActions.search.setSearchWord(data)
      dispatchedActions.search.setSearchResults(undefined)

      const message: WsSearchMessageModel = {
        search: {
          data,
          id: selectedTenant?.toString(),
          model: WsMessageModel.Search,
        },
        tenantId: selectedTenant || 0,
      }

      Api.sendSearchMessage(message, (data: WsEnhancedResponseMessage<SearchResults>, error) => {
        if (error) {
          dispatchedActions.search.init()
        } else if (data) {
          dispatchedActions.search.setSearchResults(data.data)
        }
      })
    },
    [selectedTenant],
  )

  send.current = sendWrapper

  return {
    sendSearch: send.current,
  }
}
