import { ReactNode, useMemo } from 'react'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { generateTheme } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectLanguage } from '@dis/redux/src/user/selectors'

type Props = {
  children: ReactNode
}

export const ThemeProvider = ({ children }: Props) => {
  const language = useAppSelector(selectLanguage)

  const localTheme = useMemo(
    () => generateTheme(language === 'ar'),

    [language],
  )

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={localTheme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}
