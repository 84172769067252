import { colors, hex2rgba, shadowClasses, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  buttons: {
    common: css`
      flex: 1 1 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
  },
  closeIcon: css`
    path {
      fill: ${colors.gray.gray40};
    }
  `,
  closeIconButton: css`
    background-color: transparent;
    height: ${theme.spacing(6)};
    width: ${theme.spacing(6)};
  `,

  closeIconContainer: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-inline-end: ${theme.spacing(3)};
    padding-top: ${theme.spacing(4)};
  `,
  footerContainer: css`
    display: flex;
    justify-content: center;
    gap: ${theme.spacing(3)};
    width: 100%;
  `,
  iconContainer: css`
    padding-bottom: ${theme.spacing(5)};
  `,
  innerContainer: css`
    display: flex;
    flex-direction: column;
    width: ${theme.spacing(86)};
    max-width: 90%;
    align-items: center;
    justify-content: center;
    padding-bottom: ${theme.spacing(8)};
  `,
  modalContainer: css`
    ${shadowClasses['13']};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.background.white};
    border-radius: ${theme.spacing(2)};
    width: ${theme.spacing(105)};
    max-width: 90%;
    outline: none;
    padding: ${theme.spacing(2)} ${theme.spacing(1)};
  `,
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiBackdrop-root {
      background: ${hex2rgba(colors.text, 0.4)};
    }
  `,
  textContainer: css`
    gap: ${theme.spacing(3)};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${theme.spacing(70)};
    margin-bottom: ${theme.spacing(11)};
    text-align: center;
    margin-top: -${theme.spacing(2)};
    max-width: 100%;
    color: ${colors.text};
  `,
}
