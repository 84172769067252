import { Header, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import image2 from '@dis/assets/src/images/newTutorial/2.png'
import image3 from '@dis/assets/src/images/newTutorial/3.png'
import image4 from '@dis/assets/src/images/newTutorial/4.png'
import image5 from '@dis/assets/src/images/newTutorial/5.png'
import image6 from '@dis/assets/src/images/newTutorial/6.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

const msg = tKeys.tutorials.signingIn

/* eslint-disable react/no-unescaped-entities */
export const SigningIn = ({ id }: ScrollProps) => {
  const { t } = useTranslation()
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        {t(msg.signingInHeader)}
      </Header>
      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        {t(msg.signingInHeaderDescription)}{' '}
        <a href="https://globaldiscovery.kpmg.com/">https://globaldiscovery.kpmg.com/</a>{' '}
        {t(msg.signingInHeaderDescription2)}
      </Paragraph>
      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image2} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        After clicking the "Sign in" button, a modal window will appear for selecting the Azure
        Active Directory user account under which you want to sign in to Microsoft Azure Active
        Directory.
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image3} alt="Introduction Discovery" />
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        After a successful login, the user, depending on its type, is shown either the "All tenants"
        screen (KPMG user) for selecting a specific tenant or is redirected directly to the
        application's "Landing page" (External user; have only one assigned tenant).
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <strong>"Kpmg admin"</strong> - highest application role, which has access to all tenants
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        The <strong>"All tenants"</strong> screen is displayed (by default in listView) with a full
        list of tenants across the application including additional descriptions.
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image4} alt="Introduction Discovery" />
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <strong>"Kpmg user"</strong> - ordinary internal user role, which can be associated with
        several tenants
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        The <strong>"All tenants"</strong> screen is displayed (by default in listView) with tenants
        assigned to the given user.
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image5} alt="Introduction Discovery" />
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        After selecting a specific tenant, user will be redirected to the "Landing page" (for other
        roles, you will be automatically redirected without the need to select a tenant).
      </Paragraph>

      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image6} alt="Introduction Discovery" />
      </Paragraph>
    </div>
  )
}
