import { useCallback } from 'react'
import { SchemaOf } from 'yup'
import { UseFormProps, useForm as useFormOriginal } from 'react-hook-form'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form/dist/types'
import { yupResolver } from '@hookform/resolvers/yup'
export { formArrayCompare } from './utils'

const handleErrors = (errors: any) => {
  console.warn('handleSubmit - form errors: ', errors)
}

export const useForm = <TFieldValues extends FieldValues, TContext = any>({
  validationSchema,
  ...props
}: UseFormProps<TFieldValues, TContext> & {
  validationSchema: SchemaOf<TFieldValues>
}): UseFormReturn<TFieldValues, TContext> => {
  const { handleSubmit, ...form } = useFormOriginal<TFieldValues, TContext>({
    ...props,
    resolver: yupResolver(validationSchema.required()),
  })

  const handleSubmitWrapper = useCallback(
    (onSubmit: SubmitHandler<TFieldValues>, onError = handleErrors) => {
      return handleSubmit(onSubmit, onError)
    },
    [handleSubmit],
  )

  return { handleSubmit: handleSubmitWrapper, ...form }
}
