import { css } from '@emotion/css'
import { theme } from '@dis/styles'

const threeDotsText = css`
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const styles = {
  table: {
    cellDate: css`
      width: 15%;
    `,
    cellDescription: css`
      width: 30%;
      max-width: ${theme.spacing(75)};
      padding: ${theme.spacing(2)};
    `,
    cellID: css`
      width: 10%;
      max-width: ${theme.spacing(22.5)};
    `,
    cellName: css`
      width: 30%;
      max-width: ${theme.spacing(90)};
      padding: ${theme.spacing(2)};
    `,
    cellPopover: css`
      width: 15%;
      max-width: ${theme.spacing(44)};
      padding: ${theme.spacing(2)};
    `,
    description: css`
      ${threeDotsText};
      max-width: 75%;
    `,
    name: css`
      ${threeDotsText};
      max-width: 90%;
    `,
    tBody: css`
      tr td {
        &:not(:last-child) {
          cursor: pointer;
          white-space: nowrap;
        }
      }
    `,
  },
}
