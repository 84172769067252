import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { useAppSelector } from '@dis/redux'
import { PersonaType } from '@dis/types/src/PersonaTypes'
import DefaultPersonIcon from '@dis/assets/src/icons/DefaultPersonIcon.svg'
import { Paragraph, PromisedImg, SvgImage } from '@dis/components'
import { tKeys } from '@dis/languages'
import { getFileUrl } from '@dis/api/src/endpoints'
import { styles } from './styles'

type PersonaPreviewProps = Omit<PersonaType, 'id'>

const MAX_PERSONA_NAME_AVATAR_LENGTH = 16

export const PersonaPreview = ({ image, name, basic }: PersonaPreviewProps) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectSelectedTenantId)

  return (
    <div className={styles.personaMainContainer}>
      <Grid container flexWrap="nowrap" direction="row">
        <Grid item xs={2} minWidth="3.75rem" height="3.75rem">
          <div className={styles.personaImage}>
            <PromisedImg url={image ? getFileUrl(tenantId, image) : ''} className={styles.photo}>
              <SvgImage src={DefaultPersonIcon} />
            </PromisedImg>
          </div>
        </Grid>
        <Grid item xs={6} className={styles.textContainer}>
          <Grid container>
            <Grid
              item
              className={styles.personaTitle}
              xs={20}
              title={name && name.length > MAX_PERSONA_NAME_AVATAR_LENGTH ? name : undefined}>
              <Paragraph fontWeight="bold">{name || t(tKeys.personasPreview.newPersona)}</Paragraph>
            </Grid>
            <Grid
              title={basic?.[0]?.value && `${basic[0]?.title}: ${basic[0]?.value}`}
              className={styles.personaDescription}
              whiteSpace="nowrap"
              item
              xs={20}>
              {basic?.[0]?.value && basic?.[0]?.title && (
                <>
                  {basic[0].title}: {basic[0].value}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
