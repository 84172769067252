import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  link: css`
    color: ${colors.text};
    position: relative;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 100%;
      height: 1.5px;
      background-color: ${colors.gray.gray80};
    }
  `,
  nextSteps: css`
    display: flex;
    flex-direction: column;
    background-color: ${colors.kpmg.cobaltLight};
    padding: ${theme.spacing(6)};
    color: ${colors.text};
    gap: ${theme.spacing(3)};
    border-radius: ${theme.spacing(2)};
    border-inline-start: 6px solid ${colors.kpmg.pacificBlue};
    margin-top: ${theme.spacing(15)};
  `,
}
