import { useMemo } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectPermissions } from '@dis/redux/src/security/selectors'
import { selectAtlases, selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'

type Props = {
  atlasIds: Array<number>
}

export const useAtlasPermissions = ({ atlasIds }: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { canDeleteAtlas } = useAppSelector(selectPermissions)
  const atlases = useAppSelector(selectAtlases)

  const permissions = useMemo(() => {
    const ret: Record<string, { canDelete: boolean; canEdit: boolean; canRead: boolean }> = {}

    atlasIds.forEach((aId) => {
      ret[aId] = {
        canDelete: canDeleteAtlas,
        canEdit: false,
        canRead: false,
      }
    })

    if (tenantId && atlases?.length) {
      atlases.forEach(({ id, accesslevel }) => {
        if (atlasIds.includes(id)) {
          if (accesslevel === 'write') {
            ret[id].canRead = true
            ret[id].canEdit = true

            // Some roles don't have the delete permission regardless the access level is "write"
            ret[id].canDelete = canDeleteAtlas
          } else if (accesslevel === 'read') {
            ret[id].canRead = true
          }
        }
      })
    }

    return ret
  }, [atlasIds, atlases, tenantId, canDeleteAtlas])

  return permissions
}
