import { createContext } from 'react'
import { DraggableSyntheticListeners } from '@dnd-kit/core'

interface SortableItemContextType {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

export const SortableItemContext = createContext<SortableItemContextType>({
  attributes: {},
  listeners: undefined,
  ref() {
    return
  },
})
