import { css } from '@emotion/css'
import { theme, colors, hex2rgba, shadowClasses } from '@dis/styles'

const textContainer = css`
  color: ${colors.gray.gray60};
  padding-inline-end: ${theme.spacing(3.24)};
  flex: 1 1 auto;
`

// TODO: remove own styles of footer buttons when new central design of buttons is prepared
export const styles = {
  cardContainer: {
    large: css`
      width: 42.5rem;
    `,
    normal: css`
      width: 30rem;
    `,
    root: css`
      max-width: ${theme.breakpoints.values.lg}px;
      margin: auto;
      outline: none;
      display: flex;
      flex-direction: column;
      box-shadow: none;

      @media all and (min-height: 500px) {
        max-height: 100%;
      }
    `,
  },
  crossButton: css`
    margin-inline-start: auto;
    padding: 0;
  `,
  modalBody: {
    defaultSpacing: css`
      padding: ${theme.spacing(6)} ${theme.spacing(6)};
    `,
    large: (isHeightFixed: boolean) => css`
      @media all and (min-height: 500px) {
        flex: 1 1 auto;
        overflow: auto;
        max-height: 31.5rem;
        height: ${isHeightFixed ? '31.5rem' : 'auto'};
      }
    `,
    normal: (isHeightFixed: boolean) => css`
      @media all and (min-height: 500px) {
        flex: 1 1 auto;
        overflow: auto;
        max-height: 22.375rem;
        height: ${isHeightFixed ? '22.375rem' : 'auto'};
      }
    `,
    root: css`
      min-height: ${theme.spacing(10)};
      max-width: 100%;
      flex: 0 0 auto;
      word-break: break-word;
      margin: 0 ${theme.spacing(2)};

      @media all and (min-height: 500px) {
        flex: 1 1 auto;
        overflow: auto;
        max-height: 100%;
      }
    `,
    xLarge: (isHeightFixed: boolean) => css`
      @media all and (min-height: 500px) {
        flex: 1 1 auto;
        overflow: auto;
        max-height: 50rem;
        height: ${isHeightFixed ? '50rem' : 'auto'};
      }
    `,
  },
  modalFooter: {
    button: css`
      &:disabled {
        background-color: ${colors.kpmg.pacificBlue};
        color: ${hex2rgba(colors.gray.gray0, 0.5)};
        cursor: not-allowed;
        pointer-events: all !important;
      }
      text-align: center;
      flex: 0 0 auto;
      box-sizing: content-box;
      max-width: 100%;
    `,
    justifyContent: (justifyContent: string) => css`
      justify-content: ${justifyContent};
    `,
    root: css`
      align-items: center;
      display: flex;
      padding: ${theme.spacing(8)};
      border-top: solid 1px ${colors.gray.gray5};
      gap: ${theme.spacing(3)};

      @media all and (max-width: 750px) {
        flex-wrap: wrap;

        ${'.' + textContainer} {
          width: 100%;
        }
      }
    `,
    textContainer,
  },
  modalHeader: css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(8)};
    border-bottom: solid 1px ${colors.gray.gray5};
    gap: ${theme.spacing(3)};
  `,
  root: css`
    display: flex;
    margin: ${theme.spacing(2)};
    overflow: auto;
  `,
  tooltip: {
    messageBox: css`
      ${shadowClasses['19']};
      background-color: ${colors.background.white};
      border: 1px solid ${colors.gray.gray10};
      height: 5rem;
      max-width: 100%;
      max-height: 100%;
      padding: ${theme.spacing(3)};
      color: ${colors.text};
      border-radius: 0.5rem;

      .MuiTooltip-arrow::before {
        border: 1px solid ${colors.gray.gray10};
      }

      .MuiTooltip-arrow {
        width: 2rem;
        color: ${colors.gray.gray0};
        height: 1.25rem;
        margin-top: -${theme.spacing(5)} !important;
        margin-bottom: -${theme.spacing(5)} !important;
      }
    `,
  },
}
