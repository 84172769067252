import { Store, RecordSource, Environment, Network, Observable, FetchFunction } from 'relay-runtime'
import { ExtendedAxiosResponse } from '@dis/types/src/api'
import { GraphQLResponse } from 'relay-runtime/lib/network/RelayNetworkTypes'
import { AxiosInstances } from '../axios'

let uri = ''

const fetchFunction: FetchFunction = (params, variables) => {
  const axiosResponsePromise = AxiosInstances.axiosInstance.request({
    data: {
      query: params.text,
      variables,
    },
    method: 'post',
    url: uri,
  })

  return Observable.from(
    axiosResponsePromise.then((axiosResponse) => {
      const { status, data, statusText, OK } = axiosResponse as ExtendedAxiosResponse

      return new Promise((resolve, reject) => {
        if (OK) {
          return data?.errors ? reject(data) : resolve(data)
        } else {
          const ret: GraphQLResponse = {
            data: data?.data || undefined,
            errors: data?.errors || [{ message: `Status ${status}: ${statusText}` }],
            extensions: data?.extensions,
          }

          return reject(ret)
        }
      })
    }),
  )
}

/**
 * Creates a new Relay environment instance for managing (fetching, storing) GraphQL data.
 */
function createEnvironment() {
  const network = Network.create(fetchFunction)

  const store = new Store(new RecordSource())

  return new Environment({
    network,
    store,
  })
}

export const reactRelayEnvironment = createEnvironment()

export const setRelayClientUri = (newUri: string) => {
  uri = newUri
}
