import { css } from '@emotion/css'

export const styles = {
  avatar: css`
    display: inline-flex;
    content-visibility: auto;
    flex: 0 0 auto;
  `,
  photo: (size = 140) => css`
    border-radius: ${size}px;
    height: ${size}px;
    object-fit: cover;
    width: ${size}px;
  `,
}
