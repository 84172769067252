import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image31 from '@dis/assets/src/images/newTutorial/31.png'
import image32 from '@dis/assets/src/images/newTutorial/32.png'
import image33 from '@dis/assets/src/images/newTutorial/33.png'
import image34 from '@dis/assets/src/images/newTutorial/34.png'
import image35 from '@dis/assets/src/images/newTutorial/35.png'
import image36 from '@dis/assets/src/images/newTutorial/36.png'
import image37 from '@dis/assets/src/images/newTutorial/37.png'
import image38 from '@dis/assets/src/images/newTutorial/38.png'
import image39 from '@dis/assets/src/images/newTutorial/39.png'
import image40 from '@dis/assets/src/images/newTutorial/40.png'
import image41 from '@dis/assets/src/images/newTutorial/41.png'
import image42 from '@dis/assets/src/images/newTutorial/42.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const PersonaInformation = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.3 Filling in the persona information
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        As soon as the user confirms the name of the persona with the "Submit" button, he is
        redirected to the "Change profile" screen to add data to the persona just created.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image31} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1 "Main information" section
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image32} alt="Settings Discovery" />
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          To add a picture representing the persona, click on the "Add photo" icon and select a
          picture from your files.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          To add a name of the persona, click in the "Persona name" field and enter the name.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          To add a persona description, click in the "Persona description" field and enter the
          description.
        </ListItem>
      </List>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.2 "Persona details" section
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        There are 5 basic persona attributes predefined across the application:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Age</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Gender</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Martial status</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Education</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Occupation</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        These predefined attributes of the persona can be easily changed for the needs of a specific
        tenant on the "Management of tenant" screen, "Personas" section (Kpmg admin role only).
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image33} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Attributes are automatically written into the "Persona details" section and have the
        specifications below:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          Can’t be deleted (only renamed for the purposes of a specific persona)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Displayed in the persona locket on the "All personas" screen
        </ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image34} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Within the "Persona details" section you can:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          Enter information to each of the attributes (Age, Gender, etc.) by clicking on "Content"
          field.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Change the attribute name by clicking on the "Pencil" icon.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Add another attribut (beyond the predefined ones) by clicking on the "Add new" button in
          the lower left corner of the section.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Delete an added attribute by moving your cursor on the content of the given attribute and
          click on the "Red cross" icon that appears next to it.
        </ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image35} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image36} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.3 "Behaviour" section
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The Behaviour section is a place where you can add information about the interests and
        personal behaviour specific to the persona.
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          Add information by clicking on the "Add new" button. Then enter the information by
          clicking on "Heading" and "Contents".
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Every time you click on the "Add new" button, a new "Heading" and "Content" box appears.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Delete anything by moving your cursor on the content of the given attribute and click on
          the "Red cross" icon that appears next to it.
        </ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image37} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image38} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image39} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.4 "Preferences" section
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Here you can add information about a persona’s preferences regarding consumer behaviour.
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          To add or delete any information, please refer to the steps above in "Behaviour" section,
          as the steps are the same.
        </ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image40} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image41} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image42} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
