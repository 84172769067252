import { NavigationBar } from '@dis/components/src/NavigationBar/NavigationBar'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { navBarLink } from '@dis/constants'

const msg = tKeys.tutorials

export const TutorialNavigationBar = () => {
  const { t } = useTranslation()

  return (
    <NavigationBar
      description={t(msg.tutorialMoreHelpDescription)}
      linkText={t(msg.tutorialMoreHelpLink)}
      title={t(msg.tutorialMoreHelpTitle)}
      link={navBarLink}
    />
  )
}
