import { css } from '@emotion/css'
import { colors } from './colors'
import { hex2rgba } from './utils'

/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
export const shadows = {
  1: `0 19.5px 19.5px -9.75px ${hex2rgba(colors.text, 0.1)}`,
  2: `0px 0px 2px 2px ${hex2rgba(colors.gray.gray100, 0.1)}`,
  3: `0 0px 0px 1px ${hex2rgba(colors.text, 0.1)}`,
  4: `0 2px 7px 0 ${hex2rgba(colors.gray.gray100, 0.5)}`,
  5: `0 0 15px 0 ${hex2rgba(colors.text, 0.6)}`,
  6: `0 15px 22.5px ${hex2rgba(colors.text, 0.05)}`,
  7: `0px 0px 0px 1px ${hex2rgba(colors.text, 0.1)}`,
  8: `inset 0 0 7.5px ${hex2rgba(colors.text, 0.5)}`, // active in d&d in journeys
  9: `inset 0 0 30px ${hex2rgba(colors.text, 0.5)}`, // inactive in d&d in journeys
  10: `0px 8px 12px ${hex2rgba(colors.gray.gray100, 0.25)}, 0 3px 1px -2px ${hex2rgba(
    colors.gray.gray100,
    0.12,
  )},
  0 1px 5px 0 ${hex2rgba(colors.gray.gray100, 0.2)}`, // focus shadow for wysiwyg btns
  11: ` 0 2px 2px 0 ${hex2rgba(colors.gray.gray100, 0.14)}, 0 3px 1px -2px ${hex2rgba(
    colors.gray.gray100,
    0.12,
  )},
  0 1px 5px 0 ${hex2rgba(colors.gray.gray100, 0.2)}`, //  shadow for wysiwyg btns
  12: ` 0 24px 38px 3px ${hex2rgba(colors.gray.gray100, 0.14)}, 0 9px 46px 8px  ${hex2rgba(
    colors.gray.gray100,
    0.12,
  )},
  0 11px 15px -7px  ${hex2rgba(colors.gray.gray100, 0.2)}`,
  13: `0 24px 38px 3px  ${hex2rgba(colors.gray.gray100, 0.14)}, 0 9px 46px 8px  ${hex2rgba(
    colors.gray.gray100,
    0.12,
  )}, 0 11px 15px -7px  ${hex2rgba(colors.gray.gray100, 0.2)}`,
  14: `0 4px 23px 0px ${hex2rgba(colors.gray.gray10, 0.5)}`,
  15: `inset 1px 0 ${colors.gray.gray100}`,
  16: ` 0 4px 20px 0 ${hex2rgba(colors.gray.gray10, 0.79)}`,
  17: `0 0 30px ${colors.text}`, // inactive in d&d in journeys
  18: `0 4px 10px 0 ${hex2rgba(colors['#29355f'], 0.17)}`,
  19: `0 0 30px ${colors.gray.gray20}`,
}

export const shadowClasses = {
  1: css`
    box-shadow: ${shadows['1']};
  `,
  2: css`
    box-shadow: ${shadows['2']};
  `,
  3: css`
    box-shadow: ${shadows['3']};
  `,
  4: css`
    box-shadow: ${shadows['4']};
  `,
  5: css`
    box-shadow: ${shadows['5']};
  `,
  6: css`
    box-shadow: ${shadows['6']};
  `,
  7: css`
    box-shadow: ${shadows['7']};
  `,
  8: css`
    box-shadow: ${shadows['8']};
  `,
  9: css`
    box-shadow: ${shadows['9']};
  `,
  10: css`
    box-shadow: ${shadows['10']};
  `,
  11: css`
    box-shadow: ${shadows['11']};
  `,
  12: css`
    box-shadow: ${shadows['12']};
  `,
  13: css`
    box-shadow: ${shadows['13']};
  `,
  14: css`
    box-shadow: ${shadows['14']};
  `,
  15: css`
    box-shadow: ${shadows['15']};
  `,
  16: css`
    box-shadow: ${shadows['16']};
  `,
  17: css`
    box-shadow: ${shadows['17']};
  `,
  18: css`
    box-shadow: ${shadows['18']};
  `,
  19: css`
    box-shadow: ${shadows['19']};
  `,
}
