import { theme, colors } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  addPhoto: css`
    height: 8rem;
    width: 8rem;
    max-height: 100%;
    max-width: 100%;
  `,
  cropper: css`
    height: 25rem;
    max-height: 100%;
    width: 100%;
    > div > div .cropper-view-box {
      background: ${colors.gray.gray0};
    }
  `,
  divider: css`
    margin: ${theme.spacing(6)} 0;
  `,
  preview: (dimension = 200, isRound = false) => css`
    box-sizing: border-box;
    display: inline-block;
    float: right;
    height: ${dimension}px;
    border-radius: ${isRound ? '100%' : '0'};
    margin-left: 10px;
    overflow: hidden;
    width: ${dimension}px;
  `,
  title: css`
    display: inline-flex;
    justify-content: space-between;
  `,
}
