import { ErrorCode } from '@dis/constants'
import { t, tKeys } from '@dis/languages'
import { dispatchedActions } from '@dis/redux'
import { ExtendedError } from '@dis/utils'
import { backendErrorCodeMap } from '@dis/utils/src/beErrorCodeMap'
import { CentralModalDialogConfiguration } from '@dis/types/src/centralModalDialogTypes'
import { reloadApp } from '@dis/utils/src/url'
import { BackendErrorCodesUnion } from '@dis/types/src/BackendErrorCodeList'

const logout = () => {
  dispatchedActions.security.logoutThisDevice()
}

export const generalErrorModals = {
  [ErrorCode.UnavailableConfigJson]: () =>
    dispatchedActions.centralModalDialog.showModalDialog({
      hasCrossClose: false,
      primaryButton: {
        btnText: t(tKeys.errors.cannotLoadConfigJsonModal.btn),
        onClick: reloadApp,
      },
      severity: 'error',
      title: t(tKeys.errors.cannotLoadConfigJsonModal.title),
    }),
  [ErrorCode.InvalidConfigJson]: (content: string) =>
    dispatchedActions.centralModalDialog.showModalDialog({
      content,
      hasCrossClose: false,
      primaryButton: {
        btnText: t(tKeys.errors.invalidConfigJsonModal.btn),
        onClick: reloadApp,
      },
      severity: 'error',
      title: t(tKeys.errors.invalidConfigJsonModal.title),
    }),
  // TODO: If environment is localhost or test then show "location" property in error dialog
  default: (ex: ExtendedError | Error) => {
    let title = t(tKeys.errors.default.title)
    let content: CentralModalDialogConfiguration['content'] = ex?.message?.toString() || ''

    if (ex instanceof ExtendedError) {
      if (ex.title) {
        title = ex.title
      }

      if (ex.errorCode && backendErrorCodeMap[ex.errorCode as BackendErrorCodesUnion]) {
        content = backendErrorCodeMap[ex.errorCode as BackendErrorCodesUnion].localizedMessage
      }
    }

    return dispatchedActions.centralModalDialog.showModalDialog({
      content,
      hasCrossClose: false,
      primaryButton: {
        btnText: t(tKeys.errors.invalidConfigJsonModal.btn),
        onClick: reloadApp,
      },
      secondaryButton: {
        btnText: t(tKeys.applicationMenu.logOutThisDevice),
        onClick: logout,
      },
      severity: 'error',
      title,
    })
  },
}
