import { Header, Paragraph } from '@dis/components'
import image47 from '@dis/assets/src/images/newTutorial/47.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const RemovePersona = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.5 Removing a persona from a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To remove a persona from a journey, click on the "Red cross" icon next to the name.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image47} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The given persona is automatically removed from all journey layers.
      </Paragraph>
    </div>
  )
}
