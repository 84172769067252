import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewEnum } from '@dis/types/src/GeneralTypes'

type ArchivedTenantsReducerState = {
  archivedTenantDisplayType: ViewEnum
}

const initialState: ArchivedTenantsReducerState = {
  archivedTenantDisplayType: ViewEnum.Table,
}

export const archivedTenantsSlice = createSlice({
  initialState,
  name: 'archivedTenants',
  reducers: {
    init: () => initialState,
    setArchivedTenantDisplayType: (
      state: ArchivedTenantsReducerState,
      action: PayloadAction<ViewEnum>,
    ) => {
      state.archivedTenantDisplayType = action.payload
    },
  },
})
