import { useCallback, useState } from 'react'

// TODO: refactor to use useToggle
export const useHandleOpen = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return { handleClose, handleOpen, open, setOpen }
}
