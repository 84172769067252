import { css } from '@emotion/css'
import { theme } from '@dis/styles'

export const styles = {
  card: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${theme.spacing(10)};
    max-width: ${theme.breakpoints.values.sm}px;
    padding: ${theme.spacing(8)};
  `,
  signInButtonWrapper: css`
    padding-top: ${theme.spacing(4)};
  `,
}
