import { Header, Paragraph } from '@dis/components'
import image23 from '@dis/assets/src/images/newTutorial/23.png'
import { styles } from '../styles'

/* eslint-disable react/no-unescaped-entities */
export const ExportToXML = () => {
  return (
    <>
      <Header marginTop={2} variant="h2">
        1.3 Export to XML
      </Header>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        To export a journey to XML format, click on the journey's dropdown menu and choose "Export
        into XML" option – your journey will be downloaded afterwards (and stored in the Downloads
        section).
      </Paragraph>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        <img src={image23} width="600" alt="Settings Discovery" />
      </Paragraph>
    </>
  )
}
