import { css } from '@emotion/css'
import { theme, colors } from '@dis/styles'

export const styles = {
  appBar: css`
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${theme.spacing(2)} ${theme.spacing(6)};
    align-items: center;
    justify-content: end;
    background-color: ${colors.kpmg.darkBlue};
    position: sticky;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: auto;
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};

    @media all and (min-width: 600px) {
      padding-top: ${theme.spacing(6.5)};
      padding-bottom: ${theme.spacing(6.5)};
    }

    & > * {
      margin-top: ${theme.spacing(0)};
      margin-bottom: ${theme.spacing(0)};
      flex: 0 0 auto;
    }
  `,
  breadcrumbContainer: css`
    order: 4;
    flex: 1 1 auto !important;
    margin-inline-end: auto;
  `,
  higherZindex: css`
    z-index: ${theme.zIndex.modal - 1};
  `,
  logoContainer: {
    root: css`
      order: 2;
      flex: 0 0 auto;
      margin-inline-end: auto;
      cursor: pointer;

      @media all and (min-width: 600px) {
        margin-inline-start: ${theme.spacing(1)};
      }
    `,
    rtl: css`
      order: 6;
    `,
  },
  menuContainer: css`
    align-items: center;
    display: flex;
    order: 6;
  `,
  menuContainerAr: css`
    order: 3;
  `,
  publicationStatus: {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: ${theme.spacing(9)};
      gap: ${theme.spacing(2)};
    `,
    rtl: css`
      order: 4;
    `,
  },
  resultsSearchBarWrapper: css`
    margin-right: ${theme.spacing(5)};
  `,
  revisionControl: {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing(6)};
    `,
    rtl: css`
      order: 5;
    `,
  },
  searchBar: css`
    order: 5;
    margin-inline-end: ${theme.spacing(10)};

    .MuiInputBase-root {
      padding-inline-end: ${theme.spacing(3.5)};
    }
  `,
}
