import { useCallback } from 'react'
import { NotificationModal } from '@dis/components'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectCentralModalDialogFirstMessage } from '@dis/redux/src/centralModalDialog/selectors'

export const CentralModalDialog = () => {
  const message = useAppSelector(selectCentralModalDialogFirstMessage)

  const hideModalDialog = useCallback(() => {
    if (message?.id) {
      dispatchedActions.centralModalDialog.hideModalDialog(message.id)
    }
  }, [message?.id])

  const onPrimaryBtnClick = useCallback(() => {
    if (message?.primaryButton?.onClick) {
      message?.primaryButton?.onClick()
    }

    hideModalDialog()
  }, [message, hideModalDialog])

  const onSecondaryBtnClick = useCallback(() => {
    if (message?.secondaryButton?.onClick) {
      message?.secondaryButton?.onClick()
    }

    hideModalDialog()
  }, [message, hideModalDialog])

  const onCrossBtnClick = useCallback(() => {
    if (message?.crossButton?.onClick) {
      message?.crossButton?.onClick()
    }
    hideModalDialog()
  }, [message?.crossButton, hideModalDialog])

  if (!message) {
    return <></>
  }

  return (
    <NotificationModal
      isOpen
      onCrossBtnClick={onCrossBtnClick}
      onModalDestroyed={hideModalDialog}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={onSecondaryBtnClick}
      primaryBtnText={message?.primaryButton?.btnText || ''}
      secondaryBtnText={message?.secondaryButton?.btnText || ''}
      severity={message?.severity}
      title={message?.title || ''}>
      {message?.content}
    </NotificationModal>
  )
}
