import { AxiosResponse } from 'axios'
import { AxiosInstances } from '.'
import {
  exportBacklogXlsxUrl,
  exportJourneyPdfUrl,
  exportJourneyXlsUrl,
  exportJourneyXmlUrl,
  exportPersonaPdfUrl,
  exportTenantUsersXlsUrl,
  Ids,
} from './endpoints'

type Type =
  | 'backlogXls'
  | 'journeyPdf'
  | 'journeyXls'
  | 'journeyXml'
  | 'personaPdf'
  | 'tenantUsersXls'

type FileExtension = 'pdf' | 'xlsx' | 'xml'

const config: Record<Type, { fileExtension: FileExtension; getUrl: (ids: Ids) => string }> = {
  backlogXls: {
    fileExtension: 'xlsx',
    getUrl: ({ tenantId, localeId }): string => exportBacklogXlsxUrl({ localeId, tenantId }),
  },
  journeyPdf: {
    fileExtension: 'pdf',
    getUrl: ({ tenantId, journeyId, localeId }) =>
      exportJourneyPdfUrl({ journeyId, localeId, tenantId }),
  },
  journeyXls: {
    fileExtension: 'xlsx',
    getUrl: ({ tenantId, journeyId, localeId }): string =>
      exportJourneyXlsUrl({ journeyId, localeId, tenantId }),
  },
  journeyXml: {
    fileExtension: 'xml',
    getUrl: ({ tenantId, journeyId, localeId }): string =>
      exportJourneyXmlUrl({ journeyId, localeId, tenantId }),
  },
  personaPdf: {
    fileExtension: 'pdf',
    getUrl: ({ tenantId, personaId, localeId }): string =>
      exportPersonaPdfUrl({ localeId, personaId, tenantId }),
  },
  tenantUsersXls: {
    fileExtension: 'xlsx',
    getUrl: ({ localeId }): string => exportTenantUsersXlsUrl({ localeId }),
  },
}

const contentTypes: Record<FileExtension, string> = {
  pdf: 'application/pdf',
  xlsx: 'application/json',
  xml: 'application/xml',
}

export const exportDocument = async (
  type: Type,
  ids?: Ids,
  params?: unknown,
  withPost = false,
): Promise<AxiosResponse> => {
  try {
    const { getUrl, fileExtension } = config[type]

    let response: AxiosResponse<Blob, any>

    if (withPost) {
      response = await AxiosInstances.axiosInstance.post<Blob>(getUrl(ids ?? {}), params, {
        headers: { 'Content-Type': contentTypes[fileExtension] },
        responseType: 'arraybuffer',
      })
    } else {
      response = await AxiosInstances.axiosInstance.get<Blob>(getUrl(ids ?? {}), {
        headers: { 'Content-Type': contentTypes[fileExtension] },
        params,
        responseType: 'arraybuffer',
      })
    }

    if (response.status === 200) {
      // YYYYMMDD
      const date = new Date().toISOString().replaceAll('-', '').slice(0, 8)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.style.position = 'absolute'
      link.style.visibility = 'hidden'
      link.setAttribute('download', `${date.split('.')[0]}_export.` + fileExtension)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    return response
  } catch (err: unknown) {
    console.error(`an error has occurred during document ${type}export`, err)
    throw err
  }
}
