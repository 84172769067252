import { createContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { TableContextType } from '@dis/components/src/table/types'

const defaultValue: TableContextType<any> = {
  currentPage: 0,
  idExtractor: () => uuidv4(),
  maxPage: 0,
  onPageChange: () => {},
  onRequestSort: () => {},
  orderBy: undefined,
  orderDirection: undefined,
  sortedRows: [],
}

export const TableContext = createContext<TableContextType<any>>(defaultValue)
