import { colors, theme } from '@dis/styles'
import { typography } from '@dis/styles/src/theme'
import { css } from '@emotion/css'

export const styles = {
  buttonHistory: css`
    color: ${colors.gray.gray0};
    height: 30px;
    margin: 0 ${theme.spacing(1)};
    padding: 0;
    width: 30px;
  `,
  buttonsContainer: css`
    z-index: 30;
    margin: 0 ${theme.spacing(4)};
    display: flex;
  `,
  container: {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing(6)};
    `,
    rtl: css`
      flex-direction: row-reverse;
    `,
  },
  versionListItem: css`
    font-size: ${typography.paragraph.small.fontSize};
    padding: ${theme.spacing(1)} ${theme.spacing(5)};
  `,
}
