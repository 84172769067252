import { colors, theme, typography, typographyFontWeight } from '@dis/styles'
import { css } from '@emotion/css'

const subtitleWidth = '39.4rem'
const smallScreen = '39.75rem'
const middleScreen = '56.25rem'
const normalScreen = '72.5rem'
const menuBtnWidth = '16.25rem'

export const styles = {
  actionButton: css`
    gap: ${theme.spacing(2)};
    width: auto;
    max-width: 21.56rem;
  `,
  buttonReportContainer: css`
    margin-top: ${theme.spacing(4)};
  `,
  containerScreenStyle: css`
    display: flex;
    overflow-y: hidden;
    flex: 1 1 auto;
    max-width: 100%;

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${smallScreen}) {
      flex-direction: column;
      flex: 0 0 auto;
    }
  `,
  contentInnerStyle: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
    max-width: ${normalScreen};
    padding: ${theme.spacing(10)};
  `,
  contentStyle: css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
  `,

  downloadButtonContainer: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${theme.spacing(4)};
  `,
  gap: css`
    margin-bottom: ${theme.spacing(1)};
  `,

  headerAndText: css`
    padding-bottom: ${theme.spacing(4)};
  `,
  menuBtn: css`
    gap: ${theme.spacing(2)};
    width: ${menuBtnWidth};
    justify-content: flex-start;
    padding: ${theme.spacing(4)};
    color: ${colors.gray.gray80};
    height: 3.5rem;
    border-radius: ${theme.spacing(2)};
    font-size: ${typography.paragraph.medium.fontSize};
    font-weight: ${typographyFontWeight.regular};
    margin-bottom: ${theme.spacing(1)};

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${middleScreen}) {
      width: 100%;
    }

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${smallScreen}) {
      width: 100%;
      height: auto;
      max-height: 1.8rem;
    }

    &:hover {
      background-color: ${colors.kpmg.cobaltLight};
    }
  `,
  menuText: css`
    color: ${colors.kpmg.pacificBlue};
    text-decoration: underline;
    max-width: max-content;
  `,

  navigateBackBtn: css`
    gap: ${theme.spacing(1)};
    width: ${menuBtnWidth};
    justify-content: flex-start;
    padding: ${theme.spacing(0)};
    color: ${colors.gray.gray80};
    height: auto;
    border: none;
    font-size: ${typography.header.h3.fontSize};
    margin-top: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(15)};

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${middleScreen}) {
      width: 100%;
    }
    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${smallScreen}) {
      width: 100%;
      height: auto;
      margin-top: ${theme.spacing(5)};
      margin-bottom: ${theme.spacing(3)};
    }

    &:hover {
      border: none;
      color: ${colors.gray.gray40};
    }
  `,
  screenTitleDescription: css`
    max-width: ${subtitleWidth};
    margin-bottom: ${theme.spacing(8)};
  `,
  selectedBtn: css`
    color: ${colors.kpmg.pacificBlue};
    font-weight: ${typographyFontWeight.bold};
    background-color: ${colors.kpmg.cobaltLight};
  `,
  sidebarStyle: css`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    background-color: ${colors.gray.gray3};
    overflow-y: auto;
    padding-inline: ${theme.spacing(10)};
    max-width: 100%;

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${middleScreen}) {
      padding-inline: ${theme.spacing(5)};
    }

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${smallScreen}) {
      padding-inline: ${theme.spacing(10)};
    }
  `,
  spacing: css`
    margin-bottom: ${theme.spacing(8)};
    margin-top: ${theme.spacing(6)};
  `,
  subtitleDescription: css`
    max-width: ${subtitleWidth};
    color: ${colors.text};
  `,

  textContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
    margin-top: ${theme.spacing(6)};
  `,

  tile: css`
    height: 11.5rem;
  `,
}
