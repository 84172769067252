import { useMemo } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectTenantData } from '@dis/redux/src/tenants/tenantsSelectors'

export const usePersonaDefaultTitles = () => {
  const tenantData = useAppSelector(selectTenantData)

  const personaTitles = useMemo(() => {
    if (tenantData) {
      return [
        tenantData.personatitle1,
        tenantData.personatitle2,
        tenantData.personatitle3,
        tenantData.personatitle4,
        tenantData.personatitle5,
      ]
    }

    return []
  }, [tenantData])

  return { personaTitles }
}
