import { ErrorCode } from '@dis/constants'
import { CentralModalDialogConfiguration } from '@dis/types/src/centralModalDialogTypes'
import { t, tKeys } from '@dis/languages'
import { dispatchedActions } from '@dis/redux'
import { backendErrorCodeMap } from '@dis/utils/src/beErrorCodeMap'
import { reloadApp } from '@dis/utils/src/url'
import { handleMainLogin } from '@dis/utils/src/msalInstance'

const goToLogin = () => {
  dispatchedActions.security.logoutThisDevice()
}

export const apiErrorModals = {
  [ErrorCode.NetworkError]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.modals.networkErrorModal.content),
    hasCrossClose: false,
    primaryButton: {
      btnText: t(tKeys.common.close),
    },
    severity: 'error',
    title: t(tKeys.modals.networkErrorModal.title),
  }),
  [ErrorCode.Unauthorized]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.modals.unauthorizedModal.content),
    hasCrossClose: false,
    primaryButton: {
      btnText: t(tKeys.common.close),
    },
    severity: 'error',
    title: t(tKeys.modals.unauthorizedModal.title),
  }),
  [ErrorCode.Unauthenticated]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.modals.unauthenticatedModal.content),
    crossButton: {
      onClick: handleMainLogin(true),
    },
    hasCrossClose: false,
    primaryButton: {
      btnText: t(tKeys.login.logIn),
      onClick: handleMainLogin(true),
    },
    severity: 'notice',
    title: t(tKeys.modals.unauthenticatedModal.title),
  }),
  [backendErrorCodeMap.tenantDeactivated.code]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.api.beErrorCodes.tenantDeactivated),
    id: backendErrorCodeMap.tenantDeactivated.code,
    primaryButton: {
      btnText: t(tKeys.errorModal.acceptBtn),
    },
    severity: 'error',
    title: t(tKeys.modals.tenantDeactivatedModal.title),
  }),
  [backendErrorCodeMap.userNoPermissionToAnyTenant.code]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.api.beErrorCodes.userNoPermissionToAnyTenant),
    hasCrossClose: false,
    id: backendErrorCodeMap.userNoPermissionToAnyTenant.code,
    primaryButton: {
      btnText: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.reloadButton),
      onClick: reloadApp,
    },
    secondaryButton: {
      btnText: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.logoutButton),
      onClick: goToLogin,
    },
    severity: 'error',
    title: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.title),
  }),
  [backendErrorCodeMap.userNoPermissionToTenant.code]: (): CentralModalDialogConfiguration => ({
    content: t(tKeys.api.beErrorCodes.userNoPermissionToTenant),
    hasCrossClose: false,
    id: backendErrorCodeMap.userNoPermissionToTenant.code,
    primaryButton: {
      btnText: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.reloadButton),
      onClick: reloadApp,
    },
    secondaryButton: {
      btnText: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.logoutButton),
      onClick: goToLogin,
    },
    severity: 'error',
    title: t(tKeys.modals.userHasNoPermissionToAnyTenantModal.title),
  }),
  unknownError: (content?: string): CentralModalDialogConfiguration => ({
    content: content || t(tKeys.errors.default.content),
    hasCrossClose: false,
    severity: 'error',
    title: t(tKeys.api.axios.defaultError.title),
  }),
}
