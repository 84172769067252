import { useCallback, useState, MouseEventHandler } from 'react'

export const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const handleOpen = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(undefined)
  }, [])

  return {
    anchorEl,
    handleClose,
    handleOpen,
    isOpen: !!anchorEl,
  }
}
