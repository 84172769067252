import { ReactElement } from 'react'
import { Tooltip } from '@mui/material'
import { Paragraph } from '@dis/components'
import { styles } from './styles'

interface Props {
  children: ReactElement
  content: string
  title: string
}

export const SixPillarsPopup = ({ content, title, children }: Props) => {
  return (
    <Tooltip
      classes={{ popper: styles.tooltip }}
      arrow
      title={
        <>
          <Paragraph>{title}</Paragraph>
          <Paragraph variant="small" marginTop={3}>
            {content}
          </Paragraph>
        </>
      }>
      {children}
    </Tooltip>
  )
}
