import { useTranslation } from 'react-i18next'
import { DashboardContainer, Paragraph, SvgImage } from '@dis/components'
import { useNavTo } from '@dis/hooks'
import { tKeys } from '@dis/languages'
import { styles } from './styles'
import { sixPillarsContent } from './constants'
import { SixPillarsPopup } from './SixPillarsPopup'

export const SixPillarsPreview = () => {
  const { navigateFactory } = useNavTo()
  const { t } = useTranslation()

  return (
    <div className={styles.backlogSixpillarsWrapper}>
      <DashboardContainer
        title={tKeys.sixPillarsPreview.sixPillars}
        href="https://kpmg.com/xx/en/home/insights/2024/02/the-six-pillars.html"
        target="_blank"
        textButtonTitle={tKeys.sixPillarsPreview.more}>
        <div className={styles.sixPillarsWrapper}>
          {sixPillarsContent.map((pillar) => (
            <SixPillarsPopup key={pillar.id} content={t(pillar.content)} title={t(pillar.title)}>
              <div className={styles.sixPillar}>
                <SvgImage src={pillar.Icon} />
                <Paragraph variant="medium">{t(pillar.title)}</Paragraph>
              </div>
            </SixPillarsPopup>
          ))}
        </div>
      </DashboardContainer>
      <DashboardContainer
        className={styles.backlogLayout}
        title={tKeys.sixPillarsPreview.backlog}
        textButtonTitle={tKeys.sixPillarsPreview.openBacklog}
        navigate={navigateFactory('userStory')}
      />
    </div>
  )
}
