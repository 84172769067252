import { cx } from '@dis/styles'
import { Paragraph } from '@dis/components'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { styles } from '../Input/styles'

export type Props = {
  charactersCount?: number
  isFocused?: boolean
  isLengthEqualsCharacters?: boolean
  isLimitVisible?: boolean
  maxLength?: number
  showLimitOnTop?: boolean
}

export const VisibleLimit = ({
  charactersCount,
  isLimitVisible,
  maxLength,
  isFocused,
  showLimitOnTop = false,
}: Props) => {
  const isRtl = useAppSelector(selectIsLanguageRtl)

  if (!isLimitVisible) return null

  return (
    <div
      className={cx(styles.lengthStylesVisible, {
        [styles.lengthStylesInvisible]: !isLimitVisible || !isFocused,
        [styles.lengthStylesTop]: showLimitOnTop,
        [styles.lengthStylesBottom]: !showLimitOnTop,
        [styles.lengthStylesLeft]: isRtl,
        [styles.lengthStylesRight]: !isRtl,
      })}>
      <Paragraph variant="small">
        {charactersCount}/{maxLength}
      </Paragraph>
    </div>
  )
}
