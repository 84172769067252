import React, { FC } from 'react'
import { FormGroup, TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import { FieldError } from 'react-hook-form'
import { cx } from '@emotion/css'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { styles } from './styles'
import { unsanitize } from '../utils'

export type TextAreaProps = Omit<TextareaAutosizeProps, 'value' | 'autocomplete'> & {
  className?: string
  error?: FieldError
  value?: string | number
}

export const TextArea: FC<TextAreaProps> = ({ className, error, value, ...restProps }) => {
  let enhancedValue = value

  if (enhancedValue === undefined) {
    enhancedValue = ''
  }

  let unsanitizedValue = enhancedValue

  if (typeof enhancedValue === 'string' && enhancedValue) {
    unsanitizedValue = unsanitize(enhancedValue)
  }

  return (
    <FormGroup>
      <>
        <TextareaAutosize
          {...restProps}
          autoComplete="false"
          className={cx(styles.textarea, className)}
          value={unsanitizedValue}
        />
        <ErrorNotice error={error} />
      </>
    </FormGroup>
  )
}
