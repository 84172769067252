import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image80 from '@dis/assets/src/images/newTutorial/80.png'
import image81 from '@dis/assets/src/images/newTutorial/81.png'
import image82 from '@dis/assets/src/images/newTutorial/82.png'
import image83 from '@dis/assets/src/images/newTutorial/83.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const Tenant = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={2} marginBottom={4} variant="h2">
        1.1 Tenant
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        The tenant represents the environment of a customer company that has purchased a license to
        use our software. Within this tenant, users can store and manage their customer journeys,
        providing a dedicated and customizable space for their specific needs and data.
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.1 Creating a new tenant
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        You can create a tenant by clicking on "Create a new tenant" button in the upper part of the
        "All tenants" screen (accessible from "Landing page" by clicking on "Discovery" logo or
        "Home" icon in breadcrumbs).
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image80} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        A modal window will then appear in which you have to define the name of the tenant and its
        description. Click the "Submit" button to confirm tenant creation.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image81} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.2 Editing a tenant
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To edit a tenant, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"All tenants"</strong> screen: click on the item's dropdown menu and choose "Edit"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image82} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Management of tenant"</strong> screen (accessible from upper right menu by clicking
        on "Configuration" option; see detail in 7.1 section).
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.1.3 Deleting a tenant
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Delete a tenant by clicking on the item's dropdown menu within "All tenants" screen and
        choose "Delete".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image83} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
