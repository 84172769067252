import { useCallback } from 'react'

export const useScroll = () => {
  const scrollToComponent = useCallback(
    (componentId: string) => () => {
      const element = document.getElementById(componentId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [],
  )

  return { scrollToComponent }
}
