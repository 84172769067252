import { useCallback } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import { useNavTo } from '@dis/hooks'
import { tKeys } from '@dis/languages'
import { colors } from '@dis/styles'
import { Paragraph, SvgImage } from '@dis/components'
import { styles } from './styles'

type ContentsMapperProps = {
  atlasId?: number
  journeyId?: number
  name: string
  occurrencesCount: number
}

const ContentsCard = ({ name, occurrencesCount, atlasId, journeyId }: ContentsMapperProps) => {
  const { t } = useTranslation()

  const { navigate } = useNavTo()

  const navigateTo = useCallback(() => {
    navigate('journey', { atlasId, journeyId })
  }, [navigate, atlasId, journeyId])

  return (
    <div onClick={navigateTo} tabIndex={0} className={styles.contentCard}>
      <div className={styles.content}>
        <SvgImage color={colors.gray.gray60} src={JourneyIcon} width="1.2rem" height="1.2rem" />
      </div>
      <Stack direction="row">
        <Paragraph variant="medium" marginLeft={2}>
          {name !== '' ? `${name},` : t(tKeys.common.newJourney) + ', '}
        </Paragraph>
        <Paragraph variant="small" color={colors.gray.gray40} alignSelf="center" marginLeft={1}>
          {`${t(tKeys.search.foundJourneys, {
            number: occurrencesCount,
          })}`}
        </Paragraph>
      </Stack>
    </div>
  )
}

export default ContentsCard
