import { FieldError } from 'react-hook-form/dist/types'
import { Paragraph } from '@dis/components'
import { cx } from '@emotion/css'
import { styles } from './styles'

export type Props = {
  className?: string
  error?: FieldError | Error
}

export const ErrorNotice = ({ className, error }: Props) => {
  return error?.message ? (
    <div className={cx(styles.root, className)}>
      <Paragraph>{error?.message}</Paragraph>
    </div>
  ) : (
    <></>
  )
}
