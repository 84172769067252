import DOMPurify from 'dompurify'

const allLinksOpenNewWindow = (node: Element): void => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute('rel', 'noopener noreferrer nofollow')
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute('target') &&
    (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new')
  }
}

export const purifyHtml = (htmlText: string): string => {
  // add a hook to make all DOMPurify'd links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', allLinksOpenNewWindow)

  return DOMPurify.sanitize(htmlText, {
    ADD_ATTR: ['target'],
    FORBID_TAGS: ['span'],
    USE_PROFILES: { html: true },
  })
}
