import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { cx } from '@emotion/css'
import { tKeys } from '@dis/languages'
import { Paragraph } from '@dis/components'
import { styles } from './styles'

export const LoadingSearch = () => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.root, styles.modalBg)}>
      <div className={styles.modalBox}>
        <CircularProgress />
        <Paragraph variant="medium">{t(tKeys.search.searchLoading)}</Paragraph>
      </div>
    </div>
  )
}
