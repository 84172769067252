import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import AtlasIcon from '@dis/assets/src/icons/AtlasIcon.svg'
import FolderIconDark from '@dis/assets/src/icons/FolderIconDark.svg'
import PersonaIcon from '@dis/assets/src/icons/PersonaIcon.svg'
import { DataMapper } from '@dis/modules/src/search/DataMapper'
import { ContentsMapper } from '@dis/modules/src/search/ContentsMapper'
import { selectResults, selectSearchWord } from '@dis/redux/src/search/searchSelectors'
import { useAppSelector } from '@dis/redux'
import { LoadingSearch } from '@dis/modules/src/search/LoadingSearch'
import { colors } from '@dis/styles'
import { tKeys } from '@dis/languages'
import { Header, Screen, SvgImage } from '@dis/components'
import { SearchTypes } from '@dis/types/src/SearchTypes'

export const Search = () => {
  const { t } = useTranslation()

  const results = useAppSelector(selectResults)
  const searchWord = useAppSelector(selectSearchWord)
  const data = [
    {
      data: results?.atlases || [],
      icon: (
        <SvgImage color={colors.gray.gray60} src={AtlasIcon} width="1.125rem" height="1.125rem" />
      ),
      title: t(tKeys.common.atlas),
      type: SearchTypes.Atlas,
    },
    {
      data: results?.folders || [],
      icon: (
        <SvgImage
          color={colors.gray.gray80}
          src={FolderIconDark}
          width="1.125rem"
          height="1.125rem"
        />
      ),
      title: t(tKeys.common.folder),
      type: SearchTypes.Folder,
    },
    {
      data: results?.journeys || [],
      icon: (
        <SvgImage color={colors.gray.gray60} src={JourneyIcon} width="1.125rem" height="1.125rem" />
      ),
      title: t(tKeys.common.journey),
      type: SearchTypes.Journey,
    },
    {
      data: results?.personas || [],
      icon: (
        <SvgImage color={colors.gray.gray40} src={PersonaIcon} width="1.125rem" height="1.125rem" />
      ),
      title: t(tKeys.common.persona),
      type: SearchTypes.Persona,
    },
  ]

  const numberOfResults =
    (results?.atlases?.length || 0) +
    (results?.journeys?.length || 0) +
    (results?.contents?.length || 0) +
    (results?.personas?.length || 0)

  const displayNumberOfResults = useMemo(() => {
    return searchWord
      ? `${t(tKeys.search.foundResults, {
          number: numberOfResults,
        })} "${searchWord}"`
      : t(tKeys.search.searchResults)
  }, [numberOfResults, searchWord, t])

  return (
    <Screen>
      <Header variant="h2" color={colors.gray.gray40}>
        {displayNumberOfResults}
      </Header>
      {results ? (
        <>
          {data?.map((item) => {
            return (
              <div key={item.title}>
                <DataMapper data={item.data} title={item.title} icon={item.icon} type={item.type} />
              </div>
            )
          })}
        </>
      ) : (
        <LoadingSearch />
      )}
      <ContentsMapper data={results?.contents || []} />
    </Screen>
  )
}
