import { AxiosInstances } from './axios'
import { importCapabilityUrl, importExcelToJourneyUrl } from './endpoints'

export const importExcelToJourney = async (
  data: FormData,
  tenantId?: number,
  localeId?: string,
) => {
  try {
    const response = await AxiosInstances.axiosInstance.post(
      importExcelToJourneyUrl({ localeId, tenantId }),
      data,
    )
    return response
  } catch (err: any) {
    console.warn(`An error "${err}" has occurred during importExcel()`)
    throw err
  }
}

export const importCapability = async (data: FormData) => {
  try {
    const response = await AxiosInstances.axiosInstance.post(importCapabilityUrl, data)
    return response
  } catch (err: any) {
    console.warn(`An error "${err}" has occurred during importCapability()`)
    throw err
  }
}
