import { theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  personaItem: css`
    width: 200px;
  `,
  personasWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(6)};
    justify-content: center;
    margin-top: ${theme.spacing(4)};
  `,
}
