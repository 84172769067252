import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image25 from '@dis/assets/src/images/newTutorial/25.png'
import image26 from '@dis/assets/src/images/newTutorial/26.png'
import image27 from '@dis/assets/src/images/newTutorial/27.png'
import image28 from '@dis/assets/src/images/newTutorial/28.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const NewPersona = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.1 Creating a new persona
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>User</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To create a persona, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Landing page"</strong>: click on the "Plus" button (Personas section)
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image25} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"All personas"</strong> screen: click on the "Create a new persona" button
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image26} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all the above cases, a modal window for entering the name of the persona will be
        displayed.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image27} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Subsequently, the "Change profile" screen will appear to complete the detailed specification
        of the given persona.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image28} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
