import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image29 from '@dis/assets/src/images/newTutorial/29.png'
import image30 from '@dis/assets/src/images/newTutorial/30.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const DeletePersona = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.2 Deleting a persona
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (persona must not be contained in any journey)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To delete a persona, click on the item's dropdown menu on "All personas" screen and choose
        "Delete persona".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image29} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        A modal window will then appear within which you have to confirm deletion of the persona.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image30} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
