import { FC } from 'react'
import DefaultPersonIconLarge from '@dis/assets/src/icons/DefaultPersonIconLarge.svg'
import { PromisedImg, SvgImage } from '@dis/components'
import { getFileUrl } from '@dis/api/src/endpoints'
import { cx } from '@emotion/css'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { styles } from './styles'

type Props = {
  className?: string
  image?: number
  priority?: number
  size?: number
}

export const PersonaAvatar: FC<Props> = ({ className, image, priority, size }) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const dimensions = size ? size + 'px' : undefined

  return (
    <div className={cx(className, styles.avatar)}>
      <PromisedImg
        priority={priority}
        url={image ? getFileUrl(tenantId, image) : ''}
        className={styles.photo(size)}>
        <SvgImage src={DefaultPersonIconLarge} width={dimensions} height={dimensions} />
      </PromisedImg>
    </div>
  )
}
