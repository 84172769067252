import { Typography, TypographyProps } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { typography, HeaderVariant, TypographyOmitsForTextComps } from '@dis/styles/src/theme'

interface Props extends Omit<TypographyProps, TypographyOmitsForTextComps> {
  tKey?: string
  tValues?: Record<string, string | number | undefined>
  variant: HeaderVariant
}

/**
 * Custom Typography component for Header with HTML formatters & predefined styles, see FIGMA for more: https://www.figma.com/file/fcurJwqBrHvPv9t47UCx96/Discovery-KPMG?type=design&node-id=37-9496&mode=design&t=GmoSkkVARydKNurU-0
 */
export const Header = ({ children, tKey, tValues, variant, ...rest }: Props) => {
  const { t } = useTranslation()
  const { fontSize, lineHeight } = typography.header[variant]

  return (
    <Typography
      fontWeight={700}
      fontSize={fontSize}
      lineHeight={lineHeight}
      component={variant as any}
      {...rest}>
      {children ?? (tKey && <Trans t={t}>{t(tKey, tValues)}</Trans>)}
    </Typography>
  )
}
