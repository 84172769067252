import React from 'react'
import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Paragraph, PopoverWithAnchor, Table } from '@dis/components'
import { tKeys } from '@dis/languages'
import { TenantList, Tenant } from '@dis/types/src/TenantTypes'
import { colors } from '@dis/styles'
import EditIcon from '@dis/assets/src/icons/EditIcon.svg'
import TrashIcon from '@dis/assets/src/icons/TrashIcon.svg'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { dateHandler } from '@dis/utils'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/types/src/UsersAndRoles'
import { useTenantPage } from '@dis/modules/src/tenant/hooks'
import { styles } from './styles'

type Props = {
  openDeleteTenantModal: (data: Tenant) => VoidFunction
  showEditModal: (data: Tenant) => VoidFunction
  tenants?: TenantList['tenants']
}

export const TableView = ({ tenants, showEditModal, openDeleteTenantModal }: Props) => {
  const { t } = useTranslation()
  const { navigateToLandingPage } = useTenantPage()
  const actualUserRole = useAppSelector(selectUserRole)

  const isRtl = useAppSelector(selectIsLanguageRtl)

  return (
    <Table
      tableBodyClassName={styles.table.tBody}
      isNewDesign
      fullWidth
      idExtractor={(rowData) => rowData.id}
      rowsPerPage={10}
      rows={tenants || []}
      headCols={
        actualUserRole === UserRole.KpmgAdmin
          ? [
              {
                label: t(tKeys.common.id),
              },
              {
                label: t(tKeys.common.name),
              },
              {
                label: t(tKeys.common.description),
              },
              {
                label: t(tKeys.journeysPreview.created),
              },
              {
                label: t(tKeys.administration.usersAndGroups.action),
              },
            ]
          : [
              {
                label: t(tKeys.common.id),
              },
              {
                label: t(tKeys.common.name),
              },
              {
                label: t(tKeys.journeysPreview.created),
              },
            ]
      }
      bodyCols={({ rowData }) => {
        return (
          <>
            <TableCell className={styles.table.cellID} onClick={navigateToLandingPage(rowData)}>
              <Paragraph title={rowData.id} my={1.5}>
                {rowData.id}
              </Paragraph>
            </TableCell>
            <TableCell className={styles.table.cellName} onClick={navigateToLandingPage(rowData)}>
              <Paragraph className={styles.table.name} title={rowData.name} my={1.5}>
                {rowData.name}
              </Paragraph>
            </TableCell>
            {actualUserRole === UserRole.KpmgAdmin && (
              <TableCell
                onClick={navigateToLandingPage(rowData)}
                className={styles.table.cellDescription}>
                <Paragraph
                  className={styles.table.description}
                  title={rowData?.description}
                  my={1.5}>
                  {rowData?.description}
                </Paragraph>
              </TableCell>
            )}
            <TableCell className={styles.table.cellDate} onClick={navigateToLandingPage(rowData)}>
              <Paragraph>{dateHandler(rowData.created, isRtl)}</Paragraph>
            </TableCell>
            {actualUserRole === UserRole.KpmgAdmin && (
              <TableCell className={styles.table.cellPopover}>
                <PopoverWithAnchor
                  menuOnLeft
                  items={[
                    {
                      icon: EditIcon,
                      iconColor: colors.gray.gray80,
                      label: t(tKeys.common.edit),
                      onClick: showEditModal(rowData),
                    },
                    {
                      icon: TrashIcon,
                      iconColor: colors.gray.gray80,
                      isRed: true,
                      label: t(tKeys.common.delete),
                      onClick: openDeleteTenantModal(rowData),
                    },
                  ]}
                />
              </TableCell>
            )}
          </>
        )
      }}
    />
  )
}
