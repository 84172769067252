import { colors, hex2rgba, shadowClasses, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const centralIconTransition = {
  extended: css`
    transition: all ease-out 0.5s;
    &:hover {
      background: ${hex2rgba(colors.text, 0.04)};
    }
  `,
  simple: css`
    transition: all ease-out 1s;
  `,
}

export const atlasFolderJourneyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const centralIcon = css`
  display: flex;
  align-items: center;
  place-content: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 100rem;
  border: 0.5px solid ${colors.gray.gray40};
  transition: all ease-out 1s;
`

export const roundIconsDelete = css`
  bottom: 3.5rem;
  right: 3.5rem;
`
export const roundIconsRefresh = css`
  bottom: 3.5rem;
  left: 3.5rem;
`
export const roundIconsMove = css`
  top: 3.5rem;
  left: 3.5rem;
`
export const roundIconsShare = css`
  bottom: 3.5rem;
  left: 3.5rem;
`
export const roundIconsCopy = css`
  top: 3.5rem;
  right: 3.5rem;
`

export const roundIcons = css`
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
`

export const iconsController = css`
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  margin: 2rem auto 0.5rem auto;

  &:hover ${'.' + centralIconTransition.simple} {
    ${shadowClasses[4]}
    border: 1px solid ${colors.transparent};
  }
  &:hover {
    cursor: pointer;
  }
  &:hover ${'.' + roundIcons} {
    opacity: 1;
  }
`
export const inputBox = css`
  .MuiInputBase-input:focus {
    display: inline-block;
    text-align: start;
  }

  .MuiInputBase-root {
    display: block;
    margin-bottom: 5px;
  }

  .MuiInputBase-input::placeholder {
    opacity: 1;
  }

  .MuiInputBase-input:focus::placeholder {
    color: ${colors.transparent};
  }
`

export const textControllerStyles = {
  atlasNameForRecycleBin: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 9rem;
  `,
  bottomText: css`
    white-space: nowrap;
    margin-left: ${theme.spacing(2)};
  `,
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    & > * {
      max-width: 100%;
    }
  `,
  journeyStatus: css`
    align-items: center;
    display: inline-flex;
  `,
  title: {
    editableTitleStyle: css`
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1.25)};
      margin-bottom: ${theme.spacing(1.25)};
    `,
    root: css`
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 9rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    `,
  },
  upperFirstChar: css`
    text-transform: capitalize;
  `,
}

export const roundIcon = css`
  div {
    display: flex;
  }
`
