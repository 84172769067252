import { ReactNode } from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export interface CentralNotification {
  className?: string
  content?: ReactNode
  hideAtTime: number
  icon?: ReactNode
  title?: string | ReactNode
  uuid: string
}

type ShowNotification = Omit<CentralNotification, 'uuid' | 'hideAtTime'> & {
  displayTimeInMs: number
}

const initialState: Array<CentralNotification> = []

export const centralNotificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    hideAllNotifications: () => initialState,
    hideNotification: (
      state,
      { payload }: PayloadAction<string | { payload: CentralNotification }>,
    ) => {
      const id = typeof payload === 'string' ? payload : payload.payload.uuid

      return state.filter((item) => item.uuid !== id)
    },
    init: () => initialState,
    showNotification: {
      prepare: (payload: ShowNotification): { payload: CentralNotification } => {
        const guid = uuidv4()
        const hideAt = Date.now() + payload.displayTimeInMs

        return {
          payload: {
            ...payload,
            hideAtTime: hideAt,
            uuid: guid,
          },
        }
      },
      reducer: (state, { payload }: PayloadAction<CentralNotification>) => {
        state.push(payload)
      },
    },
  },
})
