import { languages } from '@dis/languages/src/translations/languages'
import CsFlag from '@dis/assets/src/icons/CsFlag.svg'
import FeFlag from '@dis/assets/src/icons/FeFlag.svg'
import EnFlag from '@dis/assets/src/icons/EnFlag.svg'
import ArFlag from '@dis/assets/src/icons/ArFlag.svg'
import DeFlag from '@dis/assets/src/icons/DeFlag.svg'

export type Languages = {
  [K in keyof typeof languages]: K
}

export type LanguagesUnion = keyof typeof languages

export const LANGUAGES = ((): Languages => {
  const ret: Record<string, string> = {}

  Object.keys(languages).forEach((lng) => {
    ret[lng] = lng
  })

  return ret as Languages
})()

export const getFlagIcon = ({ langCode }: { langCode: LanguagesUnion }) => {
  // TODO: fix flags when figma is ready
  switch (langCode) {
    case 'en':
      return EnFlag
    case 'cs':
      return CsFlag
    case 'ar':
      return ArFlag
    case 'de':
      return DeFlag
    case 'fr':
      return FeFlag
    default:
      return ''
  }
}

const changeLanguageCodeList: ReadonlyArray<LanguagesUnion> = ['en', 'cs', 'de', 'fr', 'ar']

export const changeLanguageList = changeLanguageCodeList.map((code) => ({
  label: languages[code],
  value: code,
}))
