import EmpathyIcon from '@dis/assets/src/icons/EmpathyIcon.svg'
import ExpectationsIcon from '@dis/assets/src/icons/ExpectationsIcon.svg'
import IntegrityIcon from '@dis/assets/src/icons/IntegrityIcon.svg'
import PersonalizationIcon from '@dis/assets/src/icons/PersonalizationIcon.svg'
import ResolutionIcon from '@dis/assets/src/icons/ResolutionIcon.svg'
import TimeEffortIcon from '@dis/assets/src/icons/TimeEffortIcon.svg'
import { tKeys } from '@dis/languages'

export const sixPillarsContent = [
  {
    Icon: PersonalizationIcon,
    content: tKeys.sixPillarsPreview.personalizationContent,
    id: 1,
    title: tKeys.sixPillarsPreview.personalization,
  },
  {
    Icon: EmpathyIcon,
    content: tKeys.sixPillarsPreview.empathyContent,
    id: 2,
    title: tKeys.sixPillarsPreview.empathy,
  },
  {
    Icon: ExpectationsIcon,
    content: tKeys.sixPillarsPreview.expectationsContent,
    id: 3,
    title: tKeys.sixPillarsPreview.expectations,
  },
  {
    Icon: ResolutionIcon,
    content: tKeys.sixPillarsPreview.resolutionContent,
    id: 4,
    title: tKeys.sixPillarsPreview.resolution,
  },
  {
    Icon: TimeEffortIcon,
    content: tKeys.sixPillarsPreview.timeAndEffortContent,
    id: 5,
    title: tKeys.sixPillarsPreview.timeAndEffort,
  },
  {
    Icon: IntegrityIcon,
    content: tKeys.sixPillarsPreview.integrityContent,
    id: 6,
    title: tKeys.sixPillarsPreview.integrity,
  },
]
