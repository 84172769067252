import React, { useState } from 'react'
import { Button } from '@mui/material'
import { RouteNames } from '@dis/routing'
import { cx } from '@emotion/css'
import { SvgImage } from '@dis/components'
import { t } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { tutorialData } from './tutorialConstants'
import { styles } from './styles'

type TutorialSidebarContentProps = {
  initial: RouteNames
  onSelectedItemChange: (selectedItem: string) => void
}

type TutorialItem = {
  buttonTitleKey: string
  descriptionKey: string
  goTo: RouteNames
  iconSrc: string
  titleKey: string
}

export const TutorialSidebarContent = ({
  onSelectedItemChange,
  initial,
}: TutorialSidebarContentProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(initial)
  const [init, setInit] = useState<string>(initial)
  const { navigate } = useNavTo()

  const handleClick = (item: TutorialItem) => () => {
    setInit(item.titleKey)
    setSelectedItem(item.titleKey)
    onSelectedItemChange(item.titleKey)
    navigate(item.goTo)
  }

  return (
    <>
      {tutorialData.map((item) => (
        <Button
          key={item.titleKey}
          variant="text"
          color="primary"
          onClick={handleClick(item)}
          className={cx(styles.menuBtn, {
            [styles.selectedBtn]: init === item.goTo || selectedItem === t(item.titleKey),
          })}>
          <SvgImage src={item.iconSrc} />
          {t(item.titleKey)}
        </Button>
      ))}
    </>
  )
}
