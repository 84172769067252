// Direct export from BE code within the comments
// Don't change the content manually! Always update by export from BE code KPMG.Discovery.Model -> Enums -> Error
// Error code in received message is na "camelCase" not in "PascalCase"!
// Keys in translation dictionary have to be in "camelCase" also!
/* eslint-disable sort-keys-fix/sort-keys-fix,sort-keys */
import { CamelCase } from './index'

const BACKEND_ERROR_CODES_ORIGINAL = {
  // Obecné chyby
  /**
   * Obecná chyba serveru.
   */
  ServerGeneralError: 0,

  /**
   * Cesta neexistuje.
   */
  JourneyNotExists: 101,

  /**
   * Atlas neexistuje.
   */
  AtlasNotExists: 102,

  /**
   * Složka neexistuje.
   */
  FolderNotExists: 103,

  /**
   * Persona neexistuje.
   */
  PersonaNotExists: 104,

  /**
   * Userstory neexistuje.
   */
  UserstoryNotExists: 105,

  /**
   * Userstory s id {0} neexistuje.
   */
  UserstoryIdNotExists: 106,

  DuplicateMsgid: 107,

  MissingTenantId: 108,

  TenantNotExists: 109,

  InvalidRequestFormat: 110,

  InvalidTenantName: 111,

  InvalidTenantTemplateId: 112,

  PreviousOrFollowingVersionNotExists: 113,

  InvalidAppVersion: 114,

  TenantDeactivated: 115,

  // Security
  /**
   * Uživatel {0} nemá právo na tenanta {1}.
   */
  UserNoPermissionToTenant: 200,

  /**
   * Uživatel {0} nemá právo na žádného tenanta.
   */
  UserNoPermissionToAnyTenant: 201,

  /**
   * Uživatel {0} je zařazen do několika tenantů.
   */
  UserIncludedToMoreTenants: 202,

  /**
   * Uživatel {0} je zařazen v {1} tenantech, není možné zjistit, jakou má roli.
   */
  UserIncludedToMoreTenantsUnknownRole: 203,

  /**
   * Uživatel {0} v tenantovi {1} nemá nastavenou roli, měl by tedy být zařazen v
   * KPMG AD skupině, ale není.
   */
  UserHasNoRole: 204,

  /**
   * Uživatel {0} nemá přístup do config databáze.
   */
  UserNoPermissionToConfig: 205,

  /**
   * Nepovedlo se ziskat access token pro MS Graph API! Aplikace: {0}.
   */
  NoAccessTokenForMsGraph: 206,

  /**
   * V požadavku chybí tokenid.
   */
  NoTokenInRequest: 207,

  /**
   * Token není validní.
   */
  TokenNotValid: 208,

  /**
   * Pro přístup je vyžadována role: {0}
   */
  AccessDeniedInsufficientUserRole: 209,

  /**
   * Nedostatečná úroveň přístupu k atlasu
   */
  AccessDeniedInsufficientAccessLevel: 210,

  /**
   * Uživatel byl smazán.
   */
  UserWasDeleted: 211,

  UserNotFound: 212,

  UserMultipleAadGroups: 213,

  UserAlreadyExists: 214,

  // REST
  /**
   * Chyba při nahrání souboru: překročena velikost souboru, povolená velikost :
   * {0}, aktuální velikost : {1}.
   */
  UploadExceededFileSize: 300,

  /**
   * Chyba při nahrání souboru: nepovolený typ souboru {0}.
   */
  UploadNotAllowedContentType: 301,

  /**
   * Chyba při nahrání souboru: nepovolená přípona souboru {0}.
   */
  UploadNotAllowedFileExt: 302,

  // Web Socket
  /**
   * Jméno persony "{0}" již existuje.
   */
  PersonaNameExists: 400,

  /**
   * Dokument není validní, documentId : {0}.
   */
  DocumentNotValid: 401,

  /**
   * Import capabilit není validní.
   */
  CapabilitiesImportDataNotValid: 402,

  // Excel import
  /**
   * Chyba při importu z Excelu: neexistuje list "{0}".
   */
  ExcelImportListNotExists: 500,

  /**
   * Chyba při importu z Excelu: chybné ID vrstvy "{0}" v listu "{1}".
   */
  ExcelImportIncorrectLayerId: 501,

  /**
   * Chyba při importu z Excelu: neznámý model vrstvy : {0}.
   */
  ExcelImportUnknownLayerModel: 502,

  /**
   * Chyba při importu z Excelu: neznámý typ vazby : {0}.
   */
  ExcelImportUnknownBindingType: 503,

  /**
   * Chyba při importu z Excelu: neznámý kód "{0}" v listu "{1}" v buňce ["{2}"].
   */
  ExcelImportUnknownCode: 504,

  /**
   * Chyba při importu z Excelu: chyba při čtení buňky {0}.
   */
  ExcelImportErrorReadCell: 505,

  /**
   * Chyba při importu z Excelu: neexistuje hodnota v listu "{0}" v buňce ["{1}"].
   */
  ExcelImportNoValueInCell: 506,

  /**
   * Chyba při importu z Excelu: chybná hodnota v listu "{0}" v buňce ["{1}"].
   */
  ExcelImportIncorrectValueInCell: 507,

  /**
   * Chyba při importu z Excelu: nenumerická hodnota v listu "{0}" v buňce
   * ["{1}"].
   */
  ExcelImportNotNumericValueInCell: 508,

  /**
   * Chyba při importu z Excelu: nenalezena vazba na hodnotu v listu "{0}" v buňce
   * ["{1}"].
   */
  ExcelImportBindingNotFound: 509,

  /**
   * Chyba při importu z Excelu: duplicitní vazba na hodnotu v listu "{0}" v buňce
   * ["{1}"].
   */
  ExcelImportDuplicitBinding: 510,

  // Excel export
  /**
   * Chyba při exportu do Excelu: neznámý kód vrstvy six pillars : {0}.
   */
  UnknownCodeSixPillarsLayer: 520,

  // MS Graph API
  /**
   * Chyba při vytváření uživatele v AAD.
   */
  AadAddUserError: 600,

  /**
   * Chyba při vytváření skupiny v AAD.
   */
  AadCreateGroupError: 601,

  /**
   * Chyba při smazání uživatele v AAD.
   */
  AadDeleteUserError: 602,

  /**
   * Chyba při smazání skupiny v AAD.
   */
  AadDeleteGroupError: 603,

  /**
   * Chyba při odebrání uživatele ze skupiny v AAD.
   */
  AadRemoveUserFromGroupError: 604,

  /**
   * Uživatel {0} již existuje ve skupině.
   */
  AadUserExistsInGroupError: 605,

  AadUserNotFoundInOrganization: 606,

  UnknownAzureActiveDirectoryError: 700,

  UnknownDatabaseError: 701,
} as const
/* eslint-enable */

export type BackendErrorCodesUnion = CamelCase<keyof typeof BACKEND_ERROR_CODES_ORIGINAL>

// Transform to "camelCase" from "PascalCase"
export const BACKEND_ERROR_CODES_ARRAY: ReadonlyArray<BackendErrorCodesUnion> = Object.keys(
  BACKEND_ERROR_CODES_ORIGINAL,
).map((key) => (key.charAt(0).toLowerCase() + key.slice(1)) as BackendErrorCodesUnion)

export type BackendErrorCodeMapValue = {
  code: BackendErrorCodesUnion
  localizedMessage: string
}

export type BackendErrorCodeMap = Record<BackendErrorCodesUnion, BackendErrorCodeMapValue>
