import { useCallback } from 'react'
import { SwitchProps } from '@mui/material'
import { useController, Control } from 'react-hook-form'
import {
  ToggleSwitch as ToggleSwitchComponent,
  type ToggleSwitchProps as ToggleSwitchComponentProps,
} from '@dis/components/src/ToggleSwitch/ToggleSwitch'

export type ToggleSwitchProps = Omit<ToggleSwitchComponentProps, 'value' | 'name' | 'color'> & {
  color?: ToggleSwitchComponentProps['color']
  formControl: Control<any>
  label?: string
  name: string
  shouldUnregister?: boolean
}

export const ToggleSwitch = ({
  formControl,
  shouldUnregister = false,
  name,
  ...restProps
}: ToggleSwitchProps) => {
  const restPropsOnChange = restProps.onChange

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<SwitchProps['onChange']>>(
    (e, value) => {
      onChange(e)
      restPropsOnChange?.(e, value)
    },
    [restPropsOnChange, onChange],
  )

  return (
    <ToggleSwitchComponent
      {...restProps}
      errorObject={error}
      value={value}
      onChange={onChangeWrapper}
    />
  )
}
