import { Pagination as PaginationComponent } from '@dis/components/src/Pagination/Pagination'
import { useTable } from '@dis/context/src/table/useTable'
import { TableProps } from './types'

type Props<RowType> = Pick<TableProps<RowType>, 'paginationClassName'>

export const Pagination = <RowType,>({ paginationClassName }: Props<RowType>) => {
  const { currentPage, maxPage, onPageChange, rowsPerPage, sortedRows } = useTable()

  if (rowsPerPage === undefined || maxPage === 1 || sortedRows.length === 0) return null

  return (
    <PaginationComponent
      className={paginationClassName}
      currentPage={currentPage}
      maxPage={maxPage}
      onPageChange={onPageChange}
    />
  )
}
