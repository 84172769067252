import { Header, Paragraph } from '@dis/components'
import image43 from '@dis/assets/src/images/newTutorial/43.png'
import image44 from '@dis/assets/src/images/newTutorial/44.png'
import image45 from '@dis/assets/src/images/newTutorial/45.png'
import image46 from '@dis/assets/src/images/newTutorial/46.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const AddingPersonaToJourney = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.4 Adding a persona to a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To add a persona to a journey, click on the "Plus" button next to the name and/or
        description of the journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image43} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Subsequently, a modal window will appear with a list of available persons (those defined in
        tenant).
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image44} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        By adding a persona to the journey, a mutual link is created, which is visible within the
        persona medallion on the "All personas" screen.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image45} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Prolinks to connected journeys are available from the detail of the given persona.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image46} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
