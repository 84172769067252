import { colors } from '@dis/styles'
import { css } from '@emotion/css'
import { spin } from './utils'

const animationRingBase = (isAnimated: boolean, animation: string) => css`
  ${isAnimated ? animation : ''};
  border: ${isAnimated ? `3px solid ${colors.text}` : 'none'};
  border-radius: 50%;
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
`

export const styles = {
  animationRing1: (isAnimated: boolean) =>
    animationRingBase(
      isAnimated,
      css`
        animation: ${spin} 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      `,
    ),

  animationRing2: (isAnimated: boolean) =>
    animationRingBase(
      isAnimated,
      css`
        animation: ${spin} 2s cubic-bezier(0, 0.2, 0.8, 1) -0.66s infinite;
      `,
    ),

  animationRing3: (isAnimated: boolean) =>
    animationRingBase(
      isAnimated,
      css`
        animation: ${spin} 2s cubic-bezier(0, 0.2, 0.8, 1) -1.33s infinite;
      `,
    ),
}
