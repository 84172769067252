import { CentralModalDialogConfiguration } from '@dis/types/src/centralModalDialogTypes'
import { t, tKeys } from '@dis/languages'
import { forceLogout } from '@dis/utils/src/msalInstance'
import { dispatchedActions } from '@dis/redux'

export const loggedOutModal = (): CentralModalDialogConfiguration => ({
  content: t(tKeys.api.ws.logout.content),
  primaryButton: {
    btnText: t(tKeys.api.ws.logout.btn),
    onClick: () => {
      forceLogout()
      dispatchedActions.security.logoutThisDevice()
    },
  },
  severity: 'warning',
  title: t(tKeys.api.ws.logout.title),
})
