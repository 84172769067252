import React, { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import { FormControl, Input } from '@dis/form'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { SelectProps } from '@dis/components'
import { UseFormSetValue } from 'react-hook-form'
import { theme } from '@dis/styles'
import { SelectAtlasAndFolder } from '../selectAtlasAndFolder/SelectAtlasAndFolder'

type Props = {
  atlasOptions: SelectProps['options']
  control: FormControl<any>
  folderOptions: SelectProps['options']
  forceTenantId?: number
  refetchAtlasOptions: VoidFunction
  setValue: UseFormSetValue<any>
  tenantSelect?: ReactNode
}

const msg = tKeys.common

export const CreateOrDuplicateJourneyFormPart: FC<Props> = ({
  atlasOptions,
  control,
  folderOptions,
  forceTenantId,
  refetchAtlasOptions,
  setValue,
  tenantSelect,
}) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" gap={theme.spacing(5)} flexDirection="column">
      <Input
        required
        name="name"
        fixedLabel
        variant="outlined"
        hoverUnderline={false}
        label={t(msg.name)}
        formControl={control}
        placeholder={t(msg.enterName)}
      />

      {tenantSelect}

      <SelectAtlasAndFolder
        atlasOptions={atlasOptions}
        control={control}
        folderOptions={folderOptions}
        forceTenantId={forceTenantId}
        refetchAtlasOptions={refetchAtlasOptions}
        setValue={setValue}
      />
    </Box>
  )
}
