import { css } from '@emotion/css'
import { colors } from '@dis/styles'

const outline = css`
  outline-style: solid;
  outline-width: 1px;
  transition: outline-color 0.2s linear 0s;
`

export const styles = {
  disabled: css`
    background-color: ${colors.gray.gray20};

    &:hover {
      cursor: not-allowed;
      background-color: ${colors.gray.gray20};
    }
  `,
  outline: {
    blue: css`
      ${outline};
      outline-color: ${colors.kpmg.pacificBlue};
      &:hover {
        background-color: ${colors.kpmg.pacificBlue};
        svg {
          fill: ${colors.gray.gray0};
        }
      }
    `,
    grey: css`
      ${outline};
      outline-color: ${colors.gray.gray10};

      &:hover {
        outline-color: ${colors.gray.gray80};
      }
    `,
  },
  root: css`
    background-color: ${colors.background.white};
    cursor: pointer;
    border-style: none;

    &:hover {
      background-color: ${colors.background.white};
      transition: outline-color 0.2s linear 0s;
    }
  `,
  transparent: css`
    background-color: ${colors.transparent};

    &:hover {
      background-color: ${colors.transparent};
    }
  `,
}
