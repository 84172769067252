import { yup } from '@dis/form'

export const validationSchema = yup.object({
  AZURE_AUTHORITY_URL: yup.string().url().required(),
  BE_URL: yup.string().required(),
  CLIENT_ID: yup.string().uuid().required(),
  FEATURE_FLAGS: yup.object({
    ARABIC_LANGUAGE: yup.boolean(),
    CONNECTED_CAPABILITIES: yup.boolean(),
    REST_API: yup.boolean(),
  }),
  WEBSOCKET_TIMEOUT: yup.number(),
})
