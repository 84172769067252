import { IconButton, Menu } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import HistoryForward from '@dis/assets/src/icons/HistoryForward.svg'
import HistoryBack from '@dis/assets/src/icons/HistoryBack.svg'
import HistoryIcon from '@dis/assets/src/icons/HistoryIcon.svg'
import ApproveHistoryIcon from '@dis/assets/src/icons/ApproveHistoryIcon.svg'
import DeclineHistoryIcon from '@dis/assets/src/icons/DeclineHistoryIcon.svg'
import { FixedOverlay, SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { cx } from '@emotion/css'
import {
  selectIsChangeRunning,
  selectIsJourneyBackEnable,
  selectIsJourneyForwardEnable,
} from '@dis/redux/src/journeys/journeysSelectors'
import {
  selectIsPersonaBackEnable,
  selectIsPersonaForwardEnable,
} from '@dis/redux/src/personas/personasSelectors'
import { VersionListItems } from './VersionListItems'
import { useRevisionControlVersion } from './useRevisionControlVersion'
import { styles } from './styles'

type RevisionControlProps = {
  isJourney: boolean
  isPersona: boolean
}

export const RevisionControl = ({ isPersona, isJourney }: RevisionControlProps) => {
  const { anchorEl, handleOpen, handleClose } = useAnchor()

  const {
    isVersionListLoading,
    acceptSelectedVersion,
    onClickSetVersion,
    selectedVersionIndex,
    declineSelectedVersion,
    onClickBack,
    onClickForward,
    versions,
  } = useRevisionControlVersion({
    handleCloseForMenuItem: handleClose,
    isJourney,
    isPersona,
    semaphore: Boolean(anchorEl),
  })

  const isRtl = useAppSelector(selectIsLanguageRtl)
  const isPersonaForwardEnable = useAppSelector(selectIsPersonaForwardEnable)
  const isPersonaBackEnable = useAppSelector(selectIsPersonaBackEnable)
  const isJourneyForwardEnable = useAppSelector(selectIsJourneyForwardEnable)
  const isJourneyBackEnable = useAppSelector(selectIsJourneyBackEnable)
  const isJourneyChangeRunning = useAppSelector(selectIsChangeRunning)

  const isForwardEnable = isPersona ? isPersonaForwardEnable : isJourneyForwardEnable
  const isBackEnable = isPersona ? isPersonaBackEnable : isJourneyBackEnable

  return (
    <div
      className={cx(styles.container.root, {
        [styles.container.rtl]: isRtl,
      })}>
      <IconButton
        onClick={onClickBack}
        disabled={!isBackEnable || isJourneyChangeRunning}
        sx={{ padding: 0 }}>
        <SvgImage src={HistoryBack} color={isBackEnable ? colors.gray.gray0 : colors.gray.gray60} />
      </IconButton>

      <IconButton
        disabled={!isBackEnable || isJourneyChangeRunning}
        disableRipple
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{ padding: 0 }}>
        <SvgImage src={HistoryIcon} color={isBackEnable ? colors.gray.gray0 : colors.gray.gray60} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <VersionListItems
          isVersionListLoading={isVersionListLoading}
          versions={versions}
          onClickSetVersion={onClickSetVersion}
          handleClose={handleClose}
        />
      </Menu>

      <IconButton
        onClick={onClickForward}
        disabled={!isForwardEnable || isJourneyChangeRunning}
        sx={{ padding: 0 }}>
        <SvgImage
          src={HistoryForward}
          color={isForwardEnable ? colors.gray.gray0 : colors.gray.gray60}
        />
      </IconButton>

      {selectedVersionIndex !== undefined && (
        <>
          <div className={styles.buttonsContainer}>
            <IconButton className={styles.buttonHistory} onClick={acceptSelectedVersion}>
              <SvgImage color={colors.kpmg.pacificBlue} src={ApproveHistoryIcon} />
            </IconButton>
            <IconButton className={styles.buttonHistory} onClick={declineSelectedVersion}>
              <SvgImage color={colors.semantic.red} src={DeclineHistoryIcon} />
            </IconButton>
          </div>
          <FixedOverlay zIndex={29} />
        </>
      )}
    </div>
  )
}
