import React, { useEffect } from 'react'
import { dispatchedActions } from '@dis/redux'

export const ComponentsLoader = () => {
  useEffect(() => {
    const loader = dispatchedActions.centralModalLoader.showModalLoader()

    return () => {
      dispatchedActions.centralModalLoader.hideModalLoader(loader)
    }
  }, [])
  return <></>
}
