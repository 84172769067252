import React, { FC, SyntheticEvent, useCallback } from 'react'
import { Button, Popover } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { useTranslation } from 'react-i18next'
import SortBy from '@dis/assets/src/icons/SortBy.svg'
import { getTypographyProp, typographyFontWeight } from '@dis/styles/src/theme'
import { Paragraph, Radio, RadioProps, SvgImage } from '@dis/components'
import { tKeys } from '@dis/languages'
import { cx } from '@emotion/css'
import { styles } from './styles'

export type PopoverSortProps = {
  onSortByChange: (value: string | undefined) => void
  options: Array<{
    label: string
    value: string
  }>
  value?: string
}

export const PopoverSort: FC<PopoverSortProps> = ({ onSortByChange, options, value }) => {
  const { t } = useTranslation()

  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const handleClosePopover = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      handleClose()
    },
    [handleClose],
  )

  const handleSortByChange = useCallback<NonNullable<RadioProps['onChange']>>(
    (_, value) => {
      onSortByChange(value)
    },
    [onSortByChange],
  )

  const handleClear = useCallback(() => {
    onSortByChange(undefined)
  }, [onSortByChange])

  return (
    <>
      <Button
        className={cx(styles.mainButton.root, anchorEl && styles.mainButton.btnBorderColor)}
        color="secondary"
        onClick={handleOpen}>
        <span
          className={styles.mainButton.label(
            getTypographyProp('paragraph.body', 'fontSize'),
            typographyFontWeight.semibold,
          )}>
          {t(tKeys.sort.sortBy)}
        </span>
        <span>
          <SvgImage
            src={SortBy}
            className={styles.mainButton.icon(getTypographyProp('header.h2', 'fontSize'))}
          />
        </span>
      </Button>
      <Popover
        className={styles.popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}>
        <div className={styles.popoverChildrenContentSortBy}>
          <Radio
            onChange={handleSortByChange}
            value={value}
            options={options}
            className={styles.popoverChildrenContentSortByRadio}
          />
          {value !== null && value !== undefined && (
            <Paragraph className={styles.clear} onClick={handleClear}>
              {t(tKeys.common.clear)}
            </Paragraph>
          )}
        </div>
      </Popover>
    </>
  )
}
