import React, { ReactNode } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { styles } from './styles'

interface Props {
  children: ReactNode
}

export const FallbackComponent = ({ error }: any) => (
  <div>
    <span className={styles.title}>Oops! An error occured!</span>
    <p>
      <strong>Error:</strong>
      <span className={styles.primary}>{error.toString()}</span>
    </p>
  </div>
)

const ErrorBoundary = ({ children }: Props) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>
)

export default ErrorBoundary
