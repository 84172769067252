import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { Header } from '@dis/components'
import { styles } from './styles'
import { useConfig } from './useConfig'
import { useMobileDetector } from './useMobileDetector'

type Props = {
  children: ReactNode
}

export const AppLoader = ({ children }: Props) => {
  const { t } = useTranslation()

  const { isAppReady } = useConfig()

  useMobileDetector()

  return isAppReady ? (
    <>{children}</>
  ) : (
    <div className={styles.root}>
      <Header variant="h1">{t(tKeys.loadingApp)}</Header>
    </div>
  )
}
