import { useTenantDashboard } from '@dis/hooks/src/useSubscribe'
import { DashboardContainer } from '@dis/components'
import { useNavTo } from '@dis/hooks'
import { tKeys } from '@dis/languages'
import { useAtlasPermissions } from '@dis/hooks/src/useAtlasPermissions'
import { JourneysTable } from './JourneysTable'

export const JourneysPreview = () => {
  const { navigateFactory } = useNavTo()

  const { dashboardData } = useTenantDashboard({
    autoRefetch: {
      create: true,
      delete: true,
      duplicate: true,
      move: true,
    },
  })
  const journeys = dashboardData?.documents || undefined
  const atlasId = dashboardData?.atlases[0]?.id || 0

  const atlasPermissions = useAtlasPermissions({ atlasIds: [atlasId] })
  const canEditAtlas = atlasPermissions[atlasId].canEdit

  return (
    <DashboardContainer
      gridArea="Journeys"
      canCreate={canEditAtlas}
      navigate={navigateFactory('listOfJourneys')}
      sendCreate={navigateFactory('newJourney')}
      isLoader={!journeys}
      textButtonTitle={tKeys.atlasesPreview.showAll}
      title={tKeys.common.journeys}>
      {!!journeys && <JourneysTable journeys={journeys} />}
    </DashboardContainer>
  )
}
