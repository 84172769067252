import { useCallback, useRef, useState } from 'react'
import {
  WsDeleteCategoryMessageModel,
  WsDeleteMessageModel,
  WsDeleteTenantMessageModel,
  WsErrorMessage,
  WsMessageModel,
} from '@dis/types/src/wsModels'
import { IdModelData } from '@dis/types/src/CreateTypes'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { UserType } from '@dis/types/src/UsersAndRoles'
import { Api } from '@dis/api'

type Props = {
  onData?: (data: IdModelData) => void
  onError?: (error: WsErrorMessage['error']) => void
  useInternalErrorHandling?: boolean
}

export const useDelete = (props?: Props) => {
  const [deleteData, setDeleteData] = useState<IdModelData>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<WsErrorMessage['error']>()

  const onData = useRef(props?.onData)
  onData.current = props?.onData

  const onError = useRef(props?.onError)
  onError.current = props?.onError

  const useInternalErrorHandling = useRef(props?.useInternalErrorHandling)
  useInternalErrorHandling.current = props?.useInternalErrorHandling

  const onMessage = useCallback((newData: IdModelData, error?: WsErrorMessage['error']) => {
    if (error) {
      setLoading(false)
      setError(error)
      onError?.current?.(error)
    } else {
      setDeleteData(newData)
      setLoading(false)
      onData?.current?.(newData)
    }
  }, [])

  const sendWrapper = useCallback(
    (message: WsDeleteMessageModel) => {
      setLoading(true)
      setError(undefined)
      Api.sendDeleteMessage(
        {
          ...message,
          useInternalErrorHandling: useInternalErrorHandling.current,
        },
        onMessage,
      )
    },
    [onMessage],
  )

  return {
    deleteData,
    error,
    loading,
    sendWrapper,
  }
}

export const useDeletePersona = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (personaId?: number) => {
      if (personaId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: personaId.toString(),
            model: WsMessageModel.Persona,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteBacklogItem = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (rowId?: number) => {
      if (rowId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: rowId.toString(),
            model: WsMessageModel.UserStory,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteChannel = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (selectedCode?: number) => {
      if (selectedCode && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: selectedCode.toString(),
            model: WsMessageModel.Channel,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteCapability = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (capabilityId?: number) => {
      if (capabilityId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: capabilityId.toString(),
            model: WsMessageModel.SubCapability,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteUser = (props?: Props) => {
  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    ({
      userId,
      userType,
      tenantId,
    }: {
      tenantId?: number
      userId?: number
      userType: UserType
    }) => {
      if (userId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: userId.toString(),
            model: WsMessageModel.User,
          },
          tenantId: tenantId || 0,
        }
        switch (userType) {
          case 'kpmg':
            message.delete.model = WsMessageModel.KpmgUser
            message.tenantId = 0
            sendWrapper(message)
            break
          case 'tenant':
            message.delete.model = WsMessageModel.User

            if (tenantId) {
              message.tenantId = tenantId
              sendWrapper(message)
            } else {
              throw new Error('Missing tenant ID')
            }
            break
        }
      } else {
        throw new Error('Missing user ID')
      }
    },
    [sendWrapper],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteJourneyItem = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (journeyId?: number) => {
      if (journeyId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: journeyId.toString(),
            model: WsMessageModel.Document,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteLocalTemplate = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (templateId?: number) => {
      if (templateId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: templateId.toString(),
            model: WsMessageModel.LocalTemplateDocument,
          },
          tenantId: tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteGlobalTemplate = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (templateId?: number) => {
      if (templateId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: templateId.toString(),
            model: WsMessageModel.GlobalTemplateDocument,
          },
          tenantId: tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteAtlasDetailItem = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (folderId?: number) => {
      if (folderId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: folderId.toString(),
            model: WsMessageModel.Folder,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteAtlas = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (atlasId?: string) => {
      if (atlasId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: atlasId,
            model: WsMessageModel.Atlas,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteGroupMGMT = (props?: Props) => {
  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (groupId: number, tenantId?: number) => {
      if (groupId && tenantId) {
        const message: WsDeleteMessageModel = {
          delete: {
            id: groupId?.toString(),
            model: WsMessageModel.Group,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteTenant = (props?: Props) => {
  const { sendWrapper, loading, deleteData, error } = useDelete(props)

  const sendDelete = useCallback(
    (id: number, isPermanentDelete: boolean) => {
      if (id) {
        const message: WsDeleteTenantMessageModel = {
          delete: {
            data: {
              permanent: isPermanentDelete,
            },
            id: id.toString(),
            model: WsMessageModel.Tenant,
          },
        }

        sendWrapper(message)
      }
    },
    [sendWrapper],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}

export const useDeleteCategory = (props?: Props) => {
  const { sendWrapper, loading, deleteData, error } = useDelete(props)
  const tenantId = useAppSelector(selectSelectedTenantId)

  const sendDelete = useCallback(
    (id: number) => {
      if (id && tenantId) {
        const message: WsDeleteCategoryMessageModel = {
          delete: {
            id: id.toString(),
            model: WsMessageModel.Category,
          },
          tenantId: tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    deleteData,
    error,
    loading,
    sendDelete,
  }
}
