import { useEffect } from 'react'
import { t, tKeys } from '@dis/languages'
import { Input, useForm } from '@dis/form'
import { AtlasFolderInlineChangeForm } from '@dis/types/src/forms'
import { useChangeAtlasFolderJourney } from '@dis/hooks/src/useChange'
import { WsMessageModel } from '@dis/types/src/wsModels'
import { inputBox } from './styles'
import { validationSchema } from './validation'
import { MAX_ATLAS_FOLDER_VISIBLE_LENGTH } from './utils'

type TitleControllerProps = {
  atlasId?: number
  folderId?: number
  maxLength?: number
  name?: string
  refetch?: VoidFunction
  type: 'atlas' | 'folder' | 'journey'
}

export const TitleController = ({
  atlasId,
  folderId,
  maxLength,
  name,
  refetch,
  type,
}: TitleControllerProps) => {
  const { send: sendChangeAtlasFolderJourney } = useChangeAtlasFolderJourney({ onData: refetch })

  const {
    control: formControl,
    reset: formReset,
    handleSubmit,
  } = useForm<AtlasFolderInlineChangeForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    validationSchema,
  })

  const onSubmitChange = handleSubmit(async (data: AtlasFolderInlineChangeForm) => {
    sendChangeAtlasFolderJourney(data)
  })

  useEffect(() => {
    if (type === 'atlas' && atlasId) {
      formReset({
        id: atlasId,
        model: WsMessageModel.Atlas,
        name: name,
      })
    } else if (type === 'folder' && atlasId && folderId) {
      formReset({
        id: folderId,
        model: WsMessageModel.Folder,
        name,
      })
    }
  }, [atlasId, folderId, name, type, formReset])

  let placeholder = ''

  switch (type) {
    case 'atlas': {
      placeholder = t(tKeys.common.newAtlas)
      break
    }

    case 'folder': {
      placeholder = t(tKeys.common.newFolder)
      break
    }
  }

  return (
    <form onSubmit={onSubmitChange} className={inputBox}>
      <Input
        title={name && name?.length > MAX_ATLAS_FOLDER_VISIBLE_LENGTH ? name : undefined}
        formControl={formControl}
        autoComplete="off"
        name="name"
        maxLength={maxLength}
        placeholder={placeholder}
        fontWeight="regular"
        submitOnBlur
        textAlign="center"
        fontSize="paragraph.medium"
        sameStyleForDisabled
      />
    </form>
  )
}
