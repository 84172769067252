import { css } from '@emotion/css'

export const styles = {
  root: css`
    z-index: -1000;
    height: 0;
    width: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
  `,
}
