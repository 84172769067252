import { yup } from '@dis/form'

export const emptyResults = {
  atlases: [],
  contents: [],
  folders: [],
  journeys: [],
  personas: [],
}

export type SearchForm = {
  search: string
}

export const MIN_LENGTH = 3
export const MAX_LENGTH = 50

export const searchBarValidationSchema: yup.SchemaOf<SearchForm> = yup.object({
  search: yup.string().min(MIN_LENGTH).max(MAX_LENGTH).required(),
})
