import { BACKEND_ERROR_CODES_ARRAY, BackendErrorCodeMap } from '@dis/types/src/BackendErrorCodeList'
import { tKeys, t } from '@dis/languages'

export const backendErrorCodeMap: BackendErrorCodeMap = <BackendErrorCodeMap>{}

BACKEND_ERROR_CODES_ARRAY.forEach((stringCode) => {
  const data = {
    code: stringCode,
    get localizedMessage() {
      const tKeysBeErrorCodesMap = tKeys.api.beErrorCodes

      const translateKey = tKeysBeErrorCodesMap[stringCode]

      return translateKey ? t(translateKey) : ''
    },
  }

  backendErrorCodeMap[stringCode] = data
})
