import { css } from '@emotion/css'
import { colors } from '@dis/styles'

export const styles = {
  textarea: css`
    '::placeholder': {
      color: ${colors.gray.gray100};
    }
  `,
}
