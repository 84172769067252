import { WsMessageModel } from '@dis/types/src/wsModels'
import { HttpStatus } from '@dis/types/src/api'
import { dispatchedActions } from '@dis/redux'
import { apiErrorModals } from '@dis/modals/src/apiErrorModals'
import { ErrorCode } from '@dis/constants'

export const countHttpStatus = (httpNumericStatus = -1): HttpStatus => {
  return {
    CLIENT_ERROR:
      (httpNumericStatus === 400 || httpNumericStatus >= 402) && httpNumericStatus < 500,
    NETWORK_ERROR: httpNumericStatus < 0,
    OK: (httpNumericStatus >= 200 && httpNumericStatus < 300) || httpNumericStatus === 304,
    SERVER_ERROR: httpNumericStatus >= 500,
    UNAUTHENTICATED: httpNumericStatus === 401,
    UNAUTHORIZED: httpNumericStatus === 403,
  }
}

export const getTopic = ({
  model,
  id,
  tenantId,
}: {
  id?: string
  model?: WsMessageModel
  tenantId: number
}) => {
  if (model === WsMessageModel.KpmgUserTenants || model === WsMessageModel.CategoryList) {
    return model
  }

  return `${model}/${tenantId}/${id}/`
}

export const processError = (httpStatus: HttpStatus, unknownErrorData?: string) => {
  if (httpStatus.NETWORK_ERROR) {
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    dispatchedActions.centralModalDialog.showModalDialog(apiErrorModals[ErrorCode.NetworkError]())
  }
  // 401 - token/login expired
  else if (httpStatus.UNAUTHENTICATED) {
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    dispatchedActions.centralModalDialog.showModalDialog(
      apiErrorModals[ErrorCode.Unauthenticated](),
    )
  }
  // 403 - missing permission
  else if (httpStatus.UNAUTHORIZED) {
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    dispatchedActions.centralModalDialog.showModalDialog(apiErrorModals[ErrorCode.Unauthorized]())
  }
  // Unknown error
  else if (!httpStatus.OK && !httpStatus.CLIENT_ERROR && !httpStatus.SERVER_ERROR) {
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    dispatchedActions.centralModalDialog.showModalDialog(
      apiErrorModals.unknownError(unknownErrorData),
    )
  }
}
