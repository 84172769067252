import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { handleMainLogin } from '@dis/utils/src/msalInstance'

export const SignInButton = () => {
  const { t } = useTranslation()

  return (
    <Button className="ml-auto" color="secondary" onClick={handleMainLogin(false)}>
      {t(tKeys.login.logIn)}
    </Button>
  )
}
