import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { Export, ids } from '../tutorialConstants'
import { ExportToPdf } from './ExportToPdf'
import { ExportToXLSX } from './ExportToXLSX'
import { ExportToXML } from './ExportToXML'
import { ImportFromXLSX } from './ImportFromXLSX'
import { useScroll } from '../useScroll'

const msg = tKeys.tutorials.exportImport

export const TutorialExportImport = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}
          </Button>
          <TutorialSidebarContent
            initial={ids.exportImport}
            onSelectedItemChange={(exportImport) => {
              return exportImport
            }}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader header={t(msg.header)} />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Export.ExportToPdf)}>
                1.1 Export to PDF
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Export.ExportToXLSX)}
                className={styles.menuText}>
                1.2 Export to XLSX
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Export.ExportToXML)}
                className={styles.menuText}>
                1.3 Export to XML
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Export.ImportFromXLSX)}
                className={styles.menuText}>
                1.4 Import from XLSX
              </ListItem>
            </List>
            <ExportToPdf />
            <ExportToXLSX />
            <ExportToXML />
            <ImportFromXLSX />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
