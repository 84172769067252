import { useCallback } from 'react'
import { Modal as MuiModal, Card, ModalProps } from '@mui/material'
import { cx } from '@emotion/css'
import { DISALLOW_DND } from '@dis/constants'
import { ModalDialogProps } from '@dis/types/src/ModalDialogTypes'
import { ModalHeader } from './ModalHeader'
import { ModalFooter } from './ModalFooter'
import { styles } from './ModalStyles'

export const Modal = ({
  alignButtons = 'end',
  children,
  className = '',
  disableEscapeKeyDown = false,
  hasCrossClose = true,
  hasFooter = true,
  hasOwnBodySpacing,
  headerContent,
  height = 'auto',
  icon,
  isDisabled,
  isHeightFixed = false,
  isOpen = false,
  onCrossBtnClick = () => undefined,
  onInputClick,
  onModalDestroyed,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  ownFooter,
  textInFooter,
  primaryBtnFullWidth,
  primaryBtnText,
  secondaryBtnText,
  isSecondaryBtnInput = false,
  title,
  tooltip,
  width = 'normal',
  zIndex = undefined,
}: ModalDialogProps) => {
  const onClose = useCallback<NonNullable<ModalProps['onClose']>>(
    (event, reason) => {
      if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
        if (!disableEscapeKeyDown) {
          onCrossBtnClick?.()
        }
      } else {
        onModalDestroyed?.()
      }
    },
    [onModalDestroyed, disableEscapeKeyDown, onCrossBtnClick],
  )

  return (
    <MuiModal
      onClose={onClose}
      open={isOpen}
      componentsProps={{ backdrop: { className: DISALLOW_DND } }}
      className={cx(styles.root, className)}
      sx={{ zIndex }}>
      <Card
        className={cx(styles.cardContainer.root, DISALLOW_DND, {
          [styles.cardContainer.normal]: width === 'normal',
          [styles.cardContainer.large]: width === 'large',
        })}>
        <ModalHeader
          hasCrossClose={hasCrossClose}
          headerContent={headerContent}
          onCrossBtnClick={onCrossBtnClick}
          title={title}
          tooltip={tooltip}
        />

        <div
          className={cx(styles.modalBody.root, DISALLOW_DND, {
            [styles.modalBody.defaultSpacing]: !hasOwnBodySpacing,
            [styles.modalBody.normal(isHeightFixed)]: height === 'normal',
            [styles.modalBody.large(isHeightFixed)]: height === 'large',
            [styles.modalBody.xLarge(isHeightFixed)]: height === 'xLarge',
          })}>
          {children}
        </div>

        {hasFooter && (
          <ModalFooter
            alignButtons={alignButtons}
            isDisabled={isDisabled}
            icon={icon}
            isSecondaryBtnInput={isSecondaryBtnInput}
            onInputClick={onInputClick}
            onPrimaryBtnClick={onPrimaryBtnClick}
            onSecondaryBtnClick={onSecondaryBtnClick}
            ownFooter={ownFooter}
            textInFooter={textInFooter}
            primaryBtnText={primaryBtnText}
            primaryBtnFullWidth={primaryBtnFullWidth}
            secondaryBtnText={secondaryBtnText}
          />
        )}
      </Card>
    </MuiModal>
  )
}
