import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type PersonasReducerState = {
  isBrowserOnline: boolean
  isRestApi: boolean
  isWebsocketConnected?: boolean // Keep 3-state type
  websocketConnectionId?: string
  websocketTimeout: number
}

const initialState: PersonasReducerState = {
  isBrowserOnline: true,
  isRestApi: false,
  isWebsocketConnected: undefined,
  websocketConnectionId: undefined,
  websocketTimeout: 3600000,
}

export const apiSlice = createSlice({
  initialState,
  name: 'api',
  reducers: {
    setConnectionId: (
      state,
      action: PayloadAction<PersonasReducerState['websocketConnectionId']>,
    ) => {
      state.websocketConnectionId = action.payload
    },
    setIsBrowserOnline: (state, { payload }: PayloadAction<boolean>) => {
      state.isBrowserOnline = payload
    },
    setIsWebsocketConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.isWebsocketConnected = payload
    },
    setRestApi: (state, { payload }: PayloadAction<boolean>) => {
      state.isRestApi = payload
    },
    setWebsocketTimeout: (state, { payload }: PayloadAction<number | undefined>) => {
      if (payload && payload > 0) {
        state.websocketTimeout = payload
      }
    },
  },
})
