import { FC } from 'react'
import { cx } from '@dis/styles'
import { ReactSVG } from 'react-svg'
import { styles } from './styles'

type SvgImageProps = {
  className?: string
  // TODO: make color prop mandatory
  color?: string
  height?: string
  src: string
  width?: string
}

export const SvgImage: FC<SvgImageProps> = ({ className, height, src, width, color }) => {
  return <ReactSVG src={src} className={cx([styles.container(color, width, height), className])} />
}
