import { Button, ButtonProps } from '@mui/material'
import { NotificationModalProps, Severity } from '@dis/types/src/NotificationModalDialogTypes'
import { styles } from './NotificationModalStyles'

type NotificationModalFooterProps = Pick<
  NotificationModalProps,
  'onPrimaryBtnClick' | 'primaryBtnText' | 'onSecondaryBtnClick' | 'secondaryBtnText' | 'severity'
>

const severityColorDic: Record<Severity, ButtonProps['color']> = {
  error: 'error',
  normal: 'error',
  notice: 'primary',
  success: 'success',
  warning: 'error',
}

export const NotificationModalFooter = ({
  severity,
  secondaryBtnText,
  primaryBtnText,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
}: NotificationModalFooterProps) => {
  const primaryBtnColor = severityColorDic[severity || 'normal']

  return (
    <div className={styles.footerContainer}>
      {!!secondaryBtnText && (
        <Button
          variant="contained"
          color="secondary"
          className={styles.buttons.common}
          onClick={onSecondaryBtnClick}>
          {secondaryBtnText}
        </Button>
      )}

      {!!primaryBtnText && (
        <Button
          variant="contained"
          data-testid="notification-modal-primary-btn"
          color={primaryBtnColor}
          className={styles.buttons.common}
          onClick={onPrimaryBtnClick}>
          {primaryBtnText}
        </Button>
      )}
    </div>
  )
}
