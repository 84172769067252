import { Header, Paragraph } from '@dis/components'
import image61 from '@dis/assets/src/images/newTutorial/61.png'
import image62 from '@dis/assets/src/images/newTutorial/62.png'
import image63 from '@dis/assets/src/images/newTutorial/63.png'
import image64 from '@dis/assets/src/images/newTutorial/64.png'
import image65 from '@dis/assets/src/images/newTutorial/65.png'
import image66 from '@dis/assets/src/images/newTutorial/66.png'
import image67 from '@dis/assets/src/images/newTutorial/67.png'
import image68 from '@dis/assets/src/images/newTutorial/68.png'
import image69 from '@dis/assets/src/images/newTutorial/69.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const BaseLayers = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.2 Settings associated with base layers
      </Header>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.1 Introducing the types of base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Phase</strong>
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Refers to the broad stages of a customer's journey, such as Awareness, Consideration,
        Purchase, Retention, and Advocacy.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Subphase</strong>
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Each phase is further broken down into subphases. For example, the Purchase phase might
        include subphases like Selection, Transaction, and Confirmation.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>Touchpoints</strong>
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Each subphase is further broken down into touchpoints. These are specific interactions
        between the customer and the business, like visiting a website, calling customer service, or
        receiving a product.
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.2 Adding new base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can add a new phase/subphase/touchpoint to a journey by hovering over and clicking on
        the plus button.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image61} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.3 Naming base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To name a phase/subphase/touchpoint, click on the predefined name and rewrite it.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image62} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.4 Moving base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To move a phase/subphase/touchpoint, click on an arrow on the side of the
        phase/subphase/touchpoint to move it either to the left or right side. You can also use the
        drag&drop function for the same operation.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image63} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The second way to move a phase/subphase/touchpoint is through the "Journey Management"
        sidebar, the "Journey structure" option. Here you can change the order of items based on
        "Drag and drop" activity.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image64} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.5 Hiding/Unhiding base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can hide a phase/subphase/touchpoint by hovering over and clicking on the "Lock" icon
        placed next to the name of the phase/subphase/touchpoint.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image65} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The second way to hide a phase/subphase/touchpoint is through the "Journey Management"
        sidebar, the "Journey structure" option. Here you can hide the specific layer based on the
        same principle, by clicking on the "Lock" icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image66} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The only way to unhide the given layer is to click the "Unlock" icon in the sidebar.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image67} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In addition, the lock icon for a given layer means, that if you use scrolling, the layer
        will continue to be seen in the context of additional layers.
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.6 Deleting base layers
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can delete a phase/subphase/touchpoint by hovering over and clicking on the "Red cross"
        icon placed on the left upper part of the phase/subphase/touchpoint.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image68} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.2.7 Setting the Moment of Truth at the touchpoint
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can add a moment of truth to a touchpoint by hovering over a touchpoint and clicking on
        a star icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image69} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
