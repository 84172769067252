import { css } from '@emotion/css'
import { colors, hex2rgba, theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  newDesign: css`
    border-collapse: separate;
    border: solid 1px ${colors.gray.gray10};
    border-radius: 8px;

    thead {
      tr {
        th {
          background-color: ${colors.gray.gray3};
          padding: ${theme.spacing(2)} ${theme.spacing(2)};

          &:first-child {
            border-top-left-radius: 8px;
            border-collapse: collapse;
            padding-left: ${theme.spacing(6)};
          }

          &:last-child {
            border-collapse: collapse;
            border-top-right-radius: 8px;
            padding-right: ${theme.spacing(6)};
          }

          .MuiTableSortLabel-root {
            font-weight: ${typographyFontWeight.bold};
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            background-color: ${hex2rgba(colors.kpmg.pacificBlue, 0.06)};
          }
        }

        &:last-child {
          td {
            border-bottom: none;

            &:first-child {
              border-collapse: collapse;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-collapse: collapse;
              border-bottom-right-radius: 8px;
            }
          }
        }

        td {
          border-bottom: solid 1px ${colors.gray.gray10};
          padding: ${theme.spacing(1)} ${theme.spacing(2)};
          text-align: inherit;
          &:first-child {
            padding-left: ${theme.spacing(6)};
          }

          &:last-child {
            padding-right: ${theme.spacing(6)};
          }
        }
      }
    }
  `,
  table: {
    fullWidth: css`
      width: 100%;
    `,
    root: css`
      width: auto;
    `,
  },
  tableHead: {
    sortIcon: css`
      transform: none !important;
    `,
    sortLabel: css`
      &.Mui-active .MuiSvgIcon-root {
        color: ${colors.text};
      }

      .MuiTableSortLabel-icon {
        color: ${colors.gray.gray40};
      }
    `,
    tableCell: css`
      text-align: inherit;
    `,
  },
}
