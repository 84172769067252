import { Header, Paragraph } from '@dis/components'
import image18 from '@dis/assets/src/images/newTutorial/18.png'
import image19 from '@dis/assets/src/images/newTutorial/19.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const CapabilityManagement = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.2 Tenant User Capability Management (Connected Capabilities)
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To view capabilities, sub-capabilities and user sub-capabilities based on the sector and
        default language of the active tenant, click on the upper right menu and choose
        "Configuration/Connected capabilities" option. It is not possible to edit global
        capabilities on this screen.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image18} width="600" alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={10} variant="medium" className={styles.subtitleDescription}>
        <img src={image19} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
