import { styles as popoverFilterStyles } from '@dis/components/src/PopoverFilter/styles'
import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  clear: css`
    color: ${colors.gray.gray40};
    cursor: pointer;
    margin-top: ${theme.spacing(2)};
  `,
  mainButton: popoverFilterStyles.mainButton,
  popover: css`
    .MuiPopover-paper {
      display: flex;
      flex-direction: column;
      margin-top: ${theme.spacing(1)};
    }
  `,
  popoverChildrenContentSortBy: css`
    padding-top: ${theme.spacing(6)};
    padding-inline: ${theme.spacing(5)};
    padding-bottom: ${theme.spacing(8)};
  `,
  popoverChildrenContentSortByRadio: css`
    display: flex;
    flex-direction: column;
  `,
}
