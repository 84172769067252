import { Header, Paragraph } from '@dis/components'
import image48 from '@dis/assets/src/images/newTutorial/48.png'
import image49 from '@dis/assets/src/images/newTutorial/49.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const AddingPersonaToJourneyLayer = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.6 Adding a persona to a journey layer
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To add a persona to a layer, go in the dropdown menu and click on the "Add persona" option.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image48} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Subsequently, a modal window will appear with a list of available persons (those added to
        the journey). Only one persona can be selected for a given layer.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image49} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
