import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewEnum } from '@dis/types/src/GeneralTypes'

type AtlasesReducerState = {
  atlasDisplayType: ViewEnum
  selectedAtlasId?: number
  selectedAtlasName?: string
}

const initialState: AtlasesReducerState = {
  atlasDisplayType: ViewEnum.Table,
  selectedAtlasId: undefined,
  selectedAtlasName: undefined,
}

export const atlasesSlice = createSlice({
  initialState,
  name: 'atlases',
  reducers: {
    init: () => initialState,
    setAtlasDisplayType: (state: AtlasesReducerState, action: PayloadAction<ViewEnum>) => {
      state.atlasDisplayType = action.payload
    },
    setSelectedAtlasId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedAtlasId = action.payload
    },
    setSelectedAtlasName: (state, action: PayloadAction<string | undefined>) => {
      state.selectedAtlasName = action.payload
    },
  },
})
