import { colors, shadowClasses, shadows, theme } from '@dis/styles'
import { css } from '@emotion/css'

const journeyIcon = css`
  box-shadow: none;
  width: 1.5rem;
  height: 1.5rem;
`
export const styles = {
  content: journeyIcon,
  contentCard: css`
    cursor: pointer;
    display: flex;
    &:hover {
      ${'.' + journeyIcon} {
        ${shadowClasses['5']};
        border-radius: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
  contentsCardWrapper: css`
    padding-bottom: ${theme.spacing(2)};
  `,
  contentsMapperContainer: css`
    margin-bottom: ${theme.spacing(20)};
  `,
  dataMapperContainer: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  greyBorder: css`
    border: 1px solid ${colors.gray.gray20};
  `,
  modalBg: css`
    justify-content: center;
    align-items: center;
    background: rgba(33, 33, 33, 0.4);
    pointer-events: auto;
    position: fixed;
    z-index: 1200;
    inset: 0px;
    display: flex;
  `,
  modalBox: css`
    height: 58px;
    min-width: 133px;
    display: flex;
    padding: 15px;
    background-color: #fff;
    border-radius: 7.5px;
    box-shadow: 0 0 30px ${colors.text};
    align-items: center;
  `,
  nameContainer: css`
    color: ${colors.gray.gray100};
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  root: css`
    .MuiCircularProgress-root {
      height: 28px !important;
      width: 28px !important;
      color: #4a90e2;
      margin-right: 8px;
    }
  `,
  roundContainer: css`
    border-radius: 4rem;
    width: 5rem;
    height: 5rem;
    display: grid;
    justify-content: center;
    align-content: center;
    &:hover {
      box-shadow: ${shadows[5]};
      cursor: pointer;
    }
  `,
  roundContainerWrapper: css`
    display: flex;
    justify-content: center;
  `,
  titleContainer: css`
    color: ${colors.gray.gray40};
    text-align: center;
  `,
}
