import { css } from '@emotion/css'
import { colors } from '@dis/styles'

export const styles = {
  mainWrapper: css`
    /* This is all just for sticky positioning supporting RTL and LTR layouts */
    justify-content: space-between;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    /* Fancy scrollbars for JourneyGrid */
  `,
  root: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: ${colors.background.white};
  `,
}
