import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image89 from '@dis/assets/src/images/newTutorial/89.png'
import image90 from '@dis/assets/src/images/newTutorial/90.png'
import image91 from '@dis/assets/src/images/newTutorial/91.png'
import image92 from '@dis/assets/src/images/newTutorial/92.png'
import image93 from '@dis/assets/src/images/newTutorial/93.png'
import image94 from '@dis/assets/src/images/newTutorial/94.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const Folder = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.3 Folder
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The folder represents a subfolder of above mentioned atlas. Users can create and organize
        their journeys within these folders to maintain a structured and easily accessible
        arrangement.
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.1 Creating a folder
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        You can create a folder by clicking on "Add new Folder" button in the upper part of the
        "List of folders" screen (accessible from "Landing page" by clicking on "Show all" button in
        "Atlas" section and choosing the specific atlas).
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image89} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        A modal window will then appear in which you have to define the name of the folder. Click
        the "Submit" button to confirm the name of the folder.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image90} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.2 Editing a folder
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Edit a folder by hovering over its title.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image91} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.3 Moving a folder
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Move a folder by hovering over the folder icon and clicking on "Move". Choose the
        destination atlas in which you want to move the folder to and click on "Submit" button.
      </Paragraph>
      <div>
        <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
          <img src={image92} alt="Settings Discovery" />
        </Paragraph>
        <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
          <img src={image93} alt="Settings Discovery" />
        </Paragraph>
      </div>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.3.4 Deleting a folder
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Delete a folder by clicking on the left upper "Red cross" icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image94} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
