/* MUI default theme setup
    - for more info follow https://v3.mui.com/customization/themes/ and https://mui.com/material-ui/customization/default-theme/
*/

import { createTheme, responsiveFontSizes } from '@mui/material'

import get from 'lodash/get'
import { colors } from './colors'
import { shadows } from './boxShadows'

// TODO generate from "typography" obj
export type TypographyKeys =
  | 'header.h1'
  | 'header.h2'
  | 'header.h3'
  | 'header.h4'
  | 'paragraph.large'
  | 'paragraph.medium'
  | 'paragraph.body'
  | 'paragraph.small'
  | 'paragraph.tiny'

/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
export const typography = {
  header: {
    h1: {
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
    h5: {
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
    },
  },
  paragraph: {
    large: {
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
    },
    medium: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    small: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    tiny: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
  },
} as const
/* eslint-enable */

export const getTypographyProp = (
  variant: TypographyKeys,
  property: 'fontSize' | 'lineHeight',
): string => {
  return get(typography, variant)[property]
}

/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
export const typographyFontWeight = {
  regular: 400,
  semibold: 600,
  bold: 700,
} as const
/* eslint-enable */

export type ParagraphVariant = keyof typeof typography.paragraph
export type HeaderVariant = keyof typeof typography.header
export type TypographyFontWeight = keyof typeof typographyFontWeight

export type TypographyOmitsForTextComps = 'variant' | 'fontWeight' | 'fontSize' | 'lineHeight'

export type CheckboxLabelPlacement = 'top' | 'bottom' | 'end' | 'start'

const spacing = (factor: number) => `${0.25 * factor}rem`

const generateThemeLocal = (rtl: boolean | undefined) => {
  return createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          // Styles applied to the root element if variant="contained" and color="error".
          containedError: {
            '&:disabled': {
              backgroundColor: colors.buttons.redDisabled,
              color: colors.gray.gray0,
            },
            '&:hover': {
              backgroundColor: colors.buttons.redHover,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.redActive,
            },
            backgroundColor: colors.semantic.red,
            color: colors.gray.gray0,
          },

          // Styles applied to the root element if variant="contained" and color="primary".
          containedPrimary: {
            '&:disabled': {
              backgroundColor: colors.kpmg.lightBlue,
              color: colors.gray.gray0,
            },
            '&:hover': {
              backgroundColor: colors.kpmg.blue,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.blueActive,
              color: colors.gray.gray0,
            },
            backgroundColor: colors.kpmg.pacificBlue,
          },

          // Styles applied to the root element if variant="contained" and color="secondary".
          containedSecondary: {
            '&:disabled': {
              backgroundColor: colors.gray.gray3,
              color: colors.gray.gray20,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray10,
            },
            '&:hover:active': {
              backgroundColor: colors.gray.gray20,
            },
            backgroundColor: colors.gray.gray5,
            color: colors.text,
          },

          // Styles applied to the root element if variant="contained" and color="success".
          containedSuccess: {
            '&:disabled': {
              backgroundColor: colors.buttons.greenDisabled,
              color: colors.gray.gray0,
            },
            '&:hover': {
              backgroundColor: colors.buttons.greenHover,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.greenActive,
            },
            backgroundColor: colors.semantic.green,
            color: colors.gray.gray0,
          },

          // Styles applied to the root element if variant="outlined" and color="error".
          outlinedError: {
            '&:disabled': {
              border: `1px solid ${colors.semantic.redLight}`,
              color: colors.semantic.redLight,
            },
            '&:hover': {
              backgroundColor: colors.semantic.red,
              color: colors.gray.gray0,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.redActive,
              color: colors.gray.gray0,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.semantic.red}`,
            color: colors.semantic.red,
          },

          // Styles applied to the root element if variant="outlined" and color="primary".
          outlinedPrimary: {
            '&:disabled': {
              border: `1px solid ${colors.kpmg.lightBlue}`,
              color: colors.kpmg.lightBlue,
            },
            '&:hover': {
              backgroundColor: colors.kpmg.pacificBlue,
              color: colors.gray.gray0,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.blueActive,
              color: colors.gray.gray0,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.kpmg.pacificBlue}`,
            color: colors.kpmg.pacificBlue,
          },

          // Styles applied to the root element if variant="outlined" and color="secondary".
          outlinedSecondary: {
            '&:disabled': {
              border: `1px solid ${colors.gray.gray20}`,
              color: colors.gray.gray20,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray80,
              color: colors.gray.gray0,
            },
            '&:hover:active': {
              backgroundColor: colors.gray.gray100,
              color: colors.gray.gray0,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.gray.gray80}`,
            color: colors.gray.gray80,
          },

          // Styles applied to the root element if variant="outlined" and color="success".
          outlinedSuccess: {
            '&:disabled': {
              border: `1px solid ${colors.semantic.greenLight}`,
              color: colors.semantic.greenLight,
            },
            '&:hover': {
              backgroundColor: colors.semantic.green,
              color: colors.gray.gray0,
            },
            '&:hover:active': {
              backgroundColor: colors.buttons.greenActive,
              color: colors.gray.gray0,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.semantic.green}`,
            color: colors.semantic.green,
          },

          root: {
            borderRadius: '6.25rem',
            boxShadow: 'none',
            fontSize: typography.paragraph.body.fontSize,
            fontWeight: typographyFontWeight.bold,
            height: '2.75rem',
            lineHeight: typography.paragraph.body.lineHeight,
            padding: '0 1.5rem',
            textTransform: 'none',
          },

          // Fix for RTL languages
          startIcon: {
            marginInlineEnd: '8px',
            marginInlineStart: '-4px',
          },

          // Styles applied to the root element if variant="text" and color="error".
          textError: {
            '&:active': {
              border: `1px solid ${colors.semantic.red}`,
            },
            '&:disabled': {
              color: colors.semantic.redLight,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray3,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.transparent}`,
            color: colors.semantic.red,
          },

          // Styles applied to the root element if variant="text" and color="primary".
          textPrimary: {
            '&:active': {
              border: `1px solid ${colors.kpmg.pacificBlue}`,
            },
            '&:disabled': {
              backgroundColor: 'none',
              border: `1px solid ${colors.transparent}`,
              color: colors.kpmg.lightBlue,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray3,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.transparent}`,
            color: colors.kpmg.pacificBlue,
          },

          // Styles applied to the root element if variant="text" and color="secondary".
          textSecondary: {
            '&:active': {
              border: `1px solid ${colors.gray.gray80}`,
            },
            '&:disabled': {
              backgroundColor: 'none',
              border: `1px solid ${colors.transparent}`,
              color: colors.gray.gray20,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray3,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.transparent}`,
            color: colors.text,
          },
          // Styles applied to the root element if variant="text" and color="success".
          textSuccess: {
            '&:active': {
              border: `1px solid ${colors.semantic.green}`,
            },
            '&:disabled': {
              color: colors.semantic.greenLight,
            },
            '&:hover': {
              backgroundColor: colors.gray.gray3,
            },
            backgroundColor: 'none',
            border: `1px solid ${colors.transparent}`,
            color: colors.semantic.green,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: shadows[16],
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          focusHighlight: {
            backgroundColor: `${colors.transparent}`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '12px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: 'Open Sans',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          asterisk: {
            color: colors.semantic.red,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: colors.semantic.red,
          },
          root: {
            color: colors.text,
            fontSize: typography.paragraph.body.fontSize,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            '::placeholder': {
              color: colors.gray.gray40,
              opacity: 1,
              textOverflow: 'ellipsis !important',
            },
            fontSize: typography.paragraph.medium.fontSize,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: colors.semantic.red,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '.MuiMenuItem-root.Mui-selected': {
              backgroundColor: `${colors.transparent}`,
              color: colors.kpmg.pacificBlue,
            },
            '.MuiMenuItem-root.Mui-selected:hover': {
              backgroundColor: colors.gray.gray3,
            },
            '.MuiMenuItem-root:hover': {
              backgroundColor: colors.gray.gray3,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&.Mui-disabled': {
              background: colors.gray.gray5,
              color: colors.gray.gray10,
            },
            borderRadius: '8px',
          },
          notchedOutline: {
            borderColor: colors.gray.gray10,
            borderRadius: '8px',
            borderWidth: '1px',
          },
          root: {
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.gray.gray10,
              borderWidth: '1px',
              color: colors.gray.gray10,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.semantic.red,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              color: colors.kpmg.pacificBlue,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.kpmg.lightBlue,
              borderWidth: '1px',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: colors.gray.gray80,
            left: rtl ? 0 : 'unset',
            right: rtl ? 'unset' : 0,
          },
          iconOutlined: {
            color: colors.gray.gray80,
            left: rtl ? spacing(3) : 'unset',
            right: rtl ? 'unset' : spacing(3),
          },
          root: {
            '&::after': {
              content: 'none',
            },
            '&::before': {
              content: 'none',
            },
            '.MuiInput-input': {
              backgroundColor: `${colors.transparent} !important`,
              paddingLeft: rtl ? spacing(6) : 'unset',
              paddingRight: rtl ? 'unset !important' : 'initial',
            },
            '.MuiOutlinedInput-input': {
              backgroundColor: `${colors.transparent} !important`,
              paddingLeft: rtl ? spacing(10) : spacing(3),
              paddingRight: rtl ? `${spacing(3)} !important` : `${spacing(10)} !important`,
            },
          },
          select: {
            height: '1.4375em',
          },
        },
      },
    },

    direction: rtl ? 'rtl' : 'ltr',
    palette: {
      // red
      error: {
        main: colors.semantic.red,
      },

      mode: 'light',

      // blue
      primary: {
        contrastText: colors.gray.gray0,
        dark: colors.kpmg.kpmgBlue,
        main: colors.kpmg.pacificBlue,
      },

      // grey
      secondary: {
        dark: colors.gray.gray20,
        light: colors.gray.gray0,
        main: colors.text,
      },

      // green
      success: {
        main: colors.semantic.green,
      },

      text: {
        primary: colors.text,
        secondary: colors.gray.gray40,
      },
    },
    spacing,

    typography: {
      fontFamily: 'Open Sans',
    },
  })
}

let originalTheme = generateThemeLocal(false)

export let theme = responsiveFontSizes(originalTheme)

export const generateTheme = (rtl: boolean | undefined) => {
  originalTheme = generateThemeLocal(rtl)
  theme = responsiveFontSizes(originalTheme)
  return theme
}
