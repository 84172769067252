import { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import { Input, InputProps, useForm, yup } from '@dis/form'
import { colors } from '@dis/styles/src/colors'
import { useCreateAtlas, useCreateFolder } from '@dis/hooks/src/useCreate'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import PlusIcon from '@dis/assets/src/icons/PlusIcon.svg'
import { SvgImage } from '@dis/components'
import { styles } from './styles'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})
type Props = {
  atlasId?: number // depending on whether it is or not, an atlas or a folder is created
  forceTenantId?: number
  refetchAtlasOptions: VoidFunction
}

export type NewItemForm = {
  name: string
}

export const CreateItem = ({ atlasId, forceTenantId, refetchAtlasOptions }: Props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(false)

  const { sendCreate: sendCreateAtlas } = useCreateAtlas({
    forceTenantId,
    onData: refetchAtlasOptions,
  })
  const { sendCreate: sendCreateFolder } = useCreateFolder({
    forceTenantId,
    onData: refetchAtlasOptions,
  })

  const { control, watch } = useForm<NewItemForm>({
    defaultValues: {
      name: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema,
  })
  const name = watch('name')

  const onSubmitForm = () => {
    setSelected(false)
    // send request to server to add new atlas
    if (name && atlasId) {
      sendCreateFolder(atlasId, name)
      return
    }
    if (name) sendCreateAtlas(name)
  }

  const setSelectedWrapper = useCallback(() => {
    setSelected(true)
  }, [])

  const handleKeyDown = useCallback<NonNullable<InputProps['onKeyDown']>>((event) => {
    event.stopPropagation()
  }, [])

  return (
    <>
      <div className={styles.createItem.selectItemContainer}>
        <Input
          formControl={control}
          name="name"
          fontColor={colors.kpmg.pacificBlue}
          startIcon={
            !selected ? (
              <SvgImage
                color={colors.kpmg.pacificBlue}
                className={styles.createItem.icon}
                src={PlusIcon}
              />
            ) : (
              <></>
            )
          }
          placeholder={
            atlasId
              ? t(tKeys.journeys.new.modal.folderCreate)
              : t(tKeys.journeys.new.modal.atlasCreate)
          }
          placeholderColor={colors.kpmg.pacificBlue}
          fontSize="paragraph.body"
          fontWeight="bold"
          className={styles.createItem.input}
          onKeyDown={handleKeyDown}
          onFocus={setSelectedWrapper}
          onSubmit={onSubmitForm}
          autoComplete="off"
        />
        {selected ? (
          <Button className={styles.createItem.createButton} onClick={onSubmitForm}>
            {t(tKeys.journeys.new.modal.createButton)}
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.createItem.selectItemLine} />
    </>
  )
}
