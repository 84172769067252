import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const localApiSlice = createApi({
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getConfigJSON: builder.query({
      query: () => '/config.json',
    }),
  }),
  reducerPath: 'localApi',
})

export const { useGetConfigJSONQuery } = localApiSlice
