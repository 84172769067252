import { lazy } from 'react'

export { Error } from './Error/Error'

export { Login } from './Login/Login'

export { Tenants } from './Tenants/Tenants'

export { Search } from './Search/Search'

export const AtlasDetail = lazy(
  (): Promise<any> =>
    import('./AtlasDetail/AtlasDetail').then((response: any) => ({
      default: response.AtlasDetail,
    })),
)

export const Backlog = lazy(
  (): Promise<any> =>
    import('./Backlog/Backlog').then((response: any) => ({ default: response.Backlog })),
)

export const ConnectedCapabilities = lazy(
  (): Promise<any> =>
    import('./ConnectedCapabilities/ConnectedCapabilities').then((response: any) => ({
      default: response.ConnectedCapabilities,
    })),
)

export const ManagementOfTenant = lazy(
  (): Promise<any> =>
    import('./ManagementOfTenant/ManagementOfTenant').then((response: any) => ({
      default: response.ManagementOfTenant,
    })),
)

export const Folder = lazy(
  (): Promise<any> =>
    import('./Folder/Folder').then((response: any) => ({ default: response.Folder })),
)

export const Journey = lazy(
  (): Promise<any> =>
    import('./Journey/Journey').then((response: any) => ({ default: response.Journey })),
)

export const LandingPage = lazy(
  (): Promise<any> =>
    import('./LandingPage/LandingPage').then((response: any) => ({
      default: response.LandingPage,
    })),
)

export const Atlases = lazy(
  (): Promise<any> =>
    import('./Atlases/Atlases').then((response: any) => ({
      default: response.Atlases,
    })),
)

export const ChannelManagement = lazy(
  (): Promise<any> =>
    import('./ChannelManagement/ChannelManagement').then((response: any) => ({
      default: response.ChannelManagement,
    })),
)

export const Personas = lazy(
  (): Promise<any> =>
    import('./Personas/Personas').then((response: any) => ({ default: response.Personas })),
)

export const PersonaDetail = lazy(
  (): Promise<any> =>
    import('./PersonaDetail/PersonaDetail').then((response: any) => ({
      default: response.PersonaDetail,
    })),
)

export const ArchivedJourneys = lazy(
  (): Promise<any> =>
    import('./ArchivedJourneys/ArchivedJourneys').then((response: any) => ({
      default: response.ArchivedJourneys,
    })),
)

export const NewJourney = lazy(
  (): Promise<any> =>
    import('./NewJourney/NewJourney').then((response: any) => ({ default: response.NewJourney })),
)

export const TestPage = lazy(
  (): Promise<any> =>
    import('./TestPage/TestPage').then((response: any) => ({ default: response.TestPage })),
)

export const Tutorial = lazy(
  (): Promise<any> =>
    import('./Tutorial/Tutorial').then((response: any) => ({ default: response.Tutorial })),
)

export const UserManagement = lazy(
  (): Promise<any> =>
    import('./UserManagement/UserManagement').then((response: any) => ({
      default: response.UserManagement,
    })),
)

export const TemplateManagement = lazy(
  (): Promise<any> =>
    import('./TemplateManagement/TemplateManagement').then((response: any) => ({
      default: response.TemplateManagement,
    })),
)

export const ListOfJourneys = lazy(
  (): Promise<any> =>
    import('./ListOfJourneys/ListOfJourneys').then((response: any) => ({
      default: response.ListOfJourneys,
    })),
)

export const ArchivedTenants = lazy(
  (): Promise<any> =>
    import('./ArchivedTenants/ArchivedTenants').then((response: any) => ({
      default: response.ArchivedTenants,
    })),
)
