import { colors } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  routeIcon: css`
    path {
      fill: ${colors.gray.gray5};
    }
    g g {
      fill: ${colors.gray.gray5};
    }
  `,
}
