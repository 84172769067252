const year = new Date().getFullYear()
export const FIRST_PARAGRAPH = `Your data is being processed by KPMG International Services Limited on behalf of the
                member Firm which you have engaged. The member firm will determine the manner and
                purpose as to how your data is processed. For more details on how your data will be
                processed, please consult the local member firms’ applicable privacy statement `
export const SECOND_PARAGRAPH = ` To the extent that KPMG International Services Limited is acting as a controller of
                any personal information, your data will be processed in accordance with `
export const COPYRIGHT = `© ${year} Copyright owned by one or more of the KPMG International entities. KPMG International entities provide no services to clients. All rights reserved.`
export const PRIVACY_LINK = 'https://landing.kpmg.com/privacy.html'
export const PRIVACY_STATEMENT_LINK = 'https://kpmg.com/xx/en/home/misc/privacy.html'
