import { useMemo } from 'react'
import { RouterProvider as RouterProviderDom } from 'react-router-dom'
import { App } from '@dis/app/src/App'
import { getRouter } from './Routes'

/**
 * Own router provider to avoid loading App component before the MsalProvider component is fully prepared
 */
export const RouterProvider = () => {
  const router = useMemo(() => {
    return getRouter(<App />)
  }, [])

  return <RouterProviderDom router={router} />
}
