import { css } from '@emotion/css'

export const styles = {
  container: (color?: string, width?: string, height?: string) => css`
    display: flex;
    width: ${width};
    height: ${height};
    color: ${color};
    svg {
      width: ${width};
      height: ${height};
    }
    div {
      display: flex;
    }
  `,
}
