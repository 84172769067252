import { TableProps } from './types'

export const sort = <RowType>(
  rows: TableProps<RowType>['rows'],
  orderDirection: TableProps<RowType>['defaultOrderDirection'],
  orderBy: TableProps<RowType>['defaultOrderBy'],
  orderLikeNumber: TableProps<RowType>['orderLikeNumber'],
): Array<RowType> => {
  if (orderBy) {
    // Keep in mind the sort function also modifies the input rows prop
    const sortedRows = [
      ...rows.sort(getComparator<RowType>(orderDirection, orderBy, orderLikeNumber)),
    ]

    return sortedRows
  }

  return rows
}

function getComparator<RowType>(
  orderDirection: TableProps<RowType>['defaultOrderDirection'],
  orderBy: NonNullable<TableProps<RowType>['defaultOrderBy']>,
  orderLikeNumber: TableProps<RowType>['orderLikeNumber'],
): (a: RowType, b: RowType) => number {
  return orderDirection === 'desc'
    ? (a, b) => descendingComparator<RowType>(a, b, orderBy, orderLikeNumber)
    : (a, b) => -descendingComparator<RowType>(a, b, orderBy, orderLikeNumber)
}

function descendingComparator<RowType>(
  a: RowType,
  b: RowType,
  orderBy: keyof RowType,
  orderLikeNumber: TableProps<RowType>['orderLikeNumber'],
) {
  const shouldConvertToNumber = orderLikeNumber?.includes(orderBy)

  // backlogId is type string => wrong sorting (aka ASCII): [1, 100, 2, 20, 3,] => needs coercing
  const isOrderByIdOrEstimation = orderBy == 'id' || orderBy == 'estimation'
  let bValue = shouldConvertToNumber && isOrderByIdOrEstimation ? Number(b[orderBy]) : b[orderBy]
  let aValue = shouldConvertToNumber && isOrderByIdOrEstimation ? Number(a[orderBy]) : a[orderBy]

  // empty estimation in backlog is NaN
  if (Number.isNaN(bValue)) bValue = 0
  if (Number.isNaN(aValue)) aValue = 0

  if (bValue < aValue) {
    return -1
  }
  if (bValue > aValue) {
    return 1
  }
  return 0
}
