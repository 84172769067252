import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { JourneyPreview } from '@dis/types/src/JourneyTypes'
import { AdministrationTenant, TenantDashboard } from '@dis/types/src/TenantTypes'
import { ViewEnum } from '@dis/types/src/GeneralTypes'

type TenantsReducerState = {
  dashboard?: TenantDashboard
  isTemplate?: boolean
  recycleBin: JourneyPreview[]
  selectedTenantId?: number
  selectedTenantName?: string
  showLogo?: boolean
  tenant?: AdministrationTenant
  tenantDisplayType: ViewEnum
}

/**
 * Keep type of prop isTemplate like undefined/boolean because 3-state type is used somewhere
 */
const initialState: TenantsReducerState = {
  dashboard: undefined,
  isTemplate: undefined,
  recycleBin: [],
  // This should be defined as optional string with default undefined value.
  selectedTenantId: undefined,
  selectedTenantName: undefined,
  showLogo: true,
  tenant: undefined,
  tenantDisplayType: ViewEnum.Table,
}

export const tenantsSlice = createSlice({
  initialState,
  name: 'tenants',
  reducers: {
    init: () => initialState,
    setSelectedIsTemplateTenant: (state, action: PayloadAction<boolean>) => {
      state.isTemplate = action.payload
    },
    setSelectedLogoTenant: (state, action: PayloadAction<boolean>) => {
      state.showLogo = action.payload
    },
    setSelectedTenantId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTenantId = action.payload
    },
    setSelectedTenantName: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTenantName = action.payload
    },
    setTenantData: (state, action: PayloadAction<AdministrationTenant | undefined>) => {
      state.tenant = action.payload

      if (action.payload?.name) {
        state.selectedTenantName = action.payload.name
        state.isTemplate = action.payload.istemplate
        state.showLogo = action.payload.showlogo
      }
    },
    setTenantDisplayType: (state: TenantsReducerState, action: PayloadAction<ViewEnum>) => {
      state.tenantDisplayType = action.payload
    },
  },
})
