import { css } from '@emotion/css'
import { colors, theme, typography } from '@dis/styles'

export const styles = {
  formLabel: css`
    font-size: ${typography.paragraph.body.fontSize};
  `,
  ghost: css`
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.5;
  `,
  inputForm: css`
    position: relative;
  `,
  lengthStylesBottom: css`
    bottom: -${theme.spacing(5)};
  `,
  lengthStylesInvisible: css`
    display: none;
  `,
  lengthStylesLeft: css`
    left: 0;
  `,
  lengthStylesRight: css`
    right: 0;
  `,
  lengthStylesTop: css`
    top: ${theme.spacing(1)};
  `,
  lengthStylesVisible: css`
    display: flex;
    justify-content: end;
    color: ${colors.gray.gray60};
    position: absolute;
  `,
  noUnderline: css`
    .MuiInput-underline::before {
      border-bottom: none;
      content: none;
    }
  `,
  placeholderDisappearAndHoverUnderline: css`
    position: relative;

    input {
      :hover {
        text-decoration-line: underline;
      }
    }
    .Mui-focused {
      input {
        :hover {
          text-decoration: none;
        }
      }
      input::placeholder {
        color: ${colors.transparent};
      }
    }
  `,
  sameStyleForDisabled: css`
    input {
      -webkit-text-fill-color: initial !important;
    }
    .Mui-disabled {
      -webkit-text-fill-color: initial !important;
    }
  `,
}
