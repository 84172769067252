import { SyntheticEvent } from 'react'
import { MenuItem } from '@mui/material'
import { t } from 'i18next'
import { tKeys } from '@dis/languages'
import { WsVersionList } from '@dis/types/src/VersioningType'
import { styles } from './styles'

type VersionListItemsProps = {
  handleClose: (event: SyntheticEvent) => void
  isVersionListLoading: boolean
  onClickSetVersion: (i: number) => () => void
  versions?: NonNullable<WsVersionList['versions']>
}

const historyKeys = {
  1: tKeys.revisionControl.oneHourBack,
  24: tKeys.revisionControl.twentyFourHoursBack,
  4: tKeys.revisionControl.fourHoursBack,
  48: tKeys.revisionControl.fortyEightHoursBack,
  8: tKeys.revisionControl.eightHoursBack,
}

export const VersionListItems = ({
  isVersionListLoading,
  versions,
  onClickSetVersion,
  handleClose,
}: VersionListItemsProps) => {
  if (isVersionListLoading) {
    return (
      <MenuItem className={styles.versionListItem} disableRipple onClick={handleClose}>
        {t(tKeys.revisionControl.isLoading)}
      </MenuItem>
    )
  }

  if (versions?.length) {
    return (
      <>
        {versions.map(({ version, hours }) => (
          <MenuItem
            className={styles.versionListItem}
            key={hours}
            disableRipple
            onClick={onClickSetVersion(version)}>
            {t(historyKeys[hours as keyof typeof historyKeys])}
          </MenuItem>
        ))}
      </>
    )
  }

  return (
    <MenuItem className={styles.versionListItem} disableRipple onClick={handleClose}>
      {t(tKeys.revisionControl.historyIsNotAvailable)}
    </MenuItem>
  )
}
