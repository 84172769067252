import { Header, Paragraph } from '@dis/components'
import image22 from '@dis/assets/src/images/newTutorial/22.png'
import { styles } from '../styles'

/* eslint-disable react/no-unescaped-entities */
export const ExportToXLSX = () => {
  return (
    <>
      <Header marginTop={2} variant="h2">
        1.2 Export to XLSX
      </Header>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        To export a journey to XLSX format, click on the journey's dropdown menu and choose "Export
        to XLSX" option – your journey will be downloaded afterwards (and stored in the Downloads
        section).
      </Paragraph>
      <Paragraph marginTop={2} variant="medium" className={styles.subtitleDescription}>
        <img src={image22} width="600" alt="Settings Discovery" />
      </Paragraph>
    </>
  )
}
