import { colors, hex2rgba, shadows, theme, typography, typographyFontWeight } from '@dis/styles'
import { css, injectGlobal } from '@emotion/css'

// TinyMCE core
// eslint-disable-next-line import/no-duplicates
import 'tinymce/tinymce'
import 'tinymce/models/dom/model'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.min.css'

// TinyMCE plugins
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/emoticons/js/emojis'

// TinyMCE CSS
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'

// Styles for insert/edit link modal
injectGlobal`
    .tox {
        .tox-dialog-wrap__backdrop{
            background-color: ${hex2rgba(colors.gray.gray100, 0.5)} !important;
        }
        
        .tox-dialog {
            border-radius: 4px !important;
            
            .tox-dialog__header {
                padding: ${theme.spacing(8)};
                border-bottom: solid 1px ${colors.gray.gray5};

                .tox-dialog__title {
                    font-size: ${typography.header.h2.fontSize};
                    line-height: ${typography.header.h2.lineHeight};
                    font-weight: 700;
                }

                button {
                    border-radius: 100%;
                    padding: 0;
                    margin: 0;
                    color: ${colors.gray.gray40};

                    &:hover {
                        color: ${colors.gray.gray40} !important;
                        background-color: ${hex2rgba(colors.gray.gray100, 0.04)};
                    }
                }
            }

            .tox-dialog__content-js {
                .tox-dialog__body-content {
                    padding-top: ${theme.spacing(6)};
                    padding-inline-end: ${theme.spacing(6)};
                    padding-inline-start: 0;
                    padding-bottom: ${theme.spacing(4)};
                    margin: 0 ${theme.spacing(2)} 0 ${theme.spacing(8)};
                }

                .tox-form__group {
                    &:not(:first-child) {
                        margin-top: ${theme.spacing(4)};
                    }

                    label {
                        font-size: ${typography.paragraph.body.fontSize};
                        line-height: ${typography.paragraph.body.lineHeight};
                        margin-bottom: ${theme.spacing(1)};
                        color: ${colors.text};
                    }

                    input {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        border-radius: 8px;
                        border-color: ${colors.gray.gray10};
                        border-width: 1px;
                        outline: none;
                        box-shadow: none;
                        color: ${colors.text};
                        font-size: ${typography.paragraph.medium.fontSize};
                        line-height: ${typography.paragraph.medium.lineHeight};
                        padding: ${theme.spacing(4.125)} ${theme.spacing(3.5)};

                        &:hover {
                            outline: none;
                            box-shadow: none;
                            border-color: ${colors.kpmg.lightBlue};
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border-color: ${colors.kpmg.pacificBlue};
                        }
                    }

                    .tox-listboxfield {
                        .tox-listbox--select {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            border-radius: 8px;
                            border-color: ${colors.gray.gray10};
                            border-width: 1px;
                            outline: none;
                            box-shadow: none;
                            color: ${colors.text};
                            font-size: ${typography.paragraph.medium.fontSize};
                            line-height: ${typography.paragraph.medium.lineHeight};
                            padding: ${theme.spacing(4.125)} ${theme.spacing(3.5)};
                            width: 100% !important;
                            max-width: 100%;

                            &:hover {
                                outline: none;
                                box-shadow: none;
                                border-color: ${colors.kpmg.lightBlue};
                            }

                            &:focus {
                                outline: none;
                                box-shadow: none;
                                border-color: ${colors.kpmg.pacificBlue};
                            }
                        }
                    }
                }
            }

            .tox-dialog__footer {
                padding: ${theme.spacing(8)};
                border-top: solid 1px ${colors.gray.gray5};
                
                .tox-dialog__footer-end {
                    display: flex;
                    gap: ${theme.spacing(3)};
                }
                
                button{
                    border-radius: 6.25rem;
                    font-size: ${typography.paragraph.body.fontSize};
                    font-weight: ${typographyFontWeight.bold};
                    height: 2.75rem;
                    line-height: ${typography.paragraph.body.lineHeight};
                    padding: 0 ${theme.spacing(6)};
                    text-transform: none;
                    margin: 0;
                    
                    &:nth-child(1){
                        display: none;
                    }

                    &:nth-child(2){
                        background-color: ${colors.kpmg.pacificBlue};
                        border-width: 0;
                        
                        &:hover{
                            background-color: ${colors.kpmg.blue};
                        }
                    }
                }
            }
        }
        
        .tox-tiered-menu {
            .tox-selected-menu {
                border-radius: 4px 0 0 4px;
                border-color: transparent;
                box-shadow: ${shadows['10']};
                margin-top: ${theme.spacing(1)};
                padding: ${theme.spacing(2)} !important;
                max-width: 416px;
                min-width: 0 !important;
                right: ${theme.spacing(10)};
                box-sizing: border-box;
                
                .tox-collection__group {
                    padding: 0 !important;
                    margin: 0 !important;
                    
                    
                    & > * {
                        padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
                        background-color: transparent;
                        cursor: pointer;    
                        margin: 0;                        

                        &:hover {
                            background-color: ${colors.gray.gray3};
                        }

                        .tox-collection__item-label {
                            font-size: ${typography.paragraph.body.fontSize};
                            line-height: ${typography.paragraph.body.lineHeight};
                            color: ${colors.text};
                        }

                        .tox-collection__item-checkmark {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`

export const styles = {
  actionButtons: css`
    display: flex;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(3)};
  `,
  container: {
    fullWidth: css`
      width: 100%;
    `,
    root: css`
      p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        hyphens: auto;
        word-wrap: break-word;
      }
    `,
  },
  editorOuterContainer: {
    editorContainer: {
      error: css`
        padding: ${theme.spacing(1)} ${theme.spacing(1)};
      `,
      root: css`
        .tox.tox-tinymce {
          border: 1px solid ${colors.gray.gray10};
          border-radius: 0.5rem;

          .tox-editor-header {
            box-shadow: none !important;
            border-bottom: solid 1px ${colors.gray.gray5};

            .tox-toolbar__primary {
              background-image: none !important;

              .tox-toolbar__group {
                padding: 0 ${theme.spacing(3)};
                margin: ${theme.spacing(2)} 0;
                gap: ${theme.spacing(2)};

                &:not(:last-of-type) {
                  border-right: solid 1px ${colors.gray.gray5};
                }

                .tox-tbtn {
                  width: 2rem !important;
                  height: 2rem !important;
                  max-width: 2rem !important;
                  max-height: 2rem !important;
                  margin: 0 !important;
                  padding: 0 !important;
                  border-radius: 4px;

                  &:not(.tox-tbtn--disabled) {
                    cursor: pointer !important;
                  }

                  &:hover {
                    background-color: ${colors.kpmg.cobaltLight};
                  }

                  &.tox-tbtn--enabled {
                    background-color: ${colors.kpmg.cobaltLight};
                    svg {
                      fill: ${colors.kpmg.pacificBlue};
                    }
                  }
                }
              }
            }
          }
        }
      `,
    },
    root: css`
      display: none;
    `,
    visible: css`
      display: block;
    `,
  },
  view: {
    border: css`
      &:hover {
        border-color: ${colors.kpmg.lightBlue};
      }
      color: ${colors.text};
      border: 1px solid ${colors.gray.gray10};
      cursor: text;
      border-radius: 8px;
      min-height: 56px;
      padding: ${theme.spacing(3)};
    `,
    placeholderGray: css`
      color: ${colors.gray.gray40} !important;
    `,
    underlineHover: css`
      :hover {
        text-decoration-line: underline;
      }
    `,
  },
}

export const getEditorContentStyle = (fontFamily: string) =>
  [
    contentCss,
    contentUiCss,
    `                    
                    .mce-content-body {
                      margin: ${theme.spacing(3)};
                    }                                       
                    
                    p {
                      color: ${colors.text};
                      margin-top: 0;
                      margin-bottom: 0;
                      font-size: ${typography.paragraph.medium.fontSize};
                      line-height: ${typography.paragraph.medium.lineHeight};
                      font-family: ${fontFamily};                  
                    }                                
                `,
  ].join('\n')
