import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image95 from '@dis/assets/src/images/newTutorial/95.png'
import image96 from '@dis/assets/src/images/newTutorial/96.png'
import image97 from '@dis/assets/src/images/newTutorial/97.png'
import image98 from '@dis/assets/src/images/newTutorial/98.png'
import image99 from '@dis/assets/src/images/newTutorial/99.png'
import image100 from '@dis/assets/src/images/newTutorial/100.png'
import image101 from '@dis/assets/src/images/newTutorial/101.png'
import image102 from '@dis/assets/src/images/newTutorial/102.png'
import image103 from '@dis/assets/src/images/newTutorial/103.png'
import image104 from '@dis/assets/src/images/newTutorial/104.png'
import image105 from '@dis/assets/src/images/newTutorial/105.png'
import image106 from '@dis/assets/src/images/newTutorial/106.png'
import image107 from '@dis/assets/src/images/newTutorial/107.png'
import image108 from '@dis/assets/src/images/newTutorial/108.png'
import image109 from '@dis/assets/src/images/newTutorial/109.png'
import image110 from '@dis/assets/src/images/newTutorial/110.png'
import image111 from '@dis/assets/src/images/newTutorial/111.png'
import image112 from '@dis/assets/src/images/newTutorial/112.png'
import image113 from '@dis/assets/src/images/newTutorial/113.png'
import image114 from '@dis/assets/src/images/newTutorial/114.png'
import image115 from '@dis/assets/src/images/newTutorial/115.png'
import image116 from '@dis/assets/src/images/newTutorial/116.png'
import image117 from '@dis/assets/src/images/newTutorial/117.png'
import image118 from '@dis/assets/src/images/newTutorial/118.png'
import image119 from '@dis/assets/src/images/newTutorial/119.png'
import image120 from '@dis/assets/src/images/newTutorial/120.png'
import image121 from '@dis/assets/src/images/newTutorial/121.png'
import image122 from '@dis/assets/src/images/newTutorial/122.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const Journey = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.4 Journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        The journey represents a summary of customer interactions.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        There are three views of journeys:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Overall list of journeys of the given tenant ("All journeys" screen accessible from "Landing
        page" by clicking on "Show all" button in "Journeys" section)
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image95} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image96} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        List of journeys of a specific atlas/folder ("List of journeys" screen accessible from
        "Landing page" by clicking on "Show all" button in "Atlases" section and choosing a specific
        atlas/folder)
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image97} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image98} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "Journey detail" screen
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image99} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.4.1 Duplicating a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas; only within the assigned tenant)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Power user (only within the assigned tenant)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Tenant admin (only within the assigned tenant)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Kpmg user (only within the currently selected tenant)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Kpmg admin (within the currently selected tenant and Template tenant)
        </ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To duplicate a journey, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "All journeys" screen: click on the item's dropdown menu and choose "Duplicate".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image100} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "List of journeys" screen: hover over the journey icon and choose "Duplicate".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image101} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "Journey detail" screen: click on the journey's dropdown menu and choose "Duplicate".
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image102} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all of the above scenarios, a modal window will then appear in which you can choose the
        destination tenant (Current/Template)/atlas/folder in which you want to duplicate the
        journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image103} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        After confirming the given modal, a label will be displayed informing the user about the
        successful execution of the selected action. By clicking on the "duplicated" link, the user
        is redirected to the details of the duplicated journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image104} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.4.2 Moving a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (editable permission on the given atlas) User (editable permission on the given
          atlas; only within the assigned tenant)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To move a journey, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "All journeys" screen: click on the item's dropdown menu and choose "Move"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image105} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "List of journeys" screen: hover over the journey icon and choose "Move"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image106} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "Journey detail" screen: click on the journey's dropdown menu and choose "Move"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image107} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all of the above scenarios, a modal window will then appear in which you can choose the
        destination atlas/folder in which you want to move the journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image108} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        After confirming the given modal, a label will be displayed informing the user about the
        successful execution of the selected action. By clicking on the "moved" link, the user is
        redirected to the content of the atlas, where the journey was moved.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image109} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.4.3 Sharing a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (visible/editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To share a journey via email, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "All journeys" screen: click on the item's dropdown menu and choose "Share"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image110} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "List of journeys" screen: hover over the journey icon and choose "Share"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image111} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "Journey detail" screen: click on the journey's dropdown menu and choose "Share"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image112} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all of the above scenarios, you will then be redirected straight to your email outlook
        with a link to your journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image113} alt="Settings Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        1.4.4 Deleting a journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (visible/editable permission on the given atlas)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To delete a journey, you can use one of the options below:
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "All journeys" screen: click on the item's dropdown menu and choose "Delete"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image114} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "List of journeys" screen: hover over the journey icon and choose "Delete"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image115} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        "Journey detail" screen: click on the journey's dropdown menu and choose "Delete"
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image116} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        In all of the above scenarios, a modal window will then appear in which you have to confirm
        deletion of the journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image117} alt="Settings Discovery" />
      </Paragraph>
      <Header marginTop={5} marginBottom={4} variant="h3">
        1.4.4.1 Viewing/restoring a deleted journey
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Accessible for bellow mentioned roles:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          User (possibility to restore an archived journey based on the permission
          ("Invisible"/"Visible"/Editable) associated with the assigned atlas) Invisible: can not
          see any deleted journeys within Recycle bin Visible: can see deleted journeys which were
          placed in atlases to which user has "Visible" permission but can not restore them
          Editable: can see deleted journeys which were placed in atlases to which user has
          "Editable" permission and can restore them; within the restoration modal, the user will be
          offered only atlases on which the "Editable" permission is set
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Power user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tenant admin</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg user</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Kpmg admin</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Deleted journeys are not permanently deleted, but can be found in the recycle bin, from
        where it is possible to restore them.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To see deleted journeys, click on "Archived journeys" button on "All journeys" screen.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image118} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Subsequently, the "Recycle bin" screen will appear with a complete overview of the deleted
        journeys of the given tenant.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image119} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        By clicking on the icon of the deleted journey (managed by the permission mentioned above)
        you can view the read-only detail of the given journey (set to "Deleted" status).
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image120} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Restore a journey by clicking on the right upper "Back arrow" icon.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image121} alt="Settings Discovery" />
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        Subsequently, a modal window will appear in which you must define into which atlas/folder
        you want to restore the selected journey.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image122} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
