import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { styles } from '../styles'
import { ScrollProps } from '../types'

const msg = tKeys.tutorials.userRoles

/* eslint-disable react/no-unescaped-entities */
export const UserRoles = ({ id }: ScrollProps) => {
  const { t } = useTranslation()
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        {t(msg.userRole)}
      </Header>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        {t(msg.userRoleDescription)}
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          Belonging a user to a group in Azure Active Directory
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Settings in the Tenant database</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Settings in the configuration database</ListItem>
      </List>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        {t(msg.userRoleDefinition)}
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"User"</strong> - ordinary external user role associated with one tenant
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          sees Atlases (and thus folders and journeys) for which he has set permission at least for
          reading
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates, edits and deletes journeys and folders in those Atlases for which he has set
          permission for editing
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates, deletes personas (deletion allowed only if the given persona is not used in any
          journey).
        </ListItem>
      </List>

      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Power user"</strong> - advanced external user role associated with one tenant
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          manages user groups and their access to Atlases
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          sees and has the permission to edit all Atlases, folders and journeys
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits and deletes Atlases</ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits, and deletes personas</ListItem>
      </List>

      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Tenant admin"</strong> - highest external user role associated with one tenant
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          changes application settings (e.g. tenant details, field length limits etc.)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates new tenant users and assigns them roles ("User", "Power user", "Tenant admin")
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>assigns users with "User" role to groups</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          manages user groups and their access to Atlases
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages local templates</ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages local channels</ListItem>
      </List>

      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Kpmg user"</strong> - ordinary internal user role, which can be associated with
        several tenants
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>
          changes application settings (e.g. tenant details, field length limits etc.)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates new kpmg users and assigns them specific tenants
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates new tenant users and assigns them roles ("User", "Power user", "Tenant admin")
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>assigns users with "User" role to groups</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          manages user groups and their access to Atlases
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages local templates</ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages local channels</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          sees and has the permission to edit all Atlases, folders and journeys
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits and deletes Atlases</ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits, and deletes personas</ListItem>
      </List>

      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <strong>"Kpmg admin"</strong> - highest application role, which has access to all tenants
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>creates new tenants</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          changes application settings (e.g. tenant details, field length limits etc.)
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates new kpmg users and assigns them specific tenants
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          creates new tenant users and assigns them roles ("User", "Power user", "Tenant admin")
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>assigns users with "User" role to groups</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          manages user groups and their access to Atlases
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          manages global/local templates and examples
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages categories of examples</ListItem>
        <ListItem sx={{ display: 'list-item' }}>manages global/local channels</ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          sees and has the permission to edit all Atlases, folders and journeys
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits and deletes Atlases</ListItem>
        <ListItem sx={{ display: 'list-item' }}>creates, edits, and deletes personas</ListItem>
      </List>
    </div>
  )
}
