import { SvgImage } from '@dis/components'
import Close from '@dis/assets/src/icons/Close.svg'
import WifiOn from '@dis/assets/src/icons/WifiOn.svg'
import WifiOff from '@dis/assets/src/icons/WifiOff.svg'
import { styles } from './styles'

export const PreloadSVGs = () => {
  return (
    <div className={styles.root}>
      <SvgImage src={Close} />
      <SvgImage src={WifiOn} />
      <SvgImage src={WifiOff} />
    </div>
  )
}
