import { theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  root: (fontSize: string) => css`
    .MuiButton-startIcon {
      margin-right: 0px;
      margin-left: 0px;
      margin-inline-end: ${theme.spacing(2)};
    }
    font-size: ${fontSize};
  `,
  svgImage: (iconSize?: string) => css`
    svg {
      width: ${iconSize};
      height: ${iconSize};
    }
  `,
}
