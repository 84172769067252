export * from './date'
export { ExtendedError } from './ExtendedError'

export const removeHTMLTags = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, ' ')
}

export const shortenText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength).concat('..') : text
}

export const PERSONA = 'Persona'
