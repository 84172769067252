import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const buttonStyle = ({
  top,
  right,
  left,
  position = 'absolute',
  bottom,
  zIndex,
}: {
  bottom?: number
  left?: number
  position?: string
  right?: number
  top?: number
  zIndex?: number
}) => css`
  bottom: ${bottom && theme.spacing(bottom)};
  inset-inline-end: ${right && theme.spacing(right)};
  inset-inline-start: ${left && theme.spacing(left)};
  position: ${position};
  top: ${top && theme.spacing(top)};
  transition: opacity ease-out 0.5s;
  z-index: ${zIndex};
`

export const buttonTransition = css`
  opacity: 0;
`

export const styles = {
  body: css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: ${colors.background.whiteBlue};
    transition: all ease-out 0.5s;
    :hover {
      background-color: ${colors.background.white};
      border: 1px solid ${colors.gray.gray10};
    }
    :hover ${'.' + buttonTransition} {
      opacity: 1;
    }
  `,
  centered: css`
    align-items: center;
    justify-content: center;
  `,
  extraPadding: css`
    padding: ${theme.spacing(6)} ${theme.spacing(3)};
  `,
}
