import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JourneyPreview } from '@dis/types/src/JourneyTypes'
import { tKeys } from '@dis/languages'
import { Paragraph } from '@dis/components'
import { JourneysTableRow } from './JourneysTableRow'
import { styles } from './styles'

type MapJourneysProps = {
  journeys: JourneyPreview[]
}

export const JourneysTable = ({ journeys = [] }: MapJourneysProps) => {
  const { t } = useTranslation()

  return (
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell className={styles.tableHeaderName}>
            <Paragraph variant="small">{t(tKeys.common.name)}</Paragraph>
          </TableCell>
          <TableCell className={styles.tableHeaderDate}>
            <Paragraph variant="small">{t(tKeys.journeysPreview.lastChange)}</Paragraph>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {journeys?.map(({ updated, name, id, atlasId, folderId }) => (
          <JourneysTableRow
            atlasId={atlasId}
            folderId={folderId}
            updated={updated}
            journeyId={id}
            journeyName={name}
            key={id}
          />
        ))}
      </TableBody>
    </Table>
  )
}
