import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  lastLink: css`
    color: ${colors.gray.gray40};
    cursor: default;

    svg {
      path {
        fill: ${colors.gray.gray40};
      }
    }
  `,
  link: css`
    text-decoration: none;
    color: ${colors.gray.gray0};
  `,
  root: css`
    display: flex;
    align-items: center;

    .MuiBreadcrumbs-separator {
      margin-left: ${theme.spacing(1)};
      margin-right: ${theme.spacing(1)};
    }
  `,
  stack: css`
    display: flex;
    flex-direction: row;
    column-gap: ${theme.spacing(2)};
    align-items: center;
  `,
  text: css`
    word-break: break-all;
  `,
}
