import { tKeys } from '@dis/languages'
import { CheckboxForm } from './types'

export enum Priority {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export enum Status {
  Done = 'done',
  InProgress = 'inProgress',
  New = 'new',
  Terminated = 'terminated',
}

export const PRIORITY = {
  [Priority.High]: 30,
  [Priority.Low]: 10,
  [Priority.Medium]: 20,
} as const

export const STATUS = {
  [Status.Done]: 30,
  [Status.InProgress]: 20,
  [Status.New]: 10,
  [Status.Terminated]: 40,
} as const

export const priorityData = [
  {
    title: tKeys.backlog.low,
    value: Priority.Low,
  },
  {
    title: tKeys.backlog.medium,
    value: Priority.Medium,
  },
  {
    title: tKeys.backlog.high,
    value: Priority.High,
  },
]

export const statusData = [
  {
    title: tKeys.backlog.status.new,
    value: Status.New,
  },
  {
    title: tKeys.backlog.status.inProgress,
    value: Status.InProgress,
  },
  {
    title: tKeys.backlog.status.done,
    value: Status.Done,
  },
  {
    title: tKeys.backlog.status.terminated,
    value: Status.Terminated,
  },
] as const

export const inputData = [
  {
    maxLengthValue: 'name',
    multiline: false,
    placeholder: tKeys.common.name,
    title: tKeys.common.name,
    value: 'name',
  },
  {
    maxLengthValue: 'description',
    multiline: true,
    placeholder: tKeys.backlog.descriptionPlaceholder,
    title: tKeys.backlog.description,
    value: 'description',
  },
  {
    maxLengthValue: 'acceptancecriterium',
    multiline: true,
    placeholder: tKeys.backlog.criterium,
    title: tKeys.backlog.criterium,
    value: 'criterium',
  },
  {
    maxLengthValue: undefined,
    multiline: false,
    placeholder: tKeys.administration.backlog.estimation,
    title: tKeys.administration.backlog.estimation,
    value: 'estimation',
  },
] as const

export const MAX_LIMIT = 3_000

export const checkboxesDefaultValues: CheckboxForm = {
  priority: {
    high: false,
    low: false,
    medium: false,
  },
  status: {
    done: true,
    inProgress: true,
    new: true,
    terminated: false,
  },
} as const
