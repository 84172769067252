import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { ids, Navigation } from '../tutorialConstants'
import { Tenant } from './Tenant'
import { Atlas } from './Atlas'
import { Folder } from './Folder'
import { Journey } from './Journey'
import { useScroll } from '../useScroll'

export const TutorialNavigation = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}x
          </Button>
          <TutorialSidebarContent
            initial={ids.navigation}
            onSelectedItemChange={() => ids.navigation}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader header={t(tKeys.tutorials.tutorialNavigationTitleScreen)} />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Navigation.Tenant)}>
                1.1 Tenant
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Navigation.Atlas)}
                className={styles.menuText}>
                1.2 Atlas
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Navigation.Folder)}
                className={styles.menuText}>
                1.3 Folder
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Navigation.Journey)}
                className={styles.menuText}>
                1.4 Journey
              </ListItem>
            </List>
            <Tenant id={Navigation.Tenant} />
            <Atlas id={Navigation.Atlas} />
            <Folder id={Navigation.Folder} />
            <Journey id={Navigation.Journey} />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
