import { useEffect, useState } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectUserOid } from '@dis/redux/src/security/selectors'
import { QueriesGetUserActivityPresenceListQuery } from '@dis/api/src/graphqlClient/gql/__generated__/QueriesGetUserActivityPresenceListQuery.graphql'
import { GetUserActivityPresenceList } from '@dis/api/src/graphqlClient/gql/Queries'
import { Api } from '@dis/api'
import { WsGraphQlOperationType } from '@dis/types/src/wsModels'
import { useRelayQuery } from './useRelay'
import { useErrorCodeModal } from './useErrorCodeModal'

type UseGetUserActivityPresenceList = {
  polling?: number
  skip?: boolean
}

export const useGetUserActivityPresenceList = ({
  skip,
  polling,
}: UseGetUserActivityPresenceList) => {
  const [data, setData] = useState<QueriesGetUserActivityPresenceListQuery['response']>()

  const tenantId = useAppSelector(selectSelectedTenantId)
  const journeyId = useAppSelector(selectSelectedJourneyId)
  const userId = useAppSelector(selectUserOid)

  const { error, loading } = useRelayQuery<QueriesGetUserActivityPresenceListQuery>({
    onData: setData,
    polling,
    skip: !journeyId || !tenantId || !userId || skip,
    taggedNode: GetUserActivityPresenceList,
    variables: {
      input: {
        resourceId: journeyId || 0,
        resourceType: 'JOURNEY',
        tenantId: tenantId || 0,
        userId,
      },
    },
  })

  useEffect(() => {
    Api.subscribeGraphqlNotification<{
      [WsGraphQlOperationType.UserActivityChangePresence]: QueriesGetUserActivityPresenceListQuery['response']['userActivityPresenceList']
    }>(WsGraphQlOperationType.UserActivityChangePresence, (response, error) => {
      if (!error && response?.data) {
        setData({
          userActivityPresenceList: response.data,
        })
      }
    })
  }, [])

  useErrorCodeModal({
    defaultContent: error?.errors?.[0]?.message,
    errorCode: error?.errors?.length || 0 > 0 ? 'ERROR' : '',
    priority: 'content',
  })

  return {
    data: data?.userActivityPresenceList,
    error,
    loading,
  }
}
