import { ReactNode, useEffect } from 'react'
import { store, startAppListening, listeners } from '@dis/redux'
import { Provider } from 'react-redux'
import { Unsubscribe } from '@reduxjs/toolkit'

interface Props {
  children: ReactNode
}

export const Redux = ({ children }: Props) => {
  useEffect(() => {
    const subscriptions: Unsubscribe[] = listeners.map((listener) => listener(startAppListening))

    return () => subscriptions.forEach((unsubscribe) => unsubscribe())
  }, [])

  return <Provider store={store}>{children}</Provider>
}
