import React, { useCallback } from 'react'
import {
  Checkbox as CheckboxComponent,
  CheckboxProps as CheckboxComponentProps,
} from '@dis/components'
import { useController, Control } from 'react-hook-form'
import { CheckboxLabelPlacement } from '@dis/styles/src/theme'
import { submitByVirtualButton } from '../../utils'

export type Props = Omit<CheckboxComponentProps, 'value' | 'name'> & {
  formControl: Control<any>
  labelPlacement?: CheckboxLabelPlacement
  name: string
  shouldUnregister?: boolean
  submitOnChange?: boolean
}

export const Checkbox = ({
  name,
  formControl,
  shouldUnregister = false,
  submitOnChange = false,
  onChange: onChangeProps,
  labelPlacement,
  ...restProps
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<CheckboxComponentProps['onChange']>>(
    (e, checked) => {
      onChange(checked)
      onChangeProps?.(e, checked)

      if (submitOnChange) {
        submitByVirtualButton(e.target)
      }
    },
    [onChangeProps, onChange, submitOnChange],
  )

  return (
    <CheckboxComponent
      {...restProps}
      onChange={onChangeWrapper}
      value={value}
      errorObject={error}
      labelPlacement={labelPlacement}
    />
  )
}
