export { cx } from '@emotion/css'

export { colors } from './colors'
export {
  theme,
  generateTheme,
  getTypographyProp,
  typography,
  type TypographyKeys,
  typographyFontWeight,
} from './theme'
export * from './boxShadows'
export * from './mix'
export { hex2rgba } from './utils'

/**
 * Class Join (not class composing)
 *
 * Function cx from @emotion/css composes classNames into a new one className so CSS codes
 * like &:hover ${'.'+nestedClass} doesn't work. This function simply joins classNames so the
 * original classNames are kept unchanged.
 *
 * Class composing has better performance than class joining! So use this function slightly!
 */
export const cj = (...classNames: Array<string>) => classNames.join(' ')
