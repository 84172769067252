import { LANGUAGES } from './languages'

export * from './authConfig'

export * from './common'

export * from './languages'

export enum ErrorCode {
  GeneralError = 'GENERAL_ERROR',
  InvalidConfigJson = 'INVALID_CONFIG_JSON',
  NetworkError = 'NETWORK_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  Unauthorized = 'UNAUTHORIZED',
  UnavailableConfigJson = 'UNAVAILABLE_CONFIG_JSON',
}

export const maximumFractionDigits = {
  [LANGUAGES.en]: 2,
  [LANGUAGES.cs]: 3,
  [LANGUAGES.de]: 3,
  [LANGUAGES.fr]: 2,
  [LANGUAGES.ar]: 4,
} as const
