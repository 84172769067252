import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PopoverSort as PopoverSortComponent } from '@dis/components'
import { tKeys } from '@dis/languages'
import { AllPossibleSorters, SortType } from '@dis/types/src/PopoverFilterSortTypes'
import { atlasSortByOptions, commonSortByOptions } from './utils'

interface Props<S extends AllPossibleSorters> {
  setSortBy: React.Dispatch<React.SetStateAction<S | undefined>>
  sortBy?: S
  sortType?: SortType
}

export const PopoverSort = <S extends AllPossibleSorters>({
  sortBy,
  setSortBy,
  sortType = 'common',
}: Props<S>) => {
  const { t } = useTranslation()

  const options = useMemo(() => {
    let sortByOptions: AllPossibleSorters[] = []

    switch (sortType) {
      case 'common': {
        sortByOptions = commonSortByOptions
        break
      }

      case 'atlas': {
        sortByOptions = atlasSortByOptions
        break
      }

      case 'tenants': {
        sortByOptions = [
          'idAsc',
          'idDesc',
          'titleAZ',
          'titleZA',
          'createdOldest',
          'createdNewest',
          'updatedOldest',
          'updatedNewest',
        ]
        break
      }
    }

    return sortByOptions.map((option) => ({
      label: t(tKeys.sort.sortByOptions[option]),
      value: option,
    }))
  }, [sortType, t])

  const handleSortByChange = useCallback(
    (value: string | undefined) => {
      setSortBy(value as S)
    },
    [setSortBy],
  )

  return (
    <PopoverSortComponent onSortByChange={handleSortByChange} options={options} value={sortBy} />
  )
}
