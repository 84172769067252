import { css } from '@emotion/css'
import { colors, theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  item: {
    iconContainer: css`
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    `,
    labelColor: (color: string) => css`
      .MuiTypography-root {
        color: ${color};
      }
    `,
    labelSize: (fontSize: string, lineHeight: string) => css`
      font-size: ${fontSize};
      line-height: ${lineHeight};

      .MuiTypography-root {
        font-size: ${fontSize};
        line-height: ${lineHeight};
      }
    `,
    leftRightIconContainer: css`
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 1.5rem;
      max-width: 1.5rem;
      width: 1.5rem;
      min-height: 1.5rem;
      max-height: 1.5rem;
      height: 1.5rem;
    `,
    root: css`
      padding: ${theme.spacing(2)} ${theme.spacing(4)};
      font-weight: ${typographyFontWeight.regular};
      border-radius: 0;
      align-items: center;
      text-align: start;
      text-transform: none;
      justify-content: flex-start;
      min-height: 2.75rem;
      gap: ${theme.spacing(2)};
      min-width: 10rem;

      .MuiTypography-root {
        padding: 0;
        font-weight: ${typographyFontWeight.regular};
        border-radius: 0;
        align-items: center;
        text-align: start;
        text-transform: none;
        justify-content: flex-start;
      }

      .MuiBox-root {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing(2)};
      }

      .MuiListItemIcon-root {
        min-width: 1.5rem;
        max-width: 1.5rem;
        width: 1.5rem;
        min-height: 1.5rem;
        max-height: 1.5rem;
        height: 1.5rem;

        svg {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }
    `,
    submenu: css`
      z-index: ${theme.zIndex.snackbar};
      pointer-events: none;
    `,
    submenuContainer: css`
      display: flex;
      align-items: center;
      margin-inline-start: auto;
    `,
    submenuContent: css`
      pointer-events: auto;
    `,
    suffix: css`
      padding: 0 !important;
      margin: 0 !important;
    `,
  },
  mainButton: {
    icon: (iconSize: string) => css`
      svg {
        width: ${iconSize};
        height: ${iconSize};
      }
    `,
    iconWithMargin: css`
      margin-inline-end: ${theme.spacing(2)};
    `,
    iconWrapper: css`
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      transition: background-color 0.2s;
      border-radius: ${theme.spacing(0.5)};

      &:hover {
        background-color: ${colors.gray.gray3};
      }
    `,
    root: css`
      min-width: 0;
      text-transform: none;
      padding: 0;
      min-height: 0;
      height: auto;
      border: transparent;
      &:hover {
        background-color: transparent;
      }
      &:active {
        border: transparent;
      }
    `,
  },
  menu: css`
    z-index: ${theme.zIndex.snackbar};
  `,
}
