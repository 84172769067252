import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  container: css`
    width: 2.5rem;
    height: 1.25rem;
    padding: 0;
    display: flex;

    &:active {
      & .MuiSwitch-thumb {
        width: 15px;
      }
      & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(22px);
      }
    }

    & .MuiSwitch-switchBase {
      padding: 2px;
      &.Mui-checked {
        transform: translateX(20px);
        color: #fff;

        & + .MuiSwitch-track {
          opacity: 1;
          background-color: ${colors.kpmg.pacificBlue};
        }
      }
    }

    & .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transition: width 200ms;
    }

    & .MuiSwitch-track {
      border-radius: 10px;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
    }
  `,

  controlLabel: css`
    margin-left: 0;
    margin-right: 0;
    gap: ${theme.spacing(2)};
  `,
}
