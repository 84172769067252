import { useCallback, useId, useState, useMemo, ReactNode, useRef } from 'react'
import {
  Select as SelectComponent,
  SelectProps as SelectComponentProps,
  MenuItem,
  MenuItemProps,
  FormControl,
  FormControlProps,
  InputLabel,
  FormGroup,
  Box,
  FormLabel,
  ListSubheader,
  SvgIcon,
} from '@mui/material'
import { colors, theme, typography } from '@dis/styles'
import { nanoid } from '@reduxjs/toolkit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { cx } from '@emotion/css'
import { FieldError } from 'react-hook-form/dist/types'
import { TypographyKeys, getTypographyProp, ParagraphVariant } from '@dis/styles/src/theme'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { Paragraph } from '@dis/components'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'

const PLACEHOLDER_VALUE = nanoid()

type Option = {
  disabled?: boolean
  icon?: string
  iconColor?: string
  label: string
  labelColor?: string
  value: MenuItemProps['value']
}

type Options = Array<Option>

export type SelectProps = SelectComponentProps & {
  children?: ReactNode
  containerSx?: FormControlProps['sx']
  dropdownClasses?: string
  errorObject?: Error | FieldError
  fixedLabel?: boolean
  fixedLabelVariant?: ParagraphVariant
  fontSize?: TypographyKeys
  fullWidth?: boolean
  ghost?: boolean
  hasDropdownParentWidth?: boolean
  hasPlaceholderInList?: boolean
  menuItemSx?: MenuItemProps['sx']
  options: Options
  permanentColor?: 'error' | 'warning' | 'info' | 'success' | string
  visibleListItemCount?: number
}

export const Select = ({
  className,
  color = 'primary',
  containerSx,
  dropdownClasses,
  errorObject,
  fixedLabel,
  fixedLabelVariant = 'body',
  fontSize = 'paragraph.medium',
  fullWidth = true,
  ghost,
  hasPlaceholderInList = false,
  hasDropdownParentWidth = false,
  label,
  menuItemSx,
  onBlur: onBlurProp,
  onFocus: onFocusProp,
  options,
  permanentColor,
  placeholder,
  sx = {},
  value,
  variant = 'standard',
  children,
  visibleListItemCount,
  ...restProps
}: SelectProps) => {
  const itemId = useId()
  const labelId = useId()

  const selectRef = useRef(null)

  const [isGhostShown, setIsGhostShown] = useState(ghost)
  const [isFocused, setIsFocused] = useState(false)

  const isRtl = useAppSelector(selectIsLanguageRtl)

  let pColor = undefined

  const onFocusWrapper = useCallback<NonNullable<SelectComponentProps['onFocus']>>(
    (e) => {
      setIsFocused(true)
      onFocusProp?.(e)
    },
    [onFocusProp],
  )

  const onBlurWrapper = useCallback<NonNullable<SelectComponentProps['onBlur']>>(
    (e) => {
      setIsFocused(false)

      if (ghost) {
        setIsGhostShown(true)
      }

      onBlurProp?.(e)
    },
    [onBlurProp, ghost],
  )

  const onMouseEnterWrapper = useCallback(() => {
    setIsGhostShown(false)
  }, [])

  const onMouseLeaveWrapper = useCallback(() => {
    if (!isFocused && ghost) {
      setIsGhostShown(true)
    }
  }, [isFocused, ghost])

  switch (permanentColor) {
    case 'error':
      pColor = theme.palette.error.main
      break
    case 'warning':
      pColor = theme.palette.warning.main
      break
    case 'info':
      pColor = theme.palette.info.main
      break
    case 'success':
      pColor = theme.palette.success.main
      break
    default:
      if (permanentColor) {
        pColor = permanentColor
      }
  }

  const inputProps = {
    color,
    sx: {
      '& .MuiTypography-root:first-child': {
        fontSize: `${getTypographyProp(fontSize, 'fontSize')} !important`,
        lineHeight: `${getTypographyProp(fontSize, 'lineHeight')} !important`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap !important',
      },
      color: pColor,
      fontSize: `${getTypographyProp(fontSize, 'fontSize')} !important`,
    },
  }

  const menuItems = useMemo(
    () =>
      options?.map(
        (item) =>
          item && (
            <MenuItem
              className={styles.menuItem.root}
              value={item.value}
              key={`${item.label}_${item.value}`}
              sx={menuItemSx}
              disabled={!!item.disabled}>
              <div
                className={cx(styles.menuItem.innerContainer, { [styles.itemRow]: !!item.icon })}>
                {item.icon && (
                  <SvgImage color={item.iconColor} src={item.icon} width="1.5rem" height="1.5rem" />
                )}
                <Paragraph className={styles.menuItem.paragraph} color={item.labelColor}>
                  {item.label}
                </Paragraph>
              </div>
            </MenuItem>
          ),
      ),
    [options, menuItemSx],
  )

  const fixedLabelCss = typography.paragraph[fixedLabelVariant]

  let content = null

  const labelMargin = { mb: ghost ? 0 : 1 }

  if (isGhostShown) {
    const translatedValue = options?.find((opt) => opt.value === value)?.label || value

    content = (
      <Box
        className={cx(
          styles.ghost.root,
          { [styles.ghost.withLabel]: !fixedLabel && !!label, [styles.ghost.rtl]: isRtl },
          className,
        )}
        sx={{ ...inputProps.sx, ...sx }}>
        <>
          {translatedValue}
          <div className={cx(styles.ghost.icon, { [styles.ghost.rtlIcon]: isRtl })}>
            <SvgIcon>
              <ExpandMoreIcon />
            </SvgIcon>
          </div>
        </>
      </Box>
    )
  } else {
    content = (
      <SelectComponent
        {...restProps}
        sx={{
          '&.Mui-disabled': {
            backgroundColor: colors.gray.gray5,
          },
          '&::after': { borderColor: pColor },
          '&:hover::before': { borderColor: `${pColor} !important` },
          color: !value && placeholder ? colors.gray.gray40 : 'inherit',
          fontSize: getTypographyProp(fontSize, 'fontSize'),
          fontWeight: 'none',
          maxWidth: '100%',
          ...sx,
        }}
        variant={variant}
        color={color}
        label={fixedLabel || !label ? undefined : label}
        labelId={labelId}
        id={itemId}
        IconComponent={ExpandMoreIcon}
        componentsProps={{
          input: inputProps,
        }}
        onFocus={onFocusWrapper}
        onBlur={onBlurWrapper}
        className={className}
        value={value ?? (placeholder ? PLACEHOLDER_VALUE : '')}
        MenuProps={{
          classes: {
            list: cx(styles.menuList.root, {
              [styles.menuList.invisiblePlaceholder]: !hasPlaceholderInList,
            }),
            paper: cx(
              styles.dropdown,
              styles.dropDownMaxHeight(visibleListItemCount),
              dropdownClasses,
            ),
          },
          sx: {
            '.MuiMenu-paper': {
              maxWidth: hasDropdownParentWidth ? '0%' : undefined,
            },
          },
        }}
        ref={selectRef}>
        {children && <ListSubheader disableSticky>{children}</ListSubheader>}

        {placeholder && !value && (
          <MenuItem disabled value={PLACEHOLDER_VALUE} className={styles.menuItem.root}>
            {placeholder}
          </MenuItem>
        )}
        {menuItems}
      </SelectComponent>
    )
  }

  if (fixedLabel) {
    return (
      <FormGroup
        onMouseEnter={onMouseEnterWrapper}
        onMouseLeave={onMouseLeaveWrapper}
        sx={containerSx}>
        {!!label && (
          <FormLabel
            className={styles.formLabel}
            required={restProps.required}
            sx={{ ...fixedLabelCss, ...labelMargin }}>
            {label}
          </FormLabel>
        )}
        {content}
        <ErrorNotice error={errorObject} />
      </FormGroup>
    )
  } else {
    return (
      <FormControl
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        onMouseEnter={onMouseEnterWrapper}
        onMouseLeave={onMouseLeaveWrapper}
        sx={containerSx}>
        {!!label && (
          <InputLabel
            required={restProps.required}
            variant={variant}
            color={color}
            id={labelId}
            sx={{ '&.Mui-focused': { color: pColor }, color: pColor, labelMargin }}>
            {label}
          </InputLabel>
        )}
        {content}
        <ErrorNotice error={errorObject} />
      </FormControl>
    )
  }
}
