import Button from '@mui/material/Button'
import { Paragraph, Tile } from '@dis/components'
import { actionsContainer, colors, tileStyles } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { dateHandler } from '@dis/utils'
import { t, tKeys } from '@dis/languages'
import { MenuItem } from '@dis/components/src/PopoverWithAnchor/types'
import { Tenant } from '@dis/types/src/TenantTypes'
import { UserRole } from '@dis/types/src/UsersAndRoles'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { useTenantPage } from './hooks'

type Props = {
  menu?: Array<MenuItem>
  tenantData: Tenant
}

export const TenantCard = ({ menu, tenantData }: Props) => {
  const { description, created, name } = tenantData

  const { navigateToLandingPage } = useTenantPage()

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const actualUserRole = useAppSelector(selectUserRole)

  return (
    <Tile
      horizontalAlign="left"
      size="large"
      className={tileStyles.tile(undefined)}
      menu={menu}
      menuOnLeft
      onClick={navigateToLandingPage(tenantData)}>
      <div className={tileStyles.body}>
        <div className={tileStyles.topContainer}>
          <Paragraph
            variant="medium"
            fontWeight="bold"
            className={tileStyles.threeDotsHeader(false)}>
            {name}
          </Paragraph>
        </div>
        {actualUserRole === UserRole.KpmgAdmin && (
          <div className={tileStyles.descContainer}>
            {description && (
              <>
                <Paragraph className={tileStyles.threeDotsText}>{description}</Paragraph>
                <div className={tileStyles.descTooltipContainer} title={description} />
              </>
            )}
          </div>
        )}
        <div className={tileStyles.createdContainer}>
          <Paragraph color={colors.gray.gray40} variant="small">
            {created && t(tKeys.templateManagement.created)}: {dateHandler(created, isRtl)}
          </Paragraph>
        </div>
      </div>

      <div className={actionsContainer}>
        <Button variant="contained" color="primary">
          {t(tKeys.tenants.select)}
        </Button>
      </div>
    </Tile>
  )
}
