import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import image7 from '@dis/assets/src/images/newTutorial/7.png'
import image8 from '@dis/assets/src/images/newTutorial/8.png'
import image9 from '@dis/assets/src/images/newTutorial/9.png'
import image10 from '@dis/assets/src/images/newTutorial/10.png'
import image11 from '@dis/assets/src/images/newTutorial/11.png'
import image12 from '@dis/assets/src/images/newTutorial/12.png'
import image13 from '@dis/assets/src/images/newTutorial/13.png'
import image14 from '@dis/assets/src/images/newTutorial/14.png'
import image15 from '@dis/assets/src/images/newTutorial/15.png'
import image16 from '@dis/assets/src/images/newTutorial/16.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

const msg = tKeys.tutorials.basicElements

/* eslint-disable react/no-unescaped-entities */
export const BasicElements = ({ id }: ScrollProps) => {
  const { t } = useTranslation()
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        {t(msg.header)}
      </Header>
      <Header marginBottom={4} variant="h3">
        {t(msg.subHeader)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The application menu is located in the header (in the upper right corner) of the
        application.
      </Paragraph>

      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image7} alt="Introduction Discovery" />
      </Paragraph>

      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        Menu items vary depending on the context of which part of the application the user is
        currently in. Some items (especially the configuration section) are dependent on the user's
        permissions.
      </Paragraph>

      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image8} alt="Introduction Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        {t(msg.breadcrumbs)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        Breadcrumbs show where the user is in the application and offer him the possibility to go
        back in given steps. Depending on the type of user, the behavior of some breadcrumbs items
        changes slightly.
      </Paragraph>
      <Paragraph
        vmarginBottom={4}
        marginBottom={4}
        ariant="medium"
        className={styles.subtitleDescription}>
        <strong>KPMG user</strong>
      </Paragraph>
      <Paragraph
        marginTop={5}
        marginBottom={4}
        variant="medium"
        className={styles.subtitleDescription}>
        <img src={image9} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        If the user clicks on the kpmg logo or the house icon, he will be redirected to the "All
        tenants" screen. If the user clicks on the tenant item, he will be redirected to the{' '}
        <strong>"Landing page"</strong>.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <strong>External user</strong>
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image10} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        If the user clicks on the kpmg logo or the house icon, he will be redirected to the{' '}
        <strong>"Landing page"</strong>.
      </Paragraph>

      <Header marginTop={6} marginBottom={4} variant="h3">
        {t(msg.journeyCondition)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        When a journey is open, the header is extended with the journey status.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image11} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph variant="medium" className={styles.subtitleDescription}>
        A journey can take on the states listed below:
      </Paragraph>
      <List
        sx={{
          listStylePosition: 'inside',
          listStyleType: 'disc',
        }}>
        <ListItem sx={{ display: 'list-item' }}>Work in progress</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Published</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Deleted</ListItem>
      </List>

      <Header marginTop={5} marginBottom={4} variant="h3">
        {t(msg.reversingChanges.header)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The application offers two options to go back to an earlier version of journey (persona).
        The first option is to go back step by step using the left (back) and right (forward) arrows
        next to the Menu.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image12} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The second is to go back by a preset time period. The list of time periods that is offered
        depends on the existence of previous versions of the journey and their ages. The maximum
        offered offset is 48 hours back.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image13} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The displayed version can either be accepted or rejected by the buttons that appear next to
        the history buttons.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image14} alt="Introduction Discovery" />
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        If an older version of a journey (persona) is checked in and subsequently changed, all
        changes that were made after the version to which the journey (persona) was returned are
        deleted and a new version with new changes is created.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        Example:
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The journey (persona) is in version E
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        {t(msg.reversingChanges.example1)}
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        The user returns it to version B
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        {t(msg.reversingChanges.example2)}
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        Subsequent changes discard versions C, D and E and create a new version C1
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        {t(msg.reversingChanges.example3)}
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        {t(msg.textEditing)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        All editable texts can be edited by clicking in the box. If it is not an array where tex
        formatting is allowed (see below chap. 1.4.6), the edit is saved and closed by clicking
        outside this field.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image15} alt="Introduction Discovery" />
      </Paragraph>

      <Header marginTop={5} marginBottom={4} variant="h3">
        {t(msg.textFormatting)}
      </Header>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        As with the journey name editing above, you can edit rich text fields. Closing the editing
        of fields that have text formatting (so-called WYSIWYG fields) is done by clicking on the
        "Save" button.
      </Paragraph>
      <Paragraph marginBottom={4} variant="medium" className={styles.subtitleDescription}>
        <img src={image16} alt="Introduction Discovery" />
      </Paragraph>
    </div>
  )
}
