import { css } from '@emotion/css'

export const styles = {
  text: css`
    flex-grow: 0;
    max-width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
  `,
}
