import { Header, Paragraph, Screen } from '@dis/components'
import { useTranslation } from 'react-i18next'

import { tKeys } from '@dis/languages'

// TODO: add better design and texts
export const Error = () => {
  const { t } = useTranslation()

  return (
    <Screen>
      <Header variant="h3">{t(tKeys.errors.default.title)}</Header>
      <Paragraph variant="medium">{t(tKeys.errors.default.content)}</Paragraph>
    </Screen>
  )
}
