import { colors, shadows, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  backlogLayout: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(5)};
    background: ${colors.background.white};
    box-shadow: ${shadows[14]};
    border-radius: 8px;
  `,

  backlogSixpillarsWrapper: css`
    width: 100%;
    grid-area: Backlog;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(5)};
  `,

  sixPillar: css`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(3)};
  `,
  sixPillarsLayoutHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  sixPillarsWrapper: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${theme.spacing(15)};
    margin-top: ${theme.spacing(8)};
  `,

  tooltip: css`
    background-color: ${colors.transparent} !important;
    .MuiTooltip-arrow::before {
      border: 1px solid ${colors.gray.gray10};
    }
    .MuiTooltip-arrow {
      width: 3.75rem;
      color: ${colors.gray.gray0};
      height: 1.25rem;
      margin-top: -${theme.spacing(5)}!important;
      margin-bottom: -${theme.spacing(5)}!important;
    }
    .MuiTooltip-tooltip {
      background-color: ${colors.background.white};
      border: 1px solid ${colors.gray.gray10};
      color: ${colors.gray.gray100};
      padding: ${theme.spacing(6)};
    }
  `,
}
