import { List, ListItem } from '@mui/material'
import { Header, Paragraph } from '@dis/components'
import image17 from '@dis/assets/src/images/newTutorial/17.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const LanguageSelection = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header className={styles.headerAndText} marginTop={5} variant="h2">
        1.1 Language selection
      </Header>
      <div>
        <Paragraph variant="medium" className={styles.subtitleDescription}>
          To change language mutation, click on the upper right menu and choose "Choose language"
          option. You can choose from the supported languages below:
        </Paragraph>
        <List
          sx={{
            listStylePosition: 'inside',
            listStyleType: 'disc',
          }}>
          <ListItem sx={{ display: 'list-item' }}>English</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Czech</ListItem>
          <ListItem sx={{ display: 'list-item' }}>German</ListItem>
          <ListItem sx={{ display: 'list-item' }}>French</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Arabic</ListItem>
        </List>
      </div>

      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        By default, the language defined by the admin in the "Management of tenant" section is
        preset.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image17} width="600" alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
