import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { exportDocument } from '@dis/api'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { LoadingInfoType } from '@dis/types/src/GeneralTypes'
import { useRouteMatch } from './useRouteMatch'

export const useExportPDF = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const { isPersona } = useRouteMatch()

  const tenantId = useAppSelector(selectSelectedTenantId)
  const localeId = useAppSelector(selectLanguage)
  const personaId = useAppSelector(selectSelectedPersonaId)
  const journeyId = useAppSelector(selectSelectedJourneyId)

  const { t } = useTranslation()

  const handleExportPDF = useCallback(async () => {
    setLoadingInfo({ isLoading: true, translation: t(tKeys.exportImport.exportModal) })

    let response

    if (isPersona) {
      if (personaId) {
        response = await exportDocument('personaPdf', {
          localeId,
          personaId: personaId,
          tenantId,
        })
      }
    } else {
      response = await exportDocument('journeyPdf', { journeyId, localeId, tenantId })
    }

    if (response) {
      setLoadingInfo({ isLoading: false, status: response?.status })
    }
  }, [isPersona, journeyId, localeId, personaId, setLoadingInfo, t, tenantId])

  return { handleExportPDF }
}
