import { FC, ReactElement } from 'react'
import { Grid, GridProps } from '@mui/material'

type Props = Omit<GridProps, 'children'> & {
  children: Array<ReactElement>
  mode?: 'dashboard' | 'normal'
}

export const AtlasFolderJourneyGrid: FC<Props> = ({ children, mode = 'normal', ...rest }) => {
  const dashboardSpacing = { md: 3, xs: 2 }
  // const dashboardColumns = { md: 12, sm: 8, xl: 12, xs: 4 }
  const dashboardColumns = { md: 4, sm: 2, xl: 4, xs: 1 }

  const normalSpacing = {}
  const normalColumns: GridProps['columns'] = {
    lg: 4,
    md: 3,
    sm: 2,
    xl: 6,
    xs: 1,
  }

  return (
    <Grid
      container
      spacing={mode === 'dashboard' ? dashboardSpacing : normalSpacing}
      columns={mode === 'dashboard' ? dashboardColumns : normalColumns}
      {...rest}>
      {children.map((item, index) => (
        <Grid
          key={`atlasFolderJourneyGridItem_${item.type}_${item.props.atlasId}_${
            item.props.folderId
          }_${item.props.id || index}
          }`}
          item
          xs={1}>
          {item}
        </Grid>
      ))}
    </Grid>
  )
}
