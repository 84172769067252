import { useCallback } from 'react'
import { FormGroup } from '@mui/material'
import {
  SearchBar as SearchBarComponent,
  SearchBarProps,
} from '@dis/components/src/SearchBar/SearchBar'
import { Control, useController } from 'react-hook-form'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'

type Props = Omit<SearchBarProps, 'onChange' | 'inputValue'> & {
  formControl: Control<any>
  inputValue?: SearchBarProps['inputValue']
  name: string
  onChange?: SearchBarProps['onChange']
  shouldUnregister?: boolean
}

export const SearchBar = ({ formControl, name, shouldUnregister, ...restProps }: Props) => {
  const restPropsOnChange = restProps.onChange

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<SearchBarProps['onChange']>>(
    (e) => {
      onChange(e)
      restPropsOnChange?.(e)
    },
    [restPropsOnChange, onChange],
  )

  return (
    <FormGroup>
      <SearchBarComponent inputValue={value} onChange={onChangeWrapper} {...restProps} />
      <ErrorNotice error={error} />
    </FormGroup>
  )
}
