import { useCallback, useMemo } from 'react'
import { SchemaOf } from 'yup'
import {
  useForm as useFormOriginal,
  // Types
  UseFormProps,
  UseFormHandleSubmit,
  SubmitHandler,
} from 'react-hook-form'
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types'
import { yupResolver } from '@hookform/resolvers/yup'

export type UseJourneyFormReturn<TFieldValues extends FieldValues, TContext = any> = UseFormReturn<
  TFieldValues,
  TContext
> & {
  handleIncomingData: (data: TFieldValues) => void
}

export type UseJourneyFormProps<TFieldValues extends FieldValues, TContext = any> = UseFormProps<
  TFieldValues,
  TContext
> & {
  resetOnSubmit?: boolean
  validationSchema: SchemaOf<TFieldValues>
}

/**
 * `useJourneyForm` hook adds `resetOnSubmit` and `handleIncomingData` to the `useForm` hook; meant to be used for forms editable by multiple users at once
 *
 * **LIMITATIONS**
 * - Only supports 1-dimensional data structure
 */
export const useJourneyForm = <TFieldValues extends FieldValues, TContext = any>({
  validationSchema,
  resetOnSubmit = true,
  ...props
}: UseJourneyFormProps<TFieldValues, TContext>): UseJourneyFormReturn<TFieldValues, TContext> => {
  const {
    formState,
    reset,
    handleSubmit: originalHandleSubmit,
    ...restForm
  } = useFormOriginal<TFieldValues, TContext>({
    ...props,
    resolver: yupResolver(validationSchema.required()),
  })

  // A wrapper for handleSubmit that inherently resets the isDirty state to false
  // to allow for better comparisons
  const handleSubmit: UseFormHandleSubmit<TFieldValues> = useCallback(
    (onSubmit) => {
      if (!resetOnSubmit) return originalHandleSubmit(onSubmit)

      const wrappedOnSubmit: SubmitHandler<TFieldValues> = (data, event) => {
        onSubmit(data, event)
        reset({} as any, { keepValues: true })
      }

      return originalHandleSubmit(wrappedOnSubmit)
    },
    [originalHandleSubmit, reset, resetOnSubmit],
  )

  // Use handleIncomingData to update the form values when the data changes
  // instead of setValue or reset
  const handleIncomingData = useMemo(() => {
    return (data: TFieldValues) => {
      const fieldValues = restForm.getValues()

      Object.keys(data).forEach((inputName) => {
        const { dirtyFields } = formState
        const isDirty = dirtyFields[inputName] ?? false
        const isDifferent = fieldValues[inputName] !== data[inputName]

        if (!isDirty && isDifferent) {
          restForm.setValue<any>(inputName, data[inputName])
        }
      })
    }
  }, [formState, restForm])

  return {
    formState,
    handleIncomingData,
    handleSubmit,
    reset,
    ...restForm,
  } as const
}
