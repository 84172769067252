import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = css`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${colors.background.white};
  opacity: 0.4;
  z-index: ${theme.zIndex.modal};
`
