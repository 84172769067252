import AtlasIcon from '@dis/assets/src/icons/AtlasIcon.svg'
import FolderIconDark from '@dis/assets/src/icons/FolderIconDark.svg'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import { SvgImage } from '@dis/components'
import { colors } from '@dis/styles'

export const icons = {
  atlas: <SvgImage color={colors.gray.gray60} src={AtlasIcon} width="1rem" height="1rem" />,
  folder: <SvgImage src={FolderIconDark} width="1rem" height="1rem" />,
  journey: <SvgImage color={colors.gray.gray60} src={JourneyIcon} width="1rem" height="1rem" />,
}

export const MAX_ATLAS_FOLDER_VISIBLE_LENGTH = 20
