import { Buffer } from 'buffer'
import { ExtendedAxiosResponse } from '@dis/types/src/api'
import { GetCroppedImageResponse } from '@dis/types/src/AxiosTypes'
import { AxiosInstances } from './axios'
import { getCroppedImageUrl, getFileUrl, uploadFileUrl } from './endpoints'

export const sendUploadFile = async (tenantId: number, data?: object) => {
  try {
    const response = await AxiosInstances.axiosInstance.post<any, ExtendedAxiosResponse<number>>(
      uploadFileUrl(tenantId),
      data,
    )
    return response
  } catch (err: any) {
    console.error(`An error "${err}" has occurred during sendUploadFile()`)
    throw err
  }
}

// Error handling is in axios interceptors
export const getFile = async (
  tenantId?: number,
  fileId?: number,
): Promise<ExtendedAxiosResponse> => {
  const url = getFileUrl(tenantId, fileId)

  return await AxiosInstances.axiosInstance.get(url)
}

export const getImage = async ({
  url,
  fileId,
  tenantId,
}: {
  fileId?: number
  tenantId?: number
  url?: string
}) =>
  new Promise<string>((resolve, reject) => {
    const selectedUrl = url || getFileUrl(tenantId, fileId)

    if (selectedUrl) {
      AxiosInstances.axiosGetImageInstance.get(selectedUrl).then(
        (response) => {
          if (response.data) {
            return resolve(Buffer.from(response.data).toString('base64'))
          }

          return resolve('')
        },
        (error) => {
          console.error('getImage error: ', error)
          reject(error)
        },
      )
    } else {
      return resolve('')
    }
  })

export const getCroppedImage = async (tenantId: number, fileId: number) => {
  try {
    const { data } = await AxiosInstances.axiosInstance.get<GetCroppedImageResponse>(
      getCroppedImageUrl(tenantId, fileId),
    )
    return data.croppedImage
  } catch (err: any) {
    console.warn(`An error "${err}" has occurred during getCroppedImage()`)
    throw err
  }
}
