import React from 'react'
import { Grid } from '@mui/material'
import { SearchResultOthers } from '@dis/redux/src/search/searchTypes'
import { SearchTypes } from '@dis/types/src/SearchTypes'
import TitleContainer from './TitleContainer'
import Card from './Card'
import { styles } from './styles'

type DataMapperProps = {
  data: SearchResultOthers[]
  icon: React.ReactNode
  title: string
  type: SearchTypes
}

export const DataMapper = ({ data, title, icon, type }: DataMapperProps) => {
  return (
    <div className={styles.dataMapperContainer}>
      {data.length > 0 && <TitleContainer title={title} />}
      <Grid container rowGap={10}>
        {data?.map((item) => {
          return (
            <Grid item key={item.id} xs={12} md={1.5} lg={1.5} xl={1}>
              <Card
                name={item.name}
                icon={icon}
                title={title}
                type={type}
                atlasId={item?.atlasId}
                folderId={item?.folderId}
                journeyId={item?.journeyId}
                personaId={item?.personaId}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
