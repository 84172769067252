import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { ids, Personas } from '../tutorialConstants'
import { NewPersona } from './NewPersona'
import { DeletePersona } from './DeletePersona'
import { PersonaInformation } from './PersonaInformation'
import { AddingPersonaToJourney } from './AddingPersonaToJourney'
import { RemovePersona } from './RemovePersona'
import { AddingPersonaToJourneyLayer } from './AddingPersonaToJourneyLayer'
import { RemovePersonaFromJourneyLayer } from './RemovePersonaFromJourneyLayer'
import { useScroll } from '../useScroll'

export const TutorialPersonas = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}
          </Button>
          <TutorialSidebarContent
            initial={ids.personas}
            onSelectedItemChange={() => ids.personas}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader
              header={t(tKeys.tutorials.tutorialPersonasTitleScreen)}
              description={t(tKeys.tutorials.tutorialPersonasDescriptionScreen)}
            />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Personas.NewPersona)}>
                1.1 Creating a new persona
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.DeletePersona)}
                className={styles.menuText}>
                1.2 Deleting a persona
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.PersonaInformation)}
                className={styles.menuText}>
                1.3 Filling in the persona information
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.AddingPersona)}
                className={styles.menuText}>
                1.4 Adding a persona to a journey
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.RemovePersona)}
                className={styles.menuText}>
                1.5 Removing a persona from a journey
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.AddingPersonaLayer)}
                className={styles.menuText}>
                1.6 Adding a persona to a journey layer
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Personas.RemovePersonaLayer)}
                className={styles.menuText}>
                1.7 Removing a persona from a journey layer
              </ListItem>
            </List>
            <NewPersona id={Personas.NewPersona} />
            <DeletePersona id={Personas.DeletePersona} />
            <PersonaInformation id={Personas.PersonaInformation} />
            <AddingPersonaToJourney id={Personas.AddingPersona} />
            <RemovePersona id={Personas.RemovePersona} />
            <AddingPersonaToJourneyLayer id={Personas.AddingPersonaLayer} />
            <RemovePersonaFromJourneyLayer id={Personas.RemovePersonaLayer} />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
