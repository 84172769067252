import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { dispatchedActions, store, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId, selectTenantData } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import {
  ChangeSummaryCallback,
  PersonaChangeNotification,
  PersonaDetailType,
  PersonaDocumentList,
  PersonaPathType,
} from '@dis/types/src/PersonaTypes'
import { AdministrationTenant, TenantDashboard, TenantList } from '@dis/types/src/TenantTypes'
import { AtlasDetail, GroupManagement } from '@dis/types/src/AtlasTypes'
import {
  WsCacheEnum,
  WsEnhancedErrorMessage,
  WsEnhancedResponseMessage,
  WsMessageModel,
  WsOperationType,
  WsSubscribeCallbacks,
  WsSubscribeDataSpec,
  WsSubscribeMessageModel,
} from '@dis/types/src/wsModels'
import { CapabilityList, Sector } from '@dis/types/src/CapabilityTypes'
import { KpmgUserTenants, UserGroups } from '@dis/types/src/UsersAndRoles'
import { Folder } from '@dis/types/src/FolderTypes'
import {
  selectIsAuthenticated,
  selectUserOid,
  selectUserRole,
} from '@dis/redux/src/security/selectors'
import {
  WsSubscribeUseJourney,
  WsSubscribeUseLocalOrGlobalTemplates,
  WsSubscribeUsePublishedChannels,
  WsSubscribeUsePublishedTemplates,
  WsSubscribeUseRecycleBin,
  WsSubscribeUseTenantPersonas,
  WsSubscribeUseUserStoryList,
} from '@dis/types/src/wsSubscribeModels'
import { JourneyListItem } from '@dis/types/src/JourneyTypes'
import { Api } from '@dis/api'
import { Category, CategoryList, TemplateEnum } from '@dis/types/src/TemplateTypes'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { SectorUnion } from '@dis/modules/src/ConnectedCapabilities/constants'
import { languages } from '@dis/languages/src/translations/languages'
import { LANGUAGES } from '@dis/constants'
import { selectWebsocketConnectionId } from '@dis/redux/src/api/selectors'
import { useTypedParams } from './useTypedParams'
import { useErrorCodeModal } from './useErrorCodeModal'
import { useRouteMatch } from './useRouteMatch'

type UseGeneralProps<Data extends WsSubscribeDataSpec> = {
  autoRefetch?: Partial<Record<WsOperationType, boolean>>
  cache?: `${WsCacheEnum}`
  callbacks?: WsSubscribeCallbacks<Data>
  loadingSpinner?: boolean
  readSideloads?: Partial<Record<WsOperationType, boolean>>
  readVersion?: Partial<Record<WsOperationType, boolean>>
  useInternalErrorHandling?: boolean
}

type UseSubscribeProps<Data extends WsSubscribeDataSpec> =
  | (UseGeneralProps<Data> &
      WsSubscribeMessageModel['subscribe'] & {
        tenantId?: WsSubscribeMessageModel['tenantId']
      })
  | undefined

export const useSubscribe = <Data extends WsSubscribeDataSpec>(props: UseSubscribeProps<Data>) => {
  const [data, setData] = useState<Data>()
  const [version, setVersion] = useState(0)
  const [isBackEnabled, setIsBackEnabled] = useState(false)
  const [isForwardEnabled, setIsForwardEnabled] = useState(false)
  const [sideloads, setSideloads] = useState<any>()

  const [error, setError] = useState<WsEnhancedErrorMessage | undefined>()
  const [loading, setLoading] = useState(false)
  const [iteration, setIteration] = useState(1)

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const websocketConnectionId = useAppSelector(selectWebsocketConnectionId)

  const useInternalErrorHandling = props?.useInternalErrorHandling ?? true

  const { model, id, loadingSpinner = true, tenantId } = { ...props }

  const callbacks = useRef(props?.callbacks)
  callbacks.current = props?.callbacks

  const autoRefetch = useRef(props?.autoRefetch)
  autoRefetch.current = props?.autoRefetch

  const readVersion = useRef(props?.readVersion)
  readVersion.current = props?.readVersion

  const readSideloads = useRef(props?.readSideloads)
  readSideloads.current = props?.readSideloads

  const cache = useRef(props?.cache)
  cache.current = props?.cache

  const refetch = useCallback(() => {
    setIteration(Date.now())
  }, [])

  const callbackWrappers = useMemo(() => {
    const callbackWrappers: WsSubscribeCallbacks<Data> = {}

    // If "props.model" is specified then the props.callbacks should be present also and it's possible to generate callbacks wrappers
    if (model) {
      // WsOperationType.Subscribe must be always present
      const operationTypesSet = new Set([
        ...Object.keys(callbacks?.current || {}),
        ...Object.keys(autoRefetch?.current || {}),
        WsOperationType.Subscribe,
      ])

      operationTypesSet.forEach((operationTypeString) => {
        const operationType = operationTypeString as WsOperationType

        callbackWrappers[operationType] = (data?: WsEnhancedResponseMessage, err?: any) => {
          if (operationType === 'subscribe') {
            if (err) {
              callbacks?.current?.subscribe?.(undefined)

              setData((previousState) => {
                return {
                  ...previousState,
                  subscribe: undefined,
                } as Data
              })

              setError(err)
            } else {
              setError(undefined)

              setData((previousState) => {
                return {
                  ...previousState,
                  subscribe: data?.data,
                } as Data
              })

              if (readVersion?.current?.subscribe) {
                setVersion(data?.version || 0)
                setIsBackEnabled(data?.backenable || false)
                setIsForwardEnabled(data?.forwardenable || false)
              }

              if (readSideloads.current?.subscribe) {
                setSideloads(data?.sideloads)
              }

              callbacks?.current?.subscribe?.(data)
            }

            setLoading(false)
          } else {
            if (callbacks?.current?.[operationType]) {
              callbacks?.current[operationType]?.(data, err)
            }

            if (readVersion?.current?.[operationType]) {
              setVersion(data?.version || 0)
              setIsBackEnabled(data?.backenable || false)
              setIsForwardEnabled(data?.forwardenable || false)
            }

            if (readSideloads.current?.[operationType]) {
              setSideloads(data?.sideloads)
            }

            if (autoRefetch?.current?.[operationType]) {
              refetch()
            }
          }
        }
      })
    }

    return callbackWrappers
  }, [model, refetch])

  useEffect(() => {
    if (isAuthenticated && iteration && model && websocketConnectionId) {
      setLoading(true)
      setError(undefined)

      const unsubscribe = Api.subscribe<Data>(
        {
          [WsOperationType.Subscribe]: {
            id: id || '',
            model: model,
          },
          tenantId: tenantId || 0,
          useInternalErrorHandling,
        },
        callbackWrappers,
        cache.current,
      )

      return () => {
        const { websocketConnectionId: actualWebsocketConnectionId } = store.getState().api

        /**
         * In network outage case the actualWebsocketConnectionId is undefined so avoid calling unsubscribe because
         * after network outage recovery re-subscribes are called so unsubscribe is not necessary.
         */
        if (actualWebsocketConnectionId) {
          unsubscribe()
        }
      }
    }
  }, [
    id,
    isAuthenticated,
    iteration,
    model,
    tenantId,
    callbackWrappers,
    useInternalErrorHandling,
    websocketConnectionId,
  ])

  useEffect(() => {
    if (isAuthenticated && loading && loadingSpinner) {
      const modal = dispatchedActions.centralModalLoader.showModalLoader()

      return () => {
        dispatchedActions.centralModalLoader.hideModalLoader(modal)
      }
    }
  }, [isAuthenticated, loading, loadingSpinner, props])

  return {
    data,
    error,
    isBackEnabled,
    isForwardEnabled,
    loading,
    refetch,
    sideloads,
    version,
  }
}

type UseTenantDataSpec = {
  [WsOperationType.Change]: AdministrationTenant
  [WsOperationType.Create]: unknown
  [WsOperationType.Delete]: unknown
  [WsOperationType.Subscribe]: AdministrationTenant
}

/**
 * Tenant data are stored in the redux.
 * Changes in groups/permissions are not sent from BE automatically so to receive fresh list of atlases it's
 * necessary to re-call this hook again in the React components or call "refetch" callback.
 */
export const useTenant = (
  props?: UseGeneralProps<UseTenantDataSpec> & {
    forceTenantId?: number
    useInternalErrorHandling?: boolean
  },
) => {
  const [localTenantData, setLocalTenantData] = useState<AdministrationTenant>()

  const reduxTenantData = useAppSelector(selectTenantData)
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const saveDataToReduxRef = useRef(!props?.forceTenantId)
  saveDataToReduxRef.current = !props?.forceTenantId

  const useInternalErrorHandling = props?.useInternalErrorHandling ?? true

  // Keep ?? operator because sometimes the forceTenantId has a temporary value 0 o avoid subscribe call
  const tenantId = props?.forceTenantId ?? selectedTenantId

  const tenantIdRef = useRef(tenantId)
  tenantIdRef.current = tenantId

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const { t } = useTranslation()

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<AdministrationTenant>) => {
      if (data?.data && Number(data?.id) === tenantIdRef.current) {
        if (saveDataToReduxRef.current) {
          dispatchedActions.tenants.setTenantData(data.data)
        } else {
          setLocalTenantData(data.data)
        }
      }

      onChangeData?.current?.(data)
    },
    [setLocalTenantData],
  )

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<AdministrationTenant>) => {
      if (data?.data && Number(data?.id) === tenantIdRef.current) {
        if (saveDataToReduxRef.current) {
          dispatchedActions.tenants.setTenantData(data.data)
        } else {
          setLocalTenantData(data.data)
        }
      }

      onSubscribeData?.current?.(data)
    },
    [],
  )

  const { error, loading, refetch } = useSubscribe<UseTenantDataSpec>(
    tenantId
      ? {
          ...props,
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model: WsMessageModel.Tenant,
          tenantId,
          useInternalErrorHandling: false,
        }
      : undefined,
  )

  useErrorCodeModal({
    defaultContent: t(tKeys.errors.cannotLoadTenantData),
    disabled: !useInternalErrorHandling,
    errorCode: error?.error?.code,
    hasCrossClose: true,
    primaryBtnText: t(tKeys.common.accept),
    priority: 'mix',
    title: t(tKeys.errors.default.title),
  })

  return {
    error,
    loading,
    refetch,
    tenantData: !saveDataToReduxRef.current ? localTenantData : reduxTenantData,
  }
}

export type UseAtlasDataSpec = {
  [WsOperationType.Subscribe]: AtlasDetail
}

// TODO: Check BE and then update FE to avoid the refetch calling
export const useAtlas = (props?: UseGeneralProps<UseAtlasDataSpec>) => {
  const { atlasId } = useTypedParams()
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const { data, ...rest } = useSubscribe<UseAtlasDataSpec>(
    selectedTenantId && atlasId
      ? {
          ...props,
          id: atlasId.toString(),
          model: WsMessageModel.Atlas,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...rest,
    subscribeData: data?.subscribe,
  }
}

// TODO: fix type
type UseAtlasGroupManagementDataSpec = {
  [WsOperationType.Subscribe]: GroupManagement
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: GroupManagement
  [WsOperationType.Delete]: unknown
}

type UseAtlasGroupManagementProps = UseGeneralProps<UseAtlasGroupManagementDataSpec> & {
  forceTenantId?: number
}

// Returns all groups for all users for selected tenantId
export const useAtlasGroupManagement = (props?: UseAtlasGroupManagementProps) => {
  const [groupManagement, setGroupManagement] = useState<GroupManagement>()

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const refetchWrapper = useRef(() => {})

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const { t } = useTranslation()

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<GroupManagement>) => {
    if (data?.data) {
      setGroupManagement(data.data)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<GroupManagement>) => {
      if (data?.data) {
        setGroupManagement(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [],
  )

  // Keep ?? operator because sometimes the forceTenantId has a temporary value 0 o avoid subscribe call
  const tenantId = props?.forceTenantId ?? selectedTenantId

  const { data, refetch, ...rest } = useSubscribe<UseAtlasGroupManagementDataSpec>(
    tenantId
      ? {
          ...props,
          autoRefetch: props?.autoRefetch || {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model: WsMessageModel.AtlasGroupManagement,
          tenantId,
          useInternalErrorHandling: false,
        }
      : undefined,
  )
  refetchWrapper.current = refetch

  useErrorCodeModal({
    defaultContent: t(tKeys.errors.cannotLoadGroupList),
    errorCode: rest?.error?.error?.code,
    priority: 'mix',
  })

  return {
    ...rest,
    groupManagement,
    refetch: refetchWrapper.current,
    subscribeData: data?.subscribe,
  }
}

// TODO: fix type
type UseTenantListDataSpec = {
  [WsOperationType.Subscribe]: TenantList
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: TenantList
  [WsOperationType.Delete]: unknown
}

export const useTenantList = (
  props?: UseGeneralProps<UseTenantListDataSpec> & { semaphore?: boolean },
) => {
  const [tenantList, setTenantList] = useState<TenantList['tenants']>()

  const refetchWrapper = useRef(() => {})

  const semaphore = props?.semaphore ?? true

  const userRole = useAppSelector(selectUserRole)
  const oid = useAppSelector(selectUserOid)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<TenantList>) => {
    if (data?.data?.tenants) {
      setTenantList(data.data.tenants)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<TenantList>) => {
      if (data?.data?.tenants) {
        setTenantList(data.data.tenants)
      }

      onSubscribeData?.current?.(data)
    },
    [onSubscribeData],
  )

  const { data, refetch, ...rest } = useSubscribe<UseTenantListDataSpec>(
    semaphore && oid && (userRole === 'kpmgadmin' || userRole === 'kpmguser')
      ? {
          ...props,
          autoRefetch: {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: oid,
          model: WsMessageModel.TenantList,
        }
      : undefined,
  )
  refetchWrapper.current = refetch

  return {
    ...rest,
    refetch,
    subscribeData: data?.subscribe?.tenants,
    tenantList,
  }
}

export const useArchivedTenants = (
  props?: UseGeneralProps<UseTenantListDataSpec> & { semaphore?: boolean },
) => {
  const [archivedTenantList, setArchivedTenantList] = useState<TenantList['archivedTenants']>()

  const refetchWrapper = useRef(() => {})

  const semaphore = props?.semaphore ?? true

  const userRole = useAppSelector(selectUserRole)
  const oid = useAppSelector(selectUserOid)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<TenantList>) => {
    if (data?.data?.archivedTenants) {
      setArchivedTenantList(data.data.archivedTenants)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<TenantList>) => {
      if (data?.data?.archivedTenants) {
        setArchivedTenantList(data.data.archivedTenants)
      }

      onSubscribeData?.current?.(data)
    },
    [onSubscribeData],
  )

  const { data, refetch, ...rest } = useSubscribe<UseTenantListDataSpec>(
    semaphore && oid && (userRole === 'kpmgadmin' || userRole === 'kpmguser')
      ? {
          ...props,
          autoRefetch: {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: oid,
          model: WsMessageModel.TenantList,
        }
      : undefined,
  )
  refetchWrapper.current = refetch

  return {
    ...rest,
    archivedTenantList,
    refetch,
    subscribeData: data?.subscribe?.archivedTenants,
  }
}

type UseTenantDashboardDataSpec = {
  [WsOperationType.Subscribe]: TenantDashboard
  [WsOperationType.Change]: TenantDashboard
}

export const useTenantDashboard = (props?: UseGeneralProps<UseTenantDashboardDataSpec>) => {
  const [dashboardData, setDashboardData] = useState<TenantDashboard>()

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<TenantDashboard>) => {
    if (data?.data) {
      setDashboardData(data.data)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<TenantDashboard>) => {
      if (data?.data) {
        setDashboardData(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [onSubscribeData],
  )

  const ret = useSubscribe<UseTenantDashboardDataSpec>(
    selectedTenantId
      ? {
          ...props,
          callbacks: {
            ...props?.callbacks,
            [WsOperationType.Change]: onChangeDataWrapper,
            [WsOperationType.Subscribe]: onSubscribeDataWrapper,
          },
          id: selectedTenantId.toString(),
          model: WsMessageModel.TenantDashboard,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...ret,
    dashboardData,
  }
}

type UseJourneyListDataSpec = {
  [WsOperationType.Subscribe]: JourneyListItem[]
  [WsOperationType.Change]: JourneyListItem[]
}

export const useJourneyList = (props?: UseGeneralProps<UseJourneyListDataSpec>) => {
  const [journeyList, setJourneyList] = useState<JourneyListItem[]>([])
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onDataReceived = useCallback((data?: WsEnhancedResponseMessage<JourneyListItem[]>) => {
    if (data?.data) setJourneyList(data.data)
  }, [])

  useSubscribe<UseJourneyListDataSpec>(
    selectedTenantId
      ? {
          ...props,
          callbacks: {
            change: onDataReceived,
            subscribe: onDataReceived,
          },
          id: selectedTenantId.toString(),
          model: WsMessageModel.DocumentList,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    journeyList,
  }
}

type UseTenantPersonasDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUseTenantPersonas
  [WsOperationType.Change]: WsSubscribeUseTenantPersonas
}

export const useTenantPersonas = (props?: UseGeneralProps<UseTenantPersonasDataSpec>) => {
  const [tenantPersonas, setTenantPersonas] = useState<
    NonNullable<WsSubscribeUseTenantPersonas['personas']>
  >([])
  const [connectedDocuments, setConnectedDocuments] = useState<
    NonNullable<WsSubscribeUseTenantPersonas['connectedDocuments']>
  >({})

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onData = useCallback(
    (operation: WsOperationType) =>
      (data?: WsEnhancedResponseMessage<WsSubscribeUseTenantPersonas>) => {
        setTenantPersonas(data?.data?.personas || [])
        setConnectedDocuments(data?.data?.connectedDocuments || {})

        switch (operation) {
          case 'subscribe':
            onSubscribeData.current?.(data)
            break
          case 'change':
            onChangeData.current?.(data)
            break
        }
      },
    [],
  )

  const { loading, refetch, error } = useSubscribe<UseTenantPersonasDataSpec>(
    selectedTenantId
      ? {
          ...props,
          autoRefetch: {
            create: true,
            delete: true,
            move: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onData(WsOperationType.Change),
            subscribe: onData(WsOperationType.Subscribe),
          },
          id: selectedTenantId.toString(),
          model: WsMessageModel.TenantPersonas,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    connectedDocuments,
    error,
    loading,
    refetch,
    tenantPersonas,
  }
}

type UseTenantPersonaDataSpec = {
  [WsOperationType.Change]: PersonaChangeNotification
  [WsOperationType.Delete]: any
  [WsOperationType.Subscribe]: PersonaDetailType
  [WsOperationType.Back]: PersonaDetailType
  [WsOperationType.Forward]: PersonaDetailType
  [WsOperationType.Revert]: PersonaDetailType
  [WsOperationType.Create]: PersonaDetailType
}

export const useTenantPersona = (
  props?: UseGeneralProps<UseTenantPersonaDataSpec> & {
    callbacks?: UseGeneralProps<UseTenantPersonaDataSpec>['callbacks'] & {
      changeSummary?: ChangeSummaryCallback
    }
    semaphore?: boolean
  },
) => {
  const [persona, setPersona] = useState<PersonaDetailType>()

  const semaphore = props?.semaphore ?? true

  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedPersonaId = useAppSelector(selectSelectedPersonaId)

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const changeSummaryCallback = useRef(props?.callbacks?.changeSummary)
  changeSummaryCallback.current = props?.callbacks?.changeSummary

  const callbacksRef = useRef<Partial<Record<`${WsOperationType}`, any>>>({
    back: props?.callbacks?.back,
    create: props?.callbacks?.create,
    forward: props?.callbacks?.forward,
    revert: props?.callbacks?.revert,
    subscribe: props?.callbacks?.subscribe,
  })
  callbacksRef.current.subscribe = props?.callbacks?.subscribe
  callbacksRef.current.forward = props?.callbacks?.forward
  callbacksRef.current.back = props?.callbacks?.back
  callbacksRef.current.revert = props?.callbacks?.revert
  callbacksRef.current.create = props?.callbacks?.create

  const onFullDataDataWrapper = useCallback(
    (operation: WsOperationType) => (data?: WsEnhancedResponseMessage<PersonaDetailType>) => {
      if (data?.data) {
        setPersona(data?.data)

        callbacksRef.current[operation]?.(data)
      }
    },
    [],
  )

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<PersonaChangeNotification>) => {
      if (data?.data) {
        setPersona((previousData) => {
          if (previousData && data?.data) {
            const changes: Parameters<ChangeSummaryCallback>[0] = []

            // Deep copy
            const newData = JSON.parse(JSON.stringify(previousData))

            data?.data?.forEach(({ path, operation, value }) => {
              if (path === 'info') {
                if (operation === 'update') {
                  Object.entries(value).forEach(([key, value]) => {
                    if (!newData?.info) {
                      newData.info = {}
                    }

                    newData.info[key] = value

                    changes.push({
                      cardName: 'info',
                      guid: '',
                      operation: 'update',
                      path: key,
                      value,
                    })
                  })
                } else {
                  console.warn(
                    `useTenantPersona - onChangeDataWrapper: Missing implementation for operation ${operation}`,
                  )
                }
              } else {
                const pathParts = path.split('/') as PersonaPathType[]

                pathParts.forEach((cardName) => {
                  switch (operation) {
                    case 'delete': {
                      const matches = path.match(/(guid:)(.+)$/)
                      const guid = matches?.at(2)

                      if (guid) {
                        const index = newData[cardName]?.findIndex(
                          (infoItem: Record<string, any>) => infoItem.guid === guid,
                        )

                        if (index !== undefined && index >= 0) {
                          ;(newData[cardName] as Array<any>).splice(index, 1)

                          changes.push({
                            cardName,
                            guid,
                            operation: 'delete',
                            path: undefined,
                          })
                        }
                      }
                      break
                    }

                    case 'update': {
                      const guid = value.guid
                      if (guid) {
                        const index = newData[cardName]?.findIndex(
                          (infoItem: Record<string, any>) => infoItem.guid === guid,
                        )

                        if (index !== undefined && index >= 0) {
                          Object.entries(value).forEach(([key, value]) => {
                            newData[cardName][index][key] = value

                            changes.push({
                              cardName,
                              guid,
                              operation: 'update',
                              path: key,
                              value,
                            })
                          })
                        }
                      }

                      break
                    }

                    case 'insert': {
                      const guid = value.guid
                      if (guid) {
                        const index = newData[cardName]?.findIndex(
                          (infoItem: Record<string, any>) => infoItem.guid === guid,
                        )

                        if (index === undefined || index < 0) {
                          const position =
                            parseInt(value.position) || newData[cardName]?.length || 0

                          newData[cardName][position] = value

                          changes.push({
                            cardName,
                            guid,
                            operation: 'insert',
                            path: undefined,
                            value,
                          })
                        }
                      }

                      break
                    }
                  }
                })
              }
            })

            changeSummaryCallback?.current?.(changes, newData)

            return newData
          }

          return previousData
        })

        onChangeData?.current?.(data)
      }
    },
    [],
  )

  const { data: _data, ...rest } = useSubscribe<UseTenantPersonaDataSpec>(
    selectedTenantId && selectedPersonaId && semaphore
      ? {
          ...props,
          callbacks: {
            [WsOperationType.Change]: onChangeDataWrapper,
            [WsOperationType.Subscribe]: onFullDataDataWrapper(WsOperationType.Subscribe),
            [WsOperationType.Forward]: onFullDataDataWrapper(WsOperationType.Forward),
            [WsOperationType.Back]: onFullDataDataWrapper(WsOperationType.Back),
            [WsOperationType.Revert]: onFullDataDataWrapper(WsOperationType.Revert),
            [WsOperationType.Create]: onFullDataDataWrapper(WsOperationType.Create),
            [WsOperationType.Delete]: props?.callbacks?.delete,
          },
          id: selectedPersonaId.toString(),
          model: WsMessageModel.Persona,
          readVersion: {
            back: true,
            change: true,
            forward: true,
            revert: true,
            subscribe: true,
          },
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...rest,
    persona,
  }
}

type UsePersonaConnectedJourneysDataSpec = {
  [WsOperationType.Subscribe]: PersonaDocumentList
}

export const usePersonaConnectedJourneys = (
  props?: UseGeneralProps<UsePersonaConnectedJourneysDataSpec>,
) => {
  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedPersonaId = useAppSelector(selectSelectedPersonaId)

  const { data, ...rest } = useSubscribe<UsePersonaConnectedJourneysDataSpec>(
    selectedTenantId && selectedPersonaId
      ? {
          ...props,
          autoRefetch: {
            change: true,
            create: true,
            delete: true,
          },
          id: selectedPersonaId.toString(),
          model: WsMessageModel.PersonaDocuments,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...rest,
    connectedJourneys: data?.subscribe?.documents || [],
  }
}

type UseFolderDataSpec = {
  [WsOperationType.Subscribe]: Folder
}

export const useFolder = (props?: UseGeneralProps<UseFolderDataSpec>) => {
  const { folderId } = useTypedParams()
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const { data, ...rest } = useSubscribe<UseFolderDataSpec>(
    selectedTenantId && folderId
      ? {
          ...props,
          id: folderId.toString(),
          model: WsMessageModel.Folder,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...rest,
    subscribeData: data?.subscribe,
  }
}

type UseRecycleBinDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUseRecycleBin
}

export const useArchivedJourneys = (props?: UseGeneralProps<UseRecycleBinDataSpec>) => {
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const ret = useSubscribe<UseRecycleBinDataSpec>(
    selectedTenantId
      ? {
          ...props,
          id: selectedTenantId.toString(),
          model: WsMessageModel.RecycleBin,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...ret,
    subscribeData: ret.data?.subscribe?.documents,
  }
}

// TODO: fix type
type UseUserStoryListDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUseUserStoryList
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: WsSubscribeUseUserStoryList
  [WsOperationType.Delete]: unknown
}

export const useUserStoryList = (
  props?: UseGeneralProps<UseUserStoryListDataSpec> & { semaphore?: boolean },
) => {
  const [userStoryList, setUserStoryList] = useState<WsSubscribeUseUserStoryList['userstories']>()

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const semaphore = props?.semaphore ?? true

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUseUserStoryList>) => {
      if (data?.data) {
        setUserStoryList(data.data?.userstories)
      }

      onChangeData?.current?.(data)
    },
    [],
  )

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUseUserStoryList>) => {
      if (data?.data) {
        setUserStoryList(data.data?.userstories)
      }

      onSubscribeData?.current?.(data)
    },
    [],
  )

  const ret = useSubscribe<UseUserStoryListDataSpec>(
    selectedTenantId && semaphore
      ? {
          ...props,
          autoRefetch: props?.autoRefetch || {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: selectedTenantId.toString(),
          model: WsMessageModel.UserStoryList,
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  return {
    ...ret,
    subscribeData: ret.data?.subscribe?.userstories,
    userStoryList,
  }
}

type UseCapabilityListDataSpec = {
  [WsOperationType.Subscribe]: CapabilityList
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: CapabilityList
  [WsOperationType.Delete]: unknown
}

/**
 * Create text placeholders for missing translations
 */
const fixSubcapabilityTextList = (sector: Sector) => {
  sector.subcapabilities.forEach((subcapability) => {
    Object.keys(languages).forEach((language) => {
      const placeholder =
        subcapability.text.find(({ lang }) => lang === LANGUAGES.en) ||
        subcapability.text.find(({ name }) => name?.trim() !== '')

      if (!subcapability.text.find(({ lang }) => lang === language)) {
        subcapability.text.push({
          desc: '',
          lang: language,
          name: placeholder?.name || '',
        })
      }
    })
  })
}

/**
 * Returns capability list according to the props.forceSectorCode or according to the default tenantId placed in redux.
 */
export const useCapabilityList = (
  props?: Partial<UseGeneralProps<UseCapabilityListDataSpec>> & {
    forceSectorCode?: SectorUnion
    isManagement?: boolean
    semaphore?: boolean
  },
) => {
  const [capabilityList, setCapabilityList] = useState<CapabilityList>()

  const tenantId = useAppSelector(selectSelectedTenantId)

  const sectorCode = props?.forceSectorCode

  const isManagement = props?.isManagement ?? false

  const semaphore = props?.semaphore ?? true

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<CapabilityList>) => {
    if (data?.data) {
      setCapabilityList((actualCapList) => {
        // Update only if the previous subscribe data is available already
        if (actualCapList) {
          // Deep copy
          const capListCopy = JSON.parse(JSON.stringify(actualCapList)) as CapabilityList

          capListCopy.capabilities = data?.data?.capabilities || []

          capListCopy.sectors = data?.data?.sectors || []

          capListCopy.sectors.forEach((sector) => {
            fixSubcapabilityTextList(sector)
          })

          return capListCopy
        }

        return actualCapList
      })
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<CapabilityList>) => {
    if (data?.data) {
      const list = data.data

      list.sectors.forEach((sector) => {
        fixSubcapabilityTextList(sector)
      })

      setCapabilityList(list)
    }

    onSubscribeData?.current?.(data)
  }, [])

  /**
   * Model WsMessageModel.CapabilitySectorList requires valid sector code. TenantId is 0.
   * Model WsMessageModel.CapabilityList ignores sector code but requires valid tenantId.
   */
  const ret = useSubscribe<UseCapabilityListDataSpec>(
    (isManagement && !props?.forceSectorCode) || (!isManagement && !tenantId) || !semaphore
      ? undefined
      : {
          ...props,
          autoRefetch: props?.autoRefetch || {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: isManagement ? sectorCode : tenantId?.toString(),
          model: isManagement ? WsMessageModel.CapabilitySectorList : WsMessageModel.CapabilityList,
          tenantId: isManagement ? 0 : tenantId,
        },
  )

  return {
    ...ret,
    capabilityList,
  }
}

// TODO: fix type
type UseKpmgUserTenantsDataSpec = {
  [WsOperationType.Subscribe]: KpmgUserTenants
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: KpmgUserTenants
  [WsOperationType.Delete]: unknown
}

export const useKpmgUserTenants = (
  props?: UseGeneralProps<UseKpmgUserTenantsDataSpec> & {
    semaphore: boolean
  },
) => {
  const [kpmgUserTenantsList, setKpmgUserTenantsList] = useState<KpmgUserTenants>()

  const semaphore = props?.semaphore ?? true

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const { t } = useTranslation()

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<KpmgUserTenants>) => {
    if (data?.data) {
      setKpmgUserTenantsList(data.data)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<KpmgUserTenants>) => {
      if (data?.data) {
        setKpmgUserTenantsList(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [],
  )

  const ret = useSubscribe<UseKpmgUserTenantsDataSpec>(
    semaphore
      ? {
          ...props,
          autoRefetch: props?.autoRefetch || {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          model: WsMessageModel.KpmgUserTenants,
          useInternalErrorHandling: false,
        }
      : undefined,
  )

  useErrorCodeModal({
    defaultContent: t(tKeys.errors.cannotLoadUserList),
    errorCode: ret?.error?.error?.code,
    priority: 'mix',
  })

  return {
    ...ret,
    kpmgUserTenantsList,
    subscribeData: ret.data?.subscribe,
  }
}

// TODO: fix type
type UseUserGroupsDataSpec = {
  [WsOperationType.Subscribe]: UserGroups
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: UserGroups
  [WsOperationType.Delete]: unknown
}

type UseUserGroupsProps = UseGeneralProps<UseUserGroupsDataSpec> & {
  forceTenantId?: number
}

export const useUserGroups = (props?: UseUserGroupsProps) => {
  const [userGroups, setUserGroups] = useState<UserGroups>()

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const { t } = useTranslation()

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<UserGroups>) => {
    if (data?.data) {
      setUserGroups(data.data)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<UserGroups>) => {
    if (data?.data) {
      setUserGroups(data.data)
    }

    onSubscribeData?.current?.(data)
  }, [])

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const ret = useSubscribe<UseUserGroupsDataSpec>(
    tenantId
      ? {
          ...props,
          autoRefetch: props?.autoRefetch || {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model: WsMessageModel.UserGroups,
          tenantId,
          useInternalErrorHandling: false,
        }
      : undefined,
  )

  useErrorCodeModal({
    defaultContent: t(tKeys.errors.cannotLoadUserList),
    errorCode: ret?.error?.error?.code,
    priority: 'mix',
  })

  return {
    ...ret,
    subscribeData: ret.data?.subscribe,
    userGroups,
  }
}

type UseJourneyDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUseJourney
  [WsOperationType.Create]: undefined
  [WsOperationType.Change]: WsSubscribeUseJourney
  [WsOperationType.Delete]: undefined
  [WsOperationType.Back]: WsSubscribeUseJourney
  [WsOperationType.Forward]: WsSubscribeUseJourney
  [WsOperationType.Revert]: WsSubscribeUseJourney
}

/**
 * Working with "refetchFlag" is a little bit tricky so be careful when modifying the hook's code.
 */
export const useJourney = (
  props?: UseGeneralProps<UseJourneyDataSpec> & { semaphore?: boolean },
) => {
  const [refetchFlag, setRefetchFlag] = useState(false)

  const semaphore = props?.semaphore ?? true

  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)

  const { isTemplatePreview, isGlobalTemplatePreview } = useRouteMatch()

  const { templateId } = useTypedParams()

  let id = selectedJourneyId
  let model = WsMessageModel.Document

  if (templateId && isTemplatePreview) {
    id = templateId
    model = isGlobalTemplatePreview
      ? WsMessageModel.GlobalTemplateDocument
      : WsMessageModel.LocalTemplateDocument
  }

  const { data, ...rest } = useSubscribe<UseJourneyDataSpec>(
    selectedTenantId && id && semaphore
      ? {
          ...props,
          id: id.toString(),
          model,
          readVersion: props?.readVersion || {
            back: true,
            change: true,
            forward: true,
            revert: true,
            subscribe: true,
          },
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  const {
    data: cacheData,
    refetch,
    ...restCache
  } = useSubscribe<UseJourneyDataSpec>(
    selectedTenantId && id && semaphore && refetchFlag
      ? {
          ...props,
          id: id.toString(),
          model: WsMessageModel.DocumentCache,
          readVersion: props?.readVersion || {
            back: true,
            change: true,
            forward: true,
            revert: true,
            subscribe: true,
          },
          tenantId: selectedTenantId,
        }
      : undefined,
  )

  const refetchWrapper = useCallback(() => {
    setRefetchFlag(true)
    refetch()
  }, [refetch])

  return {
    error: restCache.error || rest.error,
    loading: rest.loading || restCache.loading,
    refetch: refetchWrapper,
    subscribeData: cacheData?.subscribe || data?.subscribe,
  }
}

type UsePublishedTemplatesDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUsePublishedTemplates
  [WsOperationType.Change]: WsSubscribeUsePublishedTemplates
}

type UsePublishedTemplatesProps = UseGeneralProps<UsePublishedTemplatesDataSpec> & {
  forceTenantId?: number
}

/**
 * Returns PUBLISHED templates only.
 * Returns local and global templates.
 */
export const usePublishedTemplates = (props?: UsePublishedTemplatesProps) => {
  const [templates, setTemplates] = useState<WsSubscribeUsePublishedTemplates>()
  const [localTemplates, setLocalTemplates] = useState<WsSubscribeUsePublishedTemplates>()
  const [globalTemplates, setGlobalTemplates] = useState<WsSubscribeUsePublishedTemplates>()

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const setTemplatesWrapper = useCallback((data: WsSubscribeUsePublishedTemplates) => {
    setTemplates(data)
    setLocalTemplates(data.filter(({ type, published }) => published && type === 'localtemplate'))
    setGlobalTemplates(data.filter(({ type, published }) => published && type === 'globaltemplate'))
  }, [])

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUsePublishedTemplates>) => {
      if (data?.data) {
        setTemplatesWrapper(data.data)
      }

      onChangeData?.current?.(data)
    },
    [setTemplatesWrapper],
  )

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUsePublishedTemplates>) => {
      if (data?.data) {
        setTemplatesWrapper(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [setTemplatesWrapper],
  )

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const ret = useSubscribe<UsePublishedTemplatesDataSpec>(
    tenantId === undefined
      ? undefined
      : {
          ...props,
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model: WsMessageModel.TemplateDocumentList,
          tenantId: tenantId,
        },
  )

  return {
    ...ret,
    globalTemplates: globalTemplates || [],
    localTemplates: localTemplates || [],
    templates,
  }
}

type UseLocalOrGlobalTemplatesDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUseLocalOrGlobalTemplates
  [WsOperationType.Change]: WsSubscribeUseLocalOrGlobalTemplates
}

type UsePLocalOrGlobalTemplatesProps = UseGeneralProps<UseLocalOrGlobalTemplatesDataSpec> & {
  forceTenantId?: number
  semaphore?: boolean
  templateType: TemplateEnum
}

/**
 * Returns local OR global templates
 */
export const useLocalOrGlobalTemplates = (props: UsePLocalOrGlobalTemplatesProps) => {
  const [templates, setTemplates] = useState<WsSubscribeUseLocalOrGlobalTemplates>()

  const semaphore = props?.semaphore ?? true

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUseLocalOrGlobalTemplates>) => {
      if (data?.data) {
        setTemplates(data.data)
      }

      onChangeData?.current?.(data)
    },
    [],
  )

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUseLocalOrGlobalTemplates>) => {
      if (data?.data) {
        setTemplates(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [],
  )

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const ret = useSubscribe<UseLocalOrGlobalTemplatesDataSpec>(
    tenantId === undefined || !semaphore
      ? undefined
      : {
          ...props,
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model:
            props.templateType === TemplateEnum.GlobalTemplate
              ? WsMessageModel.GlobalTemplateDocumentList
              : WsMessageModel.LocalTemplateDocumentList,
          tenantId: tenantId,
        },
  )

  return {
    ...ret,
    templates: templates || [],
  }
}

type UsePublishedChannelsDataSpec = {
  [WsOperationType.Subscribe]: WsSubscribeUsePublishedChannels
  [WsOperationType.Change]: WsSubscribeUsePublishedChannels
}

type UsePublishedChannelsProps = UseGeneralProps<UsePublishedChannelsDataSpec> & {
  forceTenantId?: number
}

/**
 * Returns PUBLISHED channels only.
 * Returns local and global channels.
 */
export const usePublishedChannels = (
  props?: UsePublishedChannelsProps & { semaphore?: boolean },
) => {
  const [channels, setChannels] = useState<WsSubscribeUsePublishedChannels>()
  const [localChannels, setLocalChannels] = useState<WsSubscribeUsePublishedChannels>([])
  const [globalChannels, setGlobalChannels] = useState<WsSubscribeUsePublishedChannels>([])

  const semaphore = props?.semaphore ?? true

  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const setChannelsWrapper = useCallback((data: WsSubscribeUsePublishedChannels) => {
    setChannels(data)
    setLocalChannels(data.filter(({ type }) => type === 'local'))
    setGlobalChannels(data.filter(({ type }) => type === 'global'))
  }, [])

  const onChangeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUsePublishedChannels>) => {
      if (data?.data) {
        setChannelsWrapper(data.data)
      }

      onChangeData?.current?.(data)
    },
    [setChannelsWrapper],
  )

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<WsSubscribeUsePublishedChannels>) => {
      if (data?.data) {
        setChannelsWrapper(data.data)
      }

      onSubscribeData?.current?.(data)
    },
    [setChannelsWrapper],
  )

  const tenantId = props?.forceTenantId ?? selectedTenantId

  const ret = useSubscribe<UsePublishedChannelsDataSpec>(
    tenantId === undefined || !semaphore
      ? undefined
      : {
          ...props,
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          id: tenantId.toString(),
          model: WsMessageModel.ChannelList,
          tenantId: tenantId,
        },
  )

  return {
    ...ret,
    channels,
    globalChannels,
    localChannels,
  }
}

type UseCategoryListDataSpec = {
  [WsOperationType.Subscribe]: CategoryList
  [WsOperationType.Create]: unknown
  [WsOperationType.Change]: CategoryList
  [WsOperationType.Delete]: unknown
}

export const useCategories = (
  props?: UseGeneralProps<UseCategoryListDataSpec> & { semaphore?: boolean },
) => {
  const [categories, setCategories] = useState<CategoryList['categories']>()

  const semaphore = props?.semaphore ?? true

  const onSubscribeData = useRef(props?.callbacks?.subscribe)
  onSubscribeData.current = props?.callbacks?.subscribe

  const onChangeData = useRef(props?.callbacks?.change)
  onChangeData.current = props?.callbacks?.change

  const onChangeDataWrapper = useCallback((data?: WsEnhancedResponseMessage<CategoryList>) => {
    if (data?.data?.categories) {
      setCategories(data.data.categories)
    }

    onChangeData?.current?.(data)
  }, [])

  const onSubscribeDataWrapper = useCallback(
    (data?: WsEnhancedResponseMessage<CategoryList>) => {
      if (data?.data?.categories) {
        setCategories(data.data.categories)
      }

      onSubscribeData?.current?.(data)
    },
    [onSubscribeData],
  )

  const {
    data: _data,
    refetch: _refetch,
    ...rest
  } = useSubscribe<UseCategoryListDataSpec>(
    semaphore
      ? {
          ...props,
          autoRefetch: {
            create: true,
            delete: true,
          },
          callbacks: {
            ...props?.callbacks,
            change: onChangeDataWrapper,
            subscribe: onSubscribeDataWrapper,
          },
          model: WsMessageModel.CategoryList,
        }
      : undefined,
  )

  const categoryMap = useMemo(() => {
    const ret: Record<number, Category> = {}

    categories?.forEach((category) => {
      ret[category.id] = category
    })

    return ret
  }, [categories])

  return {
    ...rest,
    categories: categories || [],
    categoryMap,
  }
}
