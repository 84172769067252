import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import { ErrorCode, loginRequest } from '@dis/constants'
import { dispatchedActions } from '@dis/redux'
import { apiErrorModals } from '@dis/modals/src/apiErrorModals'
import { tKeys } from '@dis/languages'
import { t } from 'i18next'
import { reloadApp } from './url'

let msalInstance: PublicClientApplication | undefined

const buffer: ((idToken: string) => void)[] = []

let isBufferProcessing = false

export const setMsalInstance = (instance: PublicClientApplication) => {
  msalInstance = instance
}

export const setBufferProcessing = (value: boolean) => {
  isBufferProcessing = value
}

export const forceLogout = () => {
  if (msalInstance) {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }
}

export const logoutPopup = () => {
  if (msalInstance) {
    msalInstance
      .logoutPopup()
      .then(() => {
        //  Best way how to delete all the saved data is to reload web application
        window.location.href = '/'
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const processActiveAccount = ({ account, expiresOn }: AuthenticationResult) => {
  msalInstance?.setActiveAccount(account)
  const time = expiresOn?.getTime() || 0
  dispatchedActions.security.setUserOid(account?.idTokenClaims?.oid)
  dispatchedActions.security.setAccessTokenExpireTimestamp(time)
}

export const checkExistingSession = () => {
  if (msalInstance?.getActiveAccount?.()) {
    msalInstance.acquireTokenSilent(loginRequest).then(
      (ar) => {
        if (ar.idToken) {
          processActiveAccount(ar)
          dispatchedActions.security.loginSuccess()
        } else {
          dispatchedActions.security.setIsAuthenticated(false)
        }
      },
      (error) => {
        console.warn('checkExistingSession - login failed', error)
        dispatchedActions.security.setIsAuthenticated(false)
      },
    )
  } else {
    console.warn('checkExistingSession - no active account')
    dispatchedActions.security.setIsAuthenticated(false)
  }
}

const successCallback = (ar: AuthenticationResult) => {
  if (ar.idToken) {
    processActiveAccount(ar)

    while (buffer.length) {
      const resolve = buffer.shift()

      resolve?.(ar.idToken)
    }
  } else {
    errorCallback('processUserSession - invalid token')
  }

  setBufferProcessing(false)
}

const errorCallback = (error: unknown) => {
  console.warn('processUserSession failed', error)
  msalInstance?.setActiveAccount(null)
  dispatchedActions.security.setAccessTokenExpireTimestamp()
  dispatchedActions.centralModalDialog.hideAllModalDialogs()
  dispatchedActions.centralModalDialog.showModalDialog(apiErrorModals[ErrorCode.Unauthenticated]())
}

const processBuffer = () => {
  if (!isBufferProcessing) {
    setBufferProcessing(true)

    if (msalInstance?.getActiveAccount?.()) {
      msalInstance.acquireTokenSilent(loginRequest).then(successCallback, (error) => {
        console.warn('processUserSession: refreshing token failed', error)

        msalInstance?.acquireTokenPopup(loginRequest).then(successCallback, errorCallback)
      })
    } else {
      console.warn('processUserSession: No active account found')

      msalInstance?.loginPopup(loginRequest).then(successCallback, errorCallback)
    }
  }
}

/**
 * Returns user's idToken or empty string and set redux
 */
export const processUserSession = (): Promise<string> =>
  new Promise((resolve) => {
    buffer.push(resolve)
    processBuffer()
  })

const loginFailed = () => {
  dispatchedActions.centralModalDialog.hideAllModalDialogs()
  dispatchedActions.centralModalDialog.showModalDialog({
    content: t(tKeys.modals.loginErrorModal.content),
    crossButton: {
      onClick: reloadApp,
    },
    hasCrossClose: true,
    primaryButton: {
      btnText: t(tKeys.common.accept),
      onClick: reloadApp,
    },
    severity: 'error',
    title: t(tKeys.modals.loginErrorModal.title),
  })
}

export const handleMainLogin = (withReload: boolean) => () => {
  msalInstance?.loginPopup(loginRequest).then(
    (ar) => {
      if (ar.idToken) {
        processActiveAccount(ar)
        if (withReload) {
          reloadApp()
        } else {
          dispatchedActions.security.loginSuccess()
        }
      } else {
        loginFailed()
      }
    },
    (error) => {
      console.error(error)
      loginFailed()
    },
  )

  if (!withReload) {
    setBufferProcessing(false)
  }
}
