import { useCallback } from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { cx } from '@emotion/css'
import { styles } from './styles'

export type RoundIconButtonProps = IconButtonProps & {
  icon?: React.ReactNode
  isTransparent?: boolean
  outline?: 'grey' | 'blue'
}

/** BUTTON WITH CIRCLE SHAPE WITH ICON INSIDE - MUI COMPONENT WAS USED
 * All parameters are optional, more information below:
 * @param color -default color is "primary" from MUI default theme, see theme.tsx for colors
 * @param icon -default icon is "AddIcon"
 * @param size - default size is "medium"
 * @param className - for adding or overriding your styles
 * @param onClick - for adding function
 * for example go to Persona.tsx
 * for IconButton API follow https://mui.com/material-ui/api/icon-button/
 */
export const RoundIconButton = ({
  icon = <AddIcon />,
  className = '',
  outline,
  isTransparent,
  disabled,
  onClick,
  ...rest
}: RoundIconButtonProps) => {
  const onClickWrapper = useCallback<NonNullable<IconButtonProps['onClick']>>(
    (event) => {
      if (!disabled && onClick) {
        onClick(event)
      }
    },
    [disabled, onClick],
  )

  return (
    <IconButton
      data-testid="round-icon-button"
      {...rest}
      // className prop has to be as standalone string to avoid class styles composing and keeping exact class name
      className={`${cx(styles.root, {
        [styles.transparent]: isTransparent,
        [styles.outline.grey]: outline === 'grey',
        [styles.outline.blue]: outline === 'blue',
        [styles.disabled]: disabled,
      })} ${className}`}
      onClick={onClickWrapper}>
      {icon}
    </IconButton>
  )
}
