import { Header, Paragraph } from '@dis/components'
import image50 from '@dis/assets/src/images/newTutorial/50.png'
import { styles } from '../styles'
import { ScrollProps } from '../types'

/* eslint-disable react/no-unescaped-entities */
export const RemovePersonaFromJourneyLayer = ({ id }: ScrollProps) => {
  return (
    <div id={id}>
      <Header marginTop={5} marginBottom={4} variant="h2">
        1.7 Removing a persona from a journey layer
      </Header>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        To remove a persona from a layer, go in the dropdown menu and click on the "Remove persona"
        option.
      </Paragraph>
      <Paragraph marginTop={5} variant="medium" className={styles.subtitleDescription}>
        <img src={image50} alt="Settings Discovery" />
      </Paragraph>
    </div>
  )
}
