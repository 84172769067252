import { useEffect, useRef } from 'react'
import { Card } from '@mui/material'
import { SignInButton } from '@dis/modules/src/SignInButton/SignInButton'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { Header } from '@dis/components'
import { styles } from './styles'

export const Login = () => {
  const { t } = useTranslation()

  const documentTitleBackup = useRef(document.title)

  useEffect(() => {
    const documentTitle = documentTitleBackup.current

    document.title = t(tKeys.login.documentTitle)

    return () => {
      document.title = documentTitle
    }
  }, [t])

  return (
    <Card className={styles.card}>
      <Header variant="h1">{t(tKeys.login.title)}</Header>
      <div className={styles.signInButtonWrapper}>
        <SignInButton />
      </div>
    </Card>
  )
}
